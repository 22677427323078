import 'date-fns';
import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  DateTimePicker
} from '@material-ui/pickers';
import { Redirect } from 'react-router-dom';
import CodiceFiscale from 'codice-fiscale-js';
import IBAN from 'iban';
import queryString from 'query-string';
import momentT from 'moment-timezone';
import ManageOptions from '../../../components/dialogs/manageSelector';
import ManageScript from '../../../components/dialogs/ManageScript';
import bic from 'bic';
import { isMobilePhone } from 'validator';
import { cities } from './../../../data/provinces';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import renderHTML from 'react-render-html';

import store from '../../../store';

import { 
    saveRecords,
} from '../../../actions/records';

// import { zohoUsers } from '../../../mocks/zohoUsers';
// import { zohoProducts } from '../../../mocks/zohoProducts';
import LocationSearch from '../../../components/google-autocomplete';
import InfoDialog from '../../../components/dialogs/infoPopup/index';
import NewsLetterPopup from '../../../components/dialogs/newsLetterPopup/index';

import './index.scss';

import { 
    openNotification
} from '../../../actions/notification';

import {
    saveManagedFields
} from '../../../actions/managedFields';

import * as service from '../../../service';

const mapStateToProps = state => ({
    ...state.scripts,
    managedFields: state.managedFields
})

const mapDispatchToProps = dispatch => ({
    openNotification: (payload) => {
        return dispatch(openNotification(payload));
    },

    saveManagedFields: (payload) => {
        return dispatch(saveManagedFields(payload));
    }
})

class CreateRecord extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            id: null,
            ...defaultState, 
            loading: true,
            subscribeStatus: false,
            loadingParse: false,
            usedData: {},
            zohoProducts:[],
            zohoUsers: [],
            showManagePopup: false,
            manageData: null,
            theSameAddress: false,
            showQuestion: false,
            questionData: null,
            selectedAnswer: '',
            current_question: '',
            openInfoDialog: false,
            infoDialogText: '',
            scriptId: '',
            scriptText: '',
            showManageScript: false,
            scriptCB: null,
            openNewsLetterDialog: false,
            subscribeUserChecked: false
        };
        this.formData = {
            timezone: momentT.tz.guess()
        };
        this.paramsData = {};


        this.setFieldValue = this.setFieldValue.bind(this);
        this.setFieldValueState = this.setFieldValueState.bind(this);
        this.createRecord = this.createRecord.bind(this);
        this.updateRecord = this.updateRecord.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.createRecordDraft = this.createRecordDraft.bind(this);
        this.updateRecordDraft = this.updateRecordDraft.bind(this);
        this.subscribeUser = this.subscribeUser.bind(this);
        this.createLead = this.createLead.bind(this);

        this.closeManageScript = this.closeManageScript.bind(this);
        this.openManageScript = this.openManageScript.bind(this);

        // manage fields
        this.statusManagePopup = this.statusManagePopup.bind(this);
        this.createManageField = this.createManageField.bind(this);
        this.updateManageField = this.updateManageField.bind(this);

        this.onCloseInfoDialog = this.onCloseInfoDialog.bind(this);
        this.onOpenInfoDialog = this.onOpenInfoDialog.bind(this);

        this.onCloseNewsLetteroDialog = this.onCloseNewsLetteroDialog.bind(this);
        this.onOpenNewsLetterDialog = this.onOpenNewsLetterDialog.bind(this);
    }

    closeManageScript() {
        const { scriptCB } = this.state;

        console.log(scriptCB, 'scriptCB');

        if(scriptCB)
            scriptCB();

        this.setState({showManageScript: false})
    }

    openManageScript(scriptId = '', scriptText = '', cb) {
        this.setState({showManageScript: true, scriptId, scriptText, scriptCB: cb})
    }


    onCloseInfoDialog () {
        this.setState({ openInfoDialog: false });
    }

    onOpenInfoDialog(text) {
        this.setState({ openInfoDialog: true, infoDialogText: text });
    }

    onCloseNewsLetteroDialog () {
        this.setState({ openNewsLetterDialog: false, subscribeUserChecked: true });
    }

    onOpenNewsLetterDialog(text) {
        this.setState({ openNewsLetterDialog: true });
    }

    statusManagePopup(status, data = null) {
        this.setState({ showManagePopup: status, manageData: data });
    }

    createManageField(payload) {
        service.createManageField(payload).then(resp => {
            this.statusManagePopup(false);

            const {
                ok,
                field
            } = resp.data;

            if(!ok)
                return;

            field.properties = JSON.parse(field.properties);

            const preparedManagedFields = [ ...this.props.managedFields.managedFields, field ];

            this.props.saveManagedFields(preparedManagedFields);
            this.setState({ managedFields: preparedManagedFields });
        }).catch(err => {
            this.statusManagePopup(false);

            console.log(err);
        })
    }

    updateManageField(id, payload) {
        service.updateManageField(id, payload).then(resp => {
            this.statusManagePopup(false);

            const {
                ok,
            } = resp.data;

            if(!ok)
                return;

            const upadatedFields = this.props.managedFields.managedFields.map(field => (field.id == id) ? { ...field, ...payload } : field );

            this.props.saveManagedFields(upadatedFields);
        }).catch(err => {
            this.statusManagePopup(false);

            console.log(err);
        })
    }

    componentDidMount() {
        const {
            id,
            status
        } = this.props.match.params;

        service.getQuestion().then(resp => {
            const { question: questData } = resp.data;

            const parsedRules = JSON.parse(questData.rules);

            this.setState({ questionData: parsedRules });
        }).catch(err => {
            console.log(err);
        })

        window.addEventListener('scroll', (event) => {
            const { questionData } = this.state;

            if(!questionData)
                return false;

            if(questionData.selectedSection === 'everywhere') {
                if(this.state.showQuestion)
                    return 

                return this.setState({ showQuestion: true });
            }

            const elementToFind = questionData.selectedSection;

            const trigerElement = document.getElementById(elementToFind);

            if(!trigerElement)
                return false;

            const positionTrigerElement = trigerElement.getBoundingClientRect();
            const position = positionTrigerElement.top - window.innerHeight + window.outerHeight / 2;

            if(position < 0) {
                if(this.state.showQuestion)
                    return 

                return this.setState({ showQuestion: true });
            }

            if(position > 0) {
                if(!this.state.showQuestion)
                    return 

                return this.setState({ showQuestion: false });
            }
        })

        const parsed = queryString.parse(this.props.location.search);

        console.log(parsed, "PARSED");

        if(parsed.contact_id) {
            this.paramsData = { ...parsed };
            this.formData = { ...this.formData, ...parsed };

            this.setState({ loadingParse: true, ...parsed });
        }

        service.getAllZohoProducts().then(resp => {
            const { ok, products } = resp.data;

            const onlyActive = products.filter(product => product.product_active);
        
            if(ok)
                this.setState({products: onlyActive});
        });

        service.getAllZohoUsers().then(resp => {
            const { ok, users } = resp.data;
            const { contact_owner } = this.paramsData;
        
            if(!ok)
                return false;

            this.setState({ usersZ: users });

            if(contact_owner) {
                const foundUser = users.find(user => user.ZohoID == contact_owner)

                console.log(contact_owner, users);

                console.log(foundUser, 'USER');

                if(foundUser)
                    this.setFieldValue('contact_owner', foundUser);
            }

            this.setState({ loadingParse : false });

        });

        service.searchScripts({limit: 500}).then(resp => {
            const { 
                ok,
                count,
                scripts
            } = resp.data;

            if(!scripts[0])
                return;

            this.setState({operations: scripts});
        })

        if(!id)
            return false;

        if(status !== 'duplicate')
            this.setState({ id });

        this.setState({loading: true});

        service.getRecordById(id).then(resp => {
            const {
                ok,
                record
            } = resp.data;

            if(!ok)
                return false;

            const state = this.state;
            const formData = this.formData;

            const parsedData = JSON.parse(record.data);

            delete record.data;

            const recordData = {
                ...record,
                ...parsedData,

                contact_id: record.contact_id ? record.contact_id : parsedData.contact_id
            };

            if(status === 'duplicate') {
                delete recordData.code;
            }

            const {
                type_contract,
                client_type,
                product,
                operation,
                payment_type,
                delegato,
                prodotto,
                newsletter,
                marketing_terzi,
                sub_contract,
                sub_contract_extra,
                sub_sub_contract,
                type_product,
                ricezione_del_plico_contrattuale,
                tipo_di_fatturazione,
                delegato_extra,
                tipo_di_pagamento,
                product_ee = {},
                product_gas = {},
                operation_ee = {},
                operation_gas = {},
                product_insurance,

                // ALTRA
                product_altra,
                client_type_altra,
                operation_altra,
                payment_type_altra,
                delegato_altra, 

                selectedAnswer,
                current_question,
                compagnia_scelta
            } = recordData;

            const data = {
                ...formData,
                ...recordData
            };

            this.setState({
                ...state,
                type_contract,
                client_type,
                product,
                operation,
                payment_type,
                delegato,
                prodotto,
                newsletter,
                marketing_terzi,
                delegato_extra,
                sub_contract,
                sub_contract_extra,
                sub_sub_contract,
                type_product,
                ricezione_del_plico_contrattuale,
                tipo_di_fatturazione,
                tipo_di_pagamento,
                product_ee: product_ee === null ? {} : product_ee,
                product_gas: product_gas === null ? {} : product_gas,
                operation_ee: operation_ee === null ? {} : operation_ee,
                operation_gas: operation_gas === null ? {} : operation_gas,

                // ALTRA
                product_altra,
                client_type_altra,
                operation_altra,
                payment_type_altra,
                delegato_altra,

                usedData: data,

                selectedAnswer,
                current_question,
                product_insurance,
                compagnia_scelta
            })

            this.formData = data;

            this.forceUpdate();
            this.setState({loading: false});
        });
    }

    setFieldValue(name, value) {
        this.formData[name] = value;
    }

    setFieldValueState(name, value) {
        const state = this.state;

        state[name] = value;

        console.log(name, value)

        this.setState(() => state);
    }

    setAddress(data) {
        console.log(data);
    }

    createRecord() {
        const { state, formData } = this;
        

        const {
            type_contract,
            sub_contract,
            sub_contract_extra,
            sub_sub_contract,
            client_type,
            product,
            products,
            prodotto,
            usersZ,
            operation,
            payment_type,
            delegato,
            newsletter,
            subscribeUserChecked,
            delegato_extra,
            marketing_terzi,
            type_product,
            product_ee,
            product_gas,
            operation_ee,
            operation_gas,

            product_altra,
            client_type_altra,
            operation_altra,
            payment_type_altra,
            delegato_altra,

            selectedAnswer,
            current_question,

            // INSURANCE
            product_insurance
        } = state;

        if(!subscribeUserChecked && newsletter) {
            return this.onOpenNewsLetterDialog();
        }

        if(!subscribeUserChecked && !newsletter) {
            return this.onOpenNewsLetterDialog();
        }

        const toParseFields = {
            ...formData,
            type_contract,
            sub_contract,
            sub_contract_extra,
            sub_sub_contract,
            client_type,
            product,
            prodotto,
            operation,
            payment_type,
            delegato,
            newsletter,
            delegato_extra,
            marketing_terzi,
            type_product,
            product_ee,
            product_gas,
            operation_ee,
            operation_gas,

            // ALRTRA
            product_altra,
            client_type_altra,
            operation_altra,
            payment_type_altra,
            delegato_altra,

            selectedAnswer,
            current_question,

            product_insurance
        };

        const {
            name,
            last_name,
            name_extra,
            last_name_extra
        } = toParseFields;

        console.log(name,
            last_name,
            name_extra,
            last_name_extra, 'verify names')

        if(type_contract !== 'vendite extra') {
            if((name_extra || last_name_extra) && (name?.toLowerCase() !== name_extra.toLowerCase() || last_name?.toLowerCase() !== last_name_extra.toLowerCase()))
                return this.onOpenInfoDialog('Gli intestatari sono diversi. Sei sicuro di voler procedere?');
        }

        if(type_contract === 'contratto energia') {
            const { 
                codice_fiscale,
                codice_fiscale_referente,
                pod,
                pdr
            } = formData;
            let code = '';
            let lg_code = '';

            if(product_ee && product_ee.product_name) {
                code += product_ee.product_name.substring(0, 2);
                lg_code += pod ? pod.slice(-4) : '0000';
            }

            if(product_gas && product_gas.product_name) {
                
                if(lg_code)
                    lg_code += "&";

                code += product_gas.product_name.substring(0, 2);
                lg_code += pdr ? pdr.slice(-4) : '0000';
            };

            if(lg_code && code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += lg_code;
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        toParseFields.cf = formData.codice_fiscale;

        if(type_contract === 'vendite extra') {
            toParseFields.name = formData.name_extra || formData.nome_referente_extra;
            toParseFields.last_name = formData.last_name_extra || formData.cognome_referente_extra;
            toParseFields.ragione_sociale = formData.ragione_sociale_extra;
            toParseFields.cf = formData.codice_fiscale_extra || formData.p_iva_extra || '';

            const { 
                codice_fiscale_extra
            } = formData;
            let code = '';

            if(prodotto) {
                code += prodotto.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale_extra ? codice_fiscale_extra.substring(0, 6) : 'X';
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(type_contract === 'telco') {
            const { 
                codice_fiscale,
                codice_fiscale_referente,
            } = formData;
            let code = '';

            if(product && product.product_name) {
                code += product.product_name.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(type_contract === 'Assicurazione') {
            const { 
                codice_fiscale_insurance,
                product_insurance,
                phone_insurance
            } = formData;
            let code = '';

            if(product_insurance !== 'yolo_home') {
                if(product_insurance) {
                    code += product_insurance.substring(0, 2);
                }
    
                if(code) {
                    code += codice_fiscale_insurance.substring(0, 6);
                    code += months[new Date().toLocaleString('en-US', { month: 'long' })];
                }
            } else {
                code += product_insurance.substring(0, 2);
                code += phone_insurance.substring(3, 9);
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(client_type === 'business') {
            toParseFields.name = formData.nome_referente;
            toParseFields.last_name = formData.cognome_referente;
            toParseFields.cf = formData.codice_fiscale_referente;
        }

        const payload = processFields(toParseFields);

        payload.draft = false;

        service.createRecord(payload).then(resp => {
            const {
                ok,
                recordId,
                record
            } = resp.data;

            const state = store.getState();

            if(!ok)
                return false;

            record.data = JSON.parse(record.data);

            const [ ...records] = state.records.records;
            records.unshift(record);

            store.dispatch(saveRecords({ records, count: records.length }));
            this.setState({ redirect: true });

            this.props.openNotification({ notificationText: 'Record has been created', notificationType: 'success' });
        })
    }

    createRecordDraft() {
        const { state, formData } = this;
        const {
            type_contract,
            sub_contract,
            sub_contract_extra,
            sub_sub_contract,
            client_type,
            product,
            products,
            prodotto,
            usersZ,
            operation,
            payment_type,
            delegato,
            newsletter,
            delegato_extra,
            marketing_terzi,
            type_product,
            product_ee,
            product_gas,
            operation_ee,
            operation_gas,

            // ALRTRA
            product_altra,
            client_type_altra,
            operation_altra,
            payment_type_altra,
            delegato_altra,

            selectedAnswer,
            current_question
        } = state;

        const toParseFields = {
            ...formData,
            type_contract,
            sub_contract,
            sub_contract_extra,
            sub_sub_contract,
            client_type,
            product,
            prodotto,
            operation,
            payment_type,
            delegato,
            newsletter,
            delegato_extra,
            marketing_terzi,
            type_product,
            product_ee,
            product_gas,
            operation_ee,
            operation_gas,

            product_altra,
            client_type_altra,
            operation_altra,
            payment_type_altra,
            delegato_altra,

            selectedAnswer,
            current_question
        };

        if(!toParseFields.contact_owner || !toParseFields.contact_owner.name)
            return this.props.openNotification({ notificationText: 'Please, select CONTACT OWNER!', notificationType: 'success' });

        if(type_contract === 'contratto energia') {
            const { 
                codice_fiscale,
                codice_fiscale_referente,
                pod,
                pdr
            } = formData;

            let code = '';
            let lg_code = '';

            if(product_ee && product_ee.product_name && pod) {
                code += product_ee.product_name.substring(0, 2);
                lg_code += pod ? pod.slice(-4) : '0000';
            }

            if(product_gas && product_gas.product_name && pdr) {
                
                if(lg_code)
                    lg_code += "&";

                code += product_gas.product_name.substring(0, 2);
                lg_code += pdr ? pdr.slice(-4) : '0000';
            };

            if(lg_code && code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += lg_code;
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        toParseFields.cf = formData.codice_fiscale;

        if(type_contract === 'vendite extra') {
            toParseFields.name = formData.name_extra || formData.nome_referente_extra;
            toParseFields.last_name = formData.last_name_extra || formData.cognome_referente_extra;
            toParseFields.ragione_sociale = formData.ragione_sociale_extra;
            toParseFields.cf = formData.codice_fiscale_extra || formData.p_iva_extra || '';;

            const { 
                codice_fiscale_extra
            } = formData;
            let code = '';

            if(prodotto) {
                code += prodotto.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale_extra ? codice_fiscale_extra.substring(0, 6) : 'X';
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(type_contract === 'telco') {
            const { 
                codice_fiscale,
                codice_fiscale_referente,
            } = formData;
            let code = '';

            if(product && product.product_name) {
                code += product.product_name.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(type_contract === 'Assicurazione') {
            const { 
                codice_fiscale_insurance,
                product_insurance,
                phone_insurance
            } = formData;
            let code = '';

            if(product_insurance !== 'yolo_home') {
                if(product_insurance) {
                    code += product_insurance.substring(0, 2);
                }
    
                if(code) {
                    code += codice_fiscale_insurance.substring(0, 6);
                    code += months[new Date().toLocaleString('en-US', { month: 'long' })];
                }
            } else {
                code += product_insurance.substring(0, 2);
                code += phone_insurance.substring(3, 9);
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(client_type === 'business') {
            toParseFields.name = formData.nome_referente;
            toParseFields.last_name = formData.cognome_referente;
            toParseFields.cf = formData.codice_fiscale_referente;
        }

        const payload = processFields(toParseFields);

        payload.draft = true;

        service.createRecord(payload).then(resp => {
            const {
                ok,
                recordId,
                record
            } = resp.data;

            const state = store.getState();

            if(!ok)
                return false;

            record.data = JSON.parse(record.data);

            const [ ...records] = state.records.records;
            records.unshift(record);

            store.dispatch(saveRecords({ records, count: records.length }));
            this.setState({ redirect: true });

            this.props.openNotification({ notificationText: 'Record has been created', notificationType: 'success' });
        })
    }


    updateRecord() {
        const { state, formData } = this;
        const {
            id,
            type_contract,
            sub_contract,
            sub_contract_extra,
            sub_sub_contract,
            client_type,
            product,
            products,
            prodotto,
            usersZ,
            operation,
            payment_type,
            delegato,
            newsletter,
            marketing_terzi,
            type_product,
            delegato_extra,
            product_ee,
            product_gas,
            operation_ee,
            operation_gas,

            // ALRTRA
            product_altra,
            client_type_altra,
            operation_altra,
            payment_type_altra,
            delegato_altra,

            selectedAnswer,
            current_question
        } = state;

        const toParseFields = {
            ...formData,
            type_contract,
            sub_contract,
            sub_contract_extra,
            sub_sub_contract,
            client_type,
            delegato_extra,
            product,
            prodotto,
            operation,
            payment_type,
            delegato,
            newsletter,
            marketing_terzi,
            type_product,
            product_ee,
            product_gas,
            operation_ee,
            operation_gas,

            
            product_altra,
            client_type_altra,
            operation_altra,
            payment_type_altra,
            delegato_altra,

            selectedAnswer,
            current_question
        };

        const {
            name,
            last_name,
            name_extra,
            last_name_extra
        } = toParseFields;

        console.log(name,
            last_name,
            name_extra,
            last_name_extra, 'verify names')

        if((name_extra || last_name_extra) && (name.toLowerCase() !== name_extra.toLowerCase() || last_name.toLowerCase() !== last_name_extra.toLowerCase()))
            return this.onOpenInfoDialog('Gli intestatari sono diversi. Sei sicuro di voler procedere?');

        if(type_contract === 'contratto energia') {
            const { 
                codice_fiscale = '',
                codice_fiscale_referente = '',
                pod,
                pdr
            } = formData;
            let code = '';
            let lg_code = '';

            if(product_ee && product_ee.product_name && pod) {
                code += product_ee.product_name.substring(0, 2);
                lg_code += pod ? pod.slice(-4) : '0000';
            }

            if(product_gas && product_gas.product_name && pdr) {
                
                if(lg_code)
                    lg_code += "&";

                code += product_gas.product_name.substring(0, 2);
                lg_code += pdr ? pdr.slice(-4) : '0000';
            };

            if(lg_code && code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += lg_code;
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        toParseFields.cf = formData.codice_fiscale;

        if(type_contract === 'vendite extra') {
            toParseFields.name = formData.name_extra || formData.nome_referente_extra;
            toParseFields.last_name = formData.last_name_extra || formData.cognome_referente_extra;
            toParseFields.ragione_sociale = formData.ragione_sociale_extra;
            toParseFields.cf = formData.codice_fiscale_extra || formData.p_iva_extra || '';;

            const { 
                codice_fiscale_extra
            } = formData;
            let code = '';

            if(prodotto) {
                code += prodotto.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale_extra ? codice_fiscale_extra.substring(0, 6) : 'X';
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(type_contract === 'telco') {
            const { 
                codice_fiscale,
                codice_fiscale_referente,
            } = formData;
            let code = '';

            if(product && product.product_name) {
                code += product.product_name.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(type_contract === 'Assicurazione') {
            const { 
                codice_fiscale_insurance,
                product_insurance,
                phone_insurance
            } = formData;
            let code = '';

            if(product_insurance !== 'yolo_home') {
                if(product_insurance) {
                    code += product_insurance.substring(0, 2);
                }
    
                if(code) {
                    code += codice_fiscale_insurance.substring(0, 6);
                    code += months[new Date().toLocaleString('en-US', { month: 'long' })];
                }
            } else {
                code += product_insurance.substring(0, 2);
                code += phone_insurance.substring(3, 9);
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(client_type === 'business') {
            toParseFields.name = formData.nome_referente;
            toParseFields.last_name = formData.cognome_referente;
            toParseFields.cf = formData.codice_fiscale_referente;
        }

        const payload = processFields(toParseFields);

        payload.draft = false;

        service.updateRecord(id, payload).then(resp => {
            const {
                ok,
                record
            } = resp.data;

            const state = store.getState();

            if(!ok)
                return false;

            const records = state.records.records;

            const updatedRecords = records.map(rec => {
                if(rec.id != id) {
                    return rec;
                }else {
                    record.data = JSON.parse(record.data);

                    return record;
                }
            })

            store.dispatch(saveRecords({ records: updatedRecords }));
            this.setState({ redirect: true });

            this.props.openNotification({ notificationText: 'Record has been updated', notificationType: 'success' });
        })
    }

    updateRecordDraft() {
        const { state, formData } = this;
        const {
            id,
            type_contract,
            sub_contract,
            sub_contract_extra,
            sub_sub_contract,
            client_type,
            product,
            products,
            prodotto,
            usersZ,
            operation,
            payment_type,
            delegato,
            newsletter,
            marketing_terzi,
            type_product,
            delegato_extra,
            product_ee,
            product_gas,
            operation_ee,
            operation_gas,

            // ALTRA
            product_altra,
            client_type_altra,
            operation_altra,
            payment_type_altra,
            delegato_altra,

            product_insurance
        } = state;

        const toParseFields = {
            ...formData,
            type_contract,
            sub_contract,
            sub_contract_extra,
            sub_sub_contract,
            client_type,
            delegato_extra,
            product,
            prodotto,
            operation,
            payment_type,
            delegato,
            newsletter,
            marketing_terzi,
            type_product,
            product_ee,
            product_gas,
            operation_ee,
            operation_gas,

            // ALTRA
            product_altra,
            client_type_altra,
            operation_altra,
            payment_type_altra,
            delegato_altra,
        };

        if(!toParseFields.contact_owner || !toParseFields.contact_owner.name)
            return this.props.openNotification({ notificationText: 'Please, select CONTACT OWNER!', notificationType: 'success' });

        if(type_contract === 'contratto energia') {
            const { 
                codice_fiscale = '',
                codice_fiscale_referente = '',
                pod,
                pdr
            } = formData;
            let code = '';
            let lg_code = '';

            if(product_ee && product_ee.product_name && pod) {
                code += product_ee.product_name.substring(0, 2);
                lg_code += pod ? pod.slice(-4) : '0000';
            }

            if(product_gas && product_gas.product_name && pdr) {
                
                if(lg_code)
                    lg_code += "&";

                code += product_gas.product_name.substring(0, 2);
                lg_code += pdr ? pdr.slice(-4) : '0000';
            };

            if(lg_code && code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += lg_code;
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        toParseFields.cf = formData.codice_fiscale;

        if(type_contract === 'vendite extra') {
            toParseFields.name = formData.name_extra || formData.nome_referente_extra;
            toParseFields.last_name = formData.last_name_extra || formData.cognome_referente_extra;
            toParseFields.ragione_sociale = formData.ragione_sociale_extra;
            toParseFields.cf = formData.codice_fiscale_extra || formData.p_iva_extra || '';;

            const { 
                codice_fiscale_extra
            } = formData;
            let code = '';

            if(prodotto) {
                code += prodotto.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale_extra ? codice_fiscale_extra.substring(0, 6) : 'X';
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(type_contract === 'telco') {
            const { 
                codice_fiscale,
                codice_fiscale_referente,
            } = formData;
            let code = '';

            if(product && product.product_name) {
                code += product.product_name.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(type_contract === 'Assicurazione') {
            const { 
                codice_fiscale_insurance,
                product_insurance,
                phone_insurance
            } = formData;
            let code = '';

            if(product_insurance !== 'yolo_home') {
                if(product_insurance) {
                    code += product_insurance.substring(0, 2);
                }
    
                if(code) {
                    code += codice_fiscale_insurance.substring(0, 6);
                    code += months[new Date().toLocaleString('en-US', { month: 'long' })];
                }
            } else {
                code += product_insurance.substring(0, 2);
                code += phone_insurance.substring(3, 9);
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }

            toParseFields.code = code;
        }

        if(client_type === 'business') {
            toParseFields.name = formData.nome_referente;
            toParseFields.last_name = formData.cognome_referente;
            toParseFields.cf = formData.codice_fiscale_referente;
        }

        const payload = processFields(toParseFields);

        payload.draft = true;

        service.updateRecord(id, payload).then(resp => {
            const {
                ok,
                record
            } = resp.data;

            const state = store.getState();

            if(!ok)
                return false;

            const records = state.records.records;

            const updatedRecords = records.map(rec => {
                if(rec.id != id) {
                    return rec;
                }else {
                    record.data = JSON.parse(record.data);

                    return record;
                }
            })

            store.dispatch(saveRecords({ records: updatedRecords }));
            this.setState({ redirect: true });

            this.props.openNotification({ notificationText: 'Record has been updated', notificationType: 'success' });
        })
    }

    subscribeUser() {
        const { 
            email_mailing,
            last_name_mailing,
            name_mailing
        } = this.formData;

        const {
            name_subs,
            last_name_subs,
            email_subs,
            contact_id,
            lead_id,
            contact_owner
        } = this.formData;

        console.log(this.formData, 'this.formData')


        if(!(name_subs || name_mailing))
            return this.props.openNotification({ notificationText: 'The Nome field empty!', notificationType: 'error' });

        if(!(last_name_subs || last_name_mailing))
            return this.props.openNotification({ notificationText: 'The Cognome field empty!', notificationType: 'error' });

        if(!(email_subs || email_mailing))
            return this.props.openNotification({ notificationText: 'The Email field empty!', notificationType: 'error' });

        if(!contact_id)
            return this.props.openNotification({ notificationText: 'The Contact Id field empty', notificationType: 'error' });

        if(!lead_id)
            return this.props.openNotification({ notificationText: 'The Lead Source field empty', notificationType: 'error' });

        if(!contact_owner && contact_owner.ZohoID)
            return this.props.openNotification({ notificationText: 'select Owner!', notificationType: 'error' });

        const payload = {
            name: name_subs,
            lastName: last_name_subs,
            contactId: contact_id,
            email: email_subs,
            leadId: lead_id,
            ownerId: contact_owner.ZohoID
        };

        this.setState({ subscribeStatus: true });

        service.subscribeUser(payload).then(resp => {
            const {
                ok,
                error,
                errorMessage,
                message
            } = resp.data;

            if(!ok) {
                if(errorMessage) 
                    this.props.openNotification({ notificationText: errorMessage, notificationType: 'error' });

                if(error) 
                    this.props.openNotification({ notificationText: error.title, notificationType: 'error' });
            } else {
                this.props.openNotification({ notificationText: message || 'Client subscribed!', notificationType: 'error' });
            }

            this.setState({ subscribeStatus: false, subscribeUserChecked: true });
        }).catch(err => {
            this.setState({ subscribeStatus: false, subscribeUserChecked: true });
        });


    }

    createLead() {
        const {
            contact_id,
            lead_id,
            contact_owner,
            callback_time
        } = this.formData;

        const {
            type_contract
        } = this.state;

        if(!contact_id)
            return this.props.openNotification({ notificationText: "Please, fill the Contact ID field", notificationType: 'error' });

        if(!lead_id)
            return this.props.openNotification({ notificationText: "Please, fill the Lead Source field", notificationType: 'error' });

        if(!contact_owner || contact_owner && !contact_owner.ZohoID)
            return this.props.openNotification({ notificationText: "Please, select a Contact Owner", notificationType: 'error' });

        if(!type_contract)
            return this.props.openNotification({ notificationText: "Please, select a Contract Type", notificationType: 'error' });


        const payload = {
            contact_id,
            lead_id,
            owner_id: contact_owner.ZohoID,
            type_contract,
            callback_time
        };

        this.setState({ subscribeStatus: true });

        service.createLead(payload).then(resp => {
            const {
                ok,
                id
            } = resp.data;

            if(ok && id)
                this.props.openNotification({ notificationText: 'The lead created successuly!', notificationType: 'success' });

            this.setState({ subscribeStatus: false });
        }).catch(err => {
            this.props.openNotification({ notificationText: "Can't create the lead, please create the record and send id of the record to developer:)", notificationType: 'success' });
            this.setState({ subscribeStatus: false });
        })
    }

    

    render() {
        if(this.props.match.params.id && this.state.loading)
            return 'Loading record...';

        if(this.state.loadingParse)
            return 'parsing params...';

        const { auth: { role, ...otherData } } = store.getState();

        console.log('otherData', otherData)
        
        const {
            id,
            type_contract,
            sub_contract,
            sub_contract_extra,
            sub_sub_contract,
            client_type,
            product,
            products,
            prodotto,
            operations,
            usersZ,
            operation,
            payment_type,
            delegato,
            newsletter,
            marketing_terzi,
            type_product,
            delegato_extra,
            product_ee,
            product_gas,
            operation_ee,
            operation_gas,

            usedData,

            redirect,
            zohoProducts,
            zohoUsers,

            product_altra,
            client_type_altra,
            operation_altra,
            payment_type_altra,
            delegato_altra,

            showManagePopup,
            manageData,

            theSameAddress,

            sub_insurance,

            subscribeStatus,

            showQuestion,
            questionData,
            selectedAnswer,

            openInfoDialog,
            infoDialogText,
            openNewsLetterDialog,

            scriptId,
            scriptText,
            showManageScript,
            scriptCB,

            operation_extra,
            product_insurance
        } = this.state;

        const formData = this.formData;

        const { managedFields } = this.props.managedFields;

        if(redirect)
            return <Redirect to='/'/>

        return (
            <div className='records_create_page'>
                { showManagePopup && 
                    <ManageOptions 
                        close={() => this.statusManagePopup(false)}
                        data={manageData}
                        createAction={this.createManageField}
                        updateAction={this.updateManageField}
                /> }

                { showManageScript &&
                    <ManageScript
                        close={this.closeManageScript}
                        text={scriptText}
                        scriptId={scriptId}
                        scriptCB={scriptCB}
                    />
                }
                {/* <div className='fields_section'> */}
                {subscribeStatus && <div className='loading'>
                    <div class="container">
                        <div class="coffee-header">
                        <div class="coffee-header__buttons coffee-header__button-one"></div>
                        <div class="coffee-header__buttons coffee-header__button-two"></div>
                        <div class="coffee-header__display"></div>
                        <div class="coffee-header__details"></div>
                    </div>
                    <div class="coffee-medium">
                        <div class="coffe-medium__exit"></div>
                        <div class="coffee-medium__arm"></div>
                        <div class="coffee-medium__liquid"></div>
                        <div class="coffee-medium__smoke coffee-medium__smoke-one"></div>
                        <div class="coffee-medium__smoke coffee-medium__smoke-two"></div>
                        <div class="coffee-medium__smoke coffee-medium__smoke-three"></div>
                        <div class="coffee-medium__smoke coffee-medium__smoke-for"></div>
                        <div class="coffee-medium__cup"></div>
                    </div>
                    <div class="coffee-footer"></div>
                    </div>  
                </div>}
                <form onSubmit={(event) => {
                    event.preventDefault();

                    (!id) ? this.createRecord() : this.updateRecord();
                }}
                onError={errors => console.log(errors)}
                className='fields_section'>

                    { showQuestion && questionData && 
                        <div id='modal_question'>
                            <div className='header'>{renderHTML(questionData.question)}</div>
                            <div className='content'>{
                                questionData.checkboxes.map(checkbox => {
                                    return <FormControlLabel
                                        control={
                                            <Checkbox checked={checkbox === selectedAnswer} name={checkbox} />}
                                        onChange={(event, value) => this.setState({ selectedAnswer: checkbox, current_question: questionData.question })}
                                        label={checkbox}
                                    />
                                })
                            }</div>
                        </div>
                    }
                    
                    <CRMFields usersZ={usersZ} data={formData} setFieldValue={this.setFieldValue} setFieldValueState={this.setFieldValueState} />

                    <div className='section -actions'>
                        <div className='actions'>
                            <Button 
                                className={(type_contract === 'contratto energia') ? 'action -selected' : 'action'}
                                onClick={() => {
                                    this.setFieldValueState('type_contract', 'contratto energia');
                                    this.setFieldValueState('sub_contract', '');
                                    this.setFieldValueState('sub_contract_extra', '');
                                }}
                                >
                                Contratto Energia
                            </Button>
                            <Button 
                                className={(type_contract === 'telco') ? 'action -selected' : 'action'}
                                onClick={() => {
                                    this.setFieldValueState('type_contract', 'telco');
                                    this.setFieldValueState('sub_contract', '');
                                    this.setFieldValueState('sub_contract_extra', '');
                                }}
                                >
                                Contratto Telco
                            </Button>
                            <Button 
                                className={(type_contract === 'vendite extra') ? 'action -selected' : 'action'}
                                onClick={() => {
                                    this.setFieldValueState('type_contract', 'vendite extra');
                                    this.setFieldValueState('sub_contract', '');
                                    this.setFieldValueState('sub_contract_extra', '');
                                }}
                            >
                                Vendite Extra
                            </Button>

                            <Button 
                                className={(type_contract === 'Assicurazione') ? 'action -selected' : 'action'}
                                onClick={() => {
                                    this.setFieldValueState('type_contract', 'Assicurazione');
                                    this.setFieldValueState('sub_contract', '');
                                    this.setFieldValueState('sub_contract_extra', '');
                                }}
                            >
                                Vendite Assicurazione
                            </Button>

                            { <ClearDataMain 
                                state={this.state}
                                formData={this.formData}
                                setFieldValue={this.setFieldValue}
                                setFieldValueState={this.setFieldValueState}
                                setState={(payload) => this.setState(payload)}
                            />}

                            { <CopyDataMain 
                                state={this.state}
                                formData={this.formData}
                                setFieldValue={this.setFieldValue}
                                setFieldValueState={this.setFieldValueState}
                                setState={(payload) => this.setState(payload)}
                            />}
                        </div>
                    </div>

                    

                    {/* TELCO Starts */}

                    {type_contract === 'telco' && <div className='section -add_info'>
                        <div className='add_info'>
                            <FormControl variant="outlined" className='field -select'>
                                <InputLabel id="demo-simple-select-outlined-label">Type of client</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={client_type}
                                    helperText='client_type'
                                    name='client_type'
                                    onChange={(event, val) => this.setFieldValueState('client_type', event.target.value)}
                                    label="Type of client"
                                    >
                                    <MenuItem value='domestico'>Domestico</MenuItem>
                                    <MenuItem value='business'>Business</MenuItem>
                                </Select>
                                <FormHelperText>client_type</FormHelperText>
                            </FormControl>
                            <Autocomplete
                                freeSolo
                                className='field -product'
                                onChange={(event, val) => this.setFieldValueState('product', val)}
                                options={products.filter(product => {
                                    return ["Internet", "Mobile"].some(type => product.type === type)
                                })}
                                value={product}
                                helperText='product'
                                name='product'
                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.product_name)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Product"
                                    margin="normal"
                                    variant="outlined"
                                    helperText='product'
                                    name='product'
                                    InputProps={{ ...params.InputProps }}
                                />
                                )}
                            />
                        </div>
                    </div>}

                    {/* Business Info */}
                    

                    {/* Domestico Info */}
                    

                    {showDomisticoIntestatario(this.state) && <DomesticoFields data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue}/>}
                    {showBusinessIntestatario(this.state) && <BusinessFields data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue}/>}
                    {(showDomisticoIntestatario(this.state) || showBusinessIntestatario(this.state)) && <NewsletterFields role={role} data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification} openManageScript={this.openManageScript} subscribeUser={this.subscribeUser}/>}
                    {showPuntoFornitura(this.state) && <PuntoDiFornituraFields data={formData} setFieldValue={this.setFieldValue} setAddress={this.setAddress}/>}
                    {showResidenza(this.state) && <SedeLegaleFields data={formData} setFieldValue={this.setFieldValue}/>}
                    {showIndirizzoFields(this.state) && <IndirizzoFields data={formData} setFieldValue={this.setFieldValue}/>}

                    {/* {renderIntestatarioDomestico()} -
                    {renderPuntiDiFornitura()} - 
                    {renderResidenzaSedeLegale()} - 
                    {renderIndizzoDiSpedizione()} */}

                    {showTelcoOperation(this.state) && <div className='section -add_info'>
                        <div className='add_info'>
                            <FormControl variant="outlined" className='field -select'>
                                <InputLabel id="demo-simple-select-outlined-label" id='operazione_part'>Operazione</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={operation}
                                    onChange={(event, val) => this.setFieldValueState('operation', event.target.value)}
                                    label="Operazione"
                                    >
                                    {!itsTiProduct(this.state) && <MenuItem value='cambio mobile'>Cambio - Mobile</MenuItem>}
                                    <MenuItem value='cambio linea fissa'>Cambio - Linea Fissa</MenuItem>
                                    {!itsTiProduct(this.state) && <MenuItem value='cambio modile linea fissa'>Cambio - Mobile + Linea Fissa</MenuItem>}
                                    <MenuItem value='nuova attivazione'>Nuova Attivazione</MenuItem>
                                </Select>
                                <FormHelperText>operation</FormHelperText>
                            </FormControl>
                        </div>
                    </div>}

                    {showMigrazioneMobileFields(this.state) && <MigrazioneMobileFields data={formData} setFieldValue={this.setFieldValue}/>}
                    {showMigrazioneLineaFields(this.state) && <MigrazioneLineaFields data={formData} setFieldValue={this.setFieldValue}/>}
                    {showMigrazioneMobileLineaFields(this.state) && <MigrazioneMobileLineaFields data={formData} setFieldValue={this.setFieldValue}/>}

                    {showTelcoPagamento(this.state) && operation && <div className='section -add_info'>
                        <div className='add_info'>
                            <FormControl variant="outlined" className='field -select'>
                                <InputLabel id="demo-simple-select-outlined-label">Tipo De Pagamento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={payment_type}
                                    onChange={(event, val) => this.setFieldValueState('payment_type', event.target.value)}
                                    label="Tipo De Pagamento"
                                    >
                                    <MenuItem value='rid'>Rid</MenuItem>
                                    <MenuItem value='carta'>Carta Di Credito</MenuItem>
                                    <MenuItem value='bollettino-postale'>Bollettino Postale</MenuItem>
                                </Select>
                                <FormHelperText>payment_type</FormHelperText>
                            </FormControl>
                        </div>
                    </div>}

                    {showPagamentoFields(this.state) && <PagamentoFields 
                        typeClient={this.state.client_type} 
                        data={formData} 
                        setFieldValue={this.setFieldValue}
                        openNotification={this.props.openNotification}
                        />}

                    {showDelegatoCheckTelco(this.state) && operation && <div className='section -checkbox'>
                        <FormControlLabel
                            control={<Checkbox checked={delegato} name="delegato" />}
                            onChange={(event, value) => this.setFieldValueState('delegato', value)}
                            label="Delegato"
                        />
                    </div>}

                    {showDelegatoFields(this.state) && <DelegatoFields data={formData} setFieldValue={this.setFieldValue}/>}
                    {showDescriptionFields(this.state) && <DescriptionFields data={formData} setFieldValue={this.setFieldValue}/>}

                    {showTelcoMainContent(this.state) && <div className='section -actions'>
                        <div className='actions'>
                            <Button 
                                onClick={() => {
                                    this.setFieldValueState('sub_contract', 'lead crosseling')
                                    // this.createLead();
                                }}
                                className={(sub_contract === 'lead crosseling') ? 'action -selected': 'action'}>
                                Lead Crosseling
                            </Button>
                            <Button 
                                onClick={() => {
                                    transferInfoToVendite(formData, this.setFieldValue, type_contract);
                                    this.setFieldValueState('sub_contract', 'vendite extra')}
                                } 
                                className={(sub_contract === 'vendite extra') ? 'action -selected': 'action'}>
                                Vendite Extra
                            </Button>

                            <Button 
                                onClick={() => {
                                    transferDataToAltra(formData, this.state, this.setFieldValue, this.setFieldValueState);
                                    this.setFieldValueState('sub_sub_contract', 'altra vendita')}
                                } 
                                className={(sub_sub_contract === 'altra vendita') ? 'action -selected': 'action'}>
                                Altra Vendita
                            </Button>

                            { <ClearDataAltra 
                                state={this.state}
                                formData={this.formData}
                                setFieldValue={this.setFieldValue}
                                setFieldValueState={this.setFieldValueState}
                                setState={(payload) => this.setState(payload)}
                            />}
                        </div>
                    </div>}


                    {/* TELCO ADDITIONAL */}

                    { type_contract === 'telco' && sub_sub_contract === 'altra vendita' && <>
                    {<div className='section -add_info'>
                        <div className='add_info'>
                            <FormControl variant="outlined" className='field -select'>
                                <InputLabel id="demo-simple-select-outlined-label">Type of client</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={client_type_altra}
                                    helperText='client_type_altra'
                                    name='client_type'
                                    onChange={(event, val) => this.setFieldValueState('client_type_altra', event.target.value)}
                                    label="Type of client"
                                    >
                                    <MenuItem value='domestico'>Domestico</MenuItem>
                                    <MenuItem value='business'>Business</MenuItem>
                                </Select>
                                <FormHelperText>client_type_altra</FormHelperText>
                            </FormControl>
                            <Autocomplete
                                freeSolo
                                className='field -product'
                                onChange={(event, val) => this.setFieldValueState('product_altra', val)}
                                options={products.filter(product => ["Internet", "Mobile"].some(type => product.type === type))}
                                value={product_altra}
                                helperText='product_altra'
                                name='product'
                                getOptionLabel={(option) => (typeof option === 'string' ? option : option.product_name)}
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Product"
                                    margin="normal"
                                    variant="outlined"
                                    helperText='product_altra'
                                    name='product'
                                    InputProps={{ ...params.InputProps }}
                                />
                                )}
                            />
                        </div>
                    </div>}

                    {showDomisticoIntestatarioAltra(this.state) && <DomesticoFieldsAltra data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue}/>}
                    {showBusinessIntestatarioAltra(this.state) && <BusinessFieldsAltra data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue}/>}
                    {showPuntoFornituraAltra(this.state) && <PuntoDiFornituraFieldsAltra data={formData} setFieldValue={this.setFieldValue} setAddress={this.setAddress}/>}
                    {showResidenzaAltra(this.state) && <SedeLegaleFieldsAltra data={formData} setFieldValue={this.setFieldValue}/>}
                    {showIndirizzoFieldsAltra(this.state) && <IndirizzoFieldsAltra data={formData} setFieldValue={this.setFieldValue}/>}

                    {/* {renderIntestatarioDomestico()} -
                    {renderPuntiDiFornitura()} - 
                    {renderResidenzaSedeLegale()} - 
                    {renderIndizzoDiSpedizione()} */}

                    {showTelcoOperationAltra(this.state) && <div className='section -add_info'>
                        <div className='add_info'>
                            <FormControl variant="outlined" className='field -select'>
                                <InputLabel id="demo-simple-select-outlined-label" id='operazione_part'>Operazione</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={operation_altra}
                                    onChange={(event, val) => this.setFieldValueState('operation_altra', event.target.value)}
                                    label="Operazione"
                                    >
                                    <MenuItem value='cambio mobile'>Cambio - Mobile</MenuItem>
                                    <MenuItem value='cambio linea fissa'>Cambio - Linea Fissa</MenuItem>
                                    <MenuItem value='cambio modile linea fissa'>Cambio - Mobile + Linea Fissa</MenuItem>
                                    <MenuItem value='nuova attivazione'>Nuova Attivazione</MenuItem>
                                </Select>
                                <FormHelperText>operation_altra</FormHelperText>
                            </FormControl>
                        </div>
                    </div>}

                    {showMigrazioneMobileFieldsAltra(this.state) && <MigrazioneMobileFieldsAltra data={formData} setFieldValue={this.setFieldValue}/>}
                    {showMigrazioneLineaFieldsAltra(this.state) && <MigrazioneLineaFieldsAltra data={formData} setFieldValue={this.setFieldValue}/>}
                    {showMigrazioneMobileLineaFieldsAltra(this.state) && <MigrazioneMobileLineaFieldsAltra data={formData} setFieldValue={this.setFieldValue}/>}

                    {showTelcoPagamentoAltra(this.state) && <div className='section -add_info'>
                        <div className='add_info'>
                            <FormControl variant="outlined" className='field -select'>
                                <InputLabel id="demo-simple-select-outlined-label">Tipo De Pagamento</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={payment_type_altra}
                                    onChange={(event, val) => this.setFieldValueState('payment_type_altra', event.target.value)}
                                    label="Tipo De Pagamento"
                                    >
                                    <MenuItem value='rid'>Rid</MenuItem>
                                    <MenuItem value='carta'>Carta Di Credito</MenuItem>
                                </Select>
                                <FormHelperText>payment_type_altra</FormHelperText>
                            </FormControl>
                        </div>
                    </div>}

                    {showPagamentoFieldsAltra(this.state) && <PagamentoFieldsAltra typeClient={this.state.client_type_altra} data={formData} setFieldValue={this.setFieldValue}/>}

                    {showDelegatoCheckTelcoAltra(this.state) && <div className='section -checkbox'>
                        <FormControlLabel
                            control={<Checkbox checked={delegato_altra} name="delegato" />}
                            onChange={(event, value) => this.setFieldValueState('_altra', value)}
                            label="Delegato"
                        />
                    </div>}

                    {showDelegatoFieldsAltra(this.state) && <DelegatoFieldsAltra data={formData} setFieldValue={this.setFieldValue}/>}
                    {showDescriptionFieldsAltra(this.state) && <DescriptionFieldsAltra data={formData} setFieldValue={this.setFieldValue}/>}
                    </>}


                    {showTelcoMainContent(this.state) && sub_sub_contract === 'altra vendita' && <div className='section -actions'>
                        <div className='actions'>
                            <Button 
                                onClick={() => {
                                    this.setFieldValueState('sub_contract', 'lead crosseling')
                                    // this.createLead();
                                }}
                                className={(sub_contract === 'lead crosseling') ? 'action -selected': 'action'}>
                                Lead Crosseling
                            </Button>
                            <Button 
                                onClick={() => {
                                    transferInfoToVendite(formData, this.setFieldValue, type_contract);
                                    this.setFieldValueState('sub_contract', 'vendite extra')}
                                } 
                                className={(sub_contract === 'vendite extra') ? 'action -selected': 'action'}>
                                Vendite Extra
                            </Button>

                            { allowUsersRep &&<ClearDataExtra 
                                state={this.state}
                                formData={this.formData}
                                setFieldValue={this.setFieldValue}
                                setFieldValueState={this.setFieldValueState}
                                setState={(payload) => this.setState(payload)}
                            />}
                        </div>
                    </div>}




                    {/* TELCO ADDITIONAL END */}

                    {/* TELCO END */}

                    {/* INSURANCE PART START */}

                    

                    {/* INSURANCE PART END */}


                    {/* ENERGIA PART */}

                    {type_contract === 'contratto energia' && <div className='section -add_info'>
                        <div className='add_info'>
                            <FormControl variant="outlined" className='field -select'>
                                <InputLabel id="demo-simple-select-outlined-label">Type of client</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={client_type}
                                    onChange={(event, val) => this.setFieldValueState('client_type', event.target.value)}
                                    label="Type of client"
                                    >
                                    <MenuItem value='domestico'>Domestico</MenuItem>
                                    <MenuItem value='business'>Business</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>}

                    {openNewsLetterDialog && <NewsLetterPopup text={infoDialogText} close={this.onCloseNewsLetteroDialog} confirm={
                            () => {
                                const newLetterSection = document.getElementById('mailing_section') || document.getElementById('mailing_section');

                                if(newLetterSection) {
                                    setTimeout(() => {
                                        newLetterSection.scrollIntoView();
                                    }, 250)
                                }
                                    

                                this.onCloseNewsLetteroDialog();
                            }
                    }/>}

                    {showEnergiaDomestico(this.state) && <DomesticoEnergiaFields data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue}/>}
                    {/* showDomisticoIntestatarioEnergia(this.state) && */}

                    {showEnergiaBusiness(this.state) && <BusinessEnergiaFields data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue}/>}

                    {(showEnergiaDomestico(this.state) || showEnergiaBusiness(this.state)) && <NewsletterFields role={role} openManageScript={this.openManageScript} data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification} subscribeUser={this.subscribeUser}/>}

                    {showEnergiaResidenza(this.state) && <ResidenzaEnergiaFields data={formData} setFieldValue={this.setFieldValue}/>}

                    {showEnergiaResidenza(this.state) && <div className='section -actions'>

                        {openInfoDialog && <InfoDialog text={infoDialogText} close={this.onCloseInfoDialog}/>}
                        <div className='actions' id='product_type_part'>
                            <Button 
                                className={(type_product === 'luce') ? 'action -selected' : 'action'}
                                onClick={() => {
                                    this.setFieldValueState('type_product', 'luce');
                                    this.onOpenInfoDialog('Hai chiesto anche per la seconda utenza?');
                                }}
                                >
                                LUCE
                            </Button>
                            <Button 
                                className={(type_product === 'gas') ? 'action -selected' : 'action'}
                                onClick={() => {
                                    this.setFieldValueState('type_product', 'gas');
                                    this.onOpenInfoDialog('Hai chiesto anche per la seconda utenza?')
                                }}
                                >
                                GAS
                            </Button>
                            <Button 
                                className={(type_product === 'dual') ? 'action -selected' : 'action'}
                                onClick={() => this.setFieldValueState('type_product', 'dual')}
                            >
                                DUAL
                            </Button>
                        </div>
                    </div>}

                    {showPotentialInfoEnergia(this.state) && <PotentialInfoEnergiaFields 
                        products={products} 
                        data={formData} 
                        operations={operations}
                        typeProduct={type_product}
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        />}

                    {showPuntoFornituraEnergia(this.state) && <PuntoDiFornituraEnergiaFields 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'EI', 'Allacc.') && <LuceEiAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'EI', 'Prima Att.') && <LuceEiPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'EI', 'Subentro') && <LuceEiSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'EI', 'Cambio') && <LuceEiCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'EI', 'Voltura') && <LuceEiVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}





                    {showLuce(this.state, 'EE', 'Allacc.') && <LuceEEAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'EE', 'Prima Att.') && <LuceEEPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'EE', 'Subentro') && <LuceEESubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'EE', 'Cambio') && <LuceEECambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'EE', 'Voltura') && <LuceEEVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}



                        {/* LUCE FW  */}


                    {showLuce(this.state, 'FW', 'Allacc.') && <LuceFWAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'FW', 'Prima Att.') && <LuceFWPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'FW', 'Subentro') && <LuceFWSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'FW', 'Cambio') && <LuceFWCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'FW', 'Voltura') && <LuceFWVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* END LUVE FW */}






                        {/* LUCE IR */}

                    {showLuce(this.state, 'IR', 'Allacc.') && <LuceIRAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'IR', 'Prima Att.') && <LuceIRPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'IR', 'Subentro') && <LuceIRSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'IR', 'Cambio') && <LuceIRCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'IR', 'Voltura') && <LuceIRVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}



                        {/* GN */}

                        {showLuce(this.state, 'GN', 'Allacc.') && <LuceIRAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'GN', 'Prima Att.') && <LuceIRPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'GN', 'Subentro') && <LuceIRSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'GN', 'Cambio') && <LuceIRCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'GN', 'Voltura') && <LuceIRVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* SG */}

                    {showLuce(this.state, 'SG', 'Allacc.') && <LuceSGAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'SG', 'Prima Att.') && <LuceSGPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'SG', 'Subentro') && <LuceSGSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'SG', 'Cambio') && <LuceSGCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'OP', 'Cambio') && <LuceSGCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'SG', 'Voltura') && <LuceSGVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}



                        {/* WK */}

                    {showLuce(this.state, 'WK', 'Allacc.') && <LuceWKAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'WK', 'Prima Att.') && <LuceWKPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'WK', 'Subentro') && <LuceWKSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'WK', 'Cambio') && <LuceWKCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'WK', 'Voltura') && <LuceWKVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                        {/* ES */}

                    {showLuce(this.state, 'ES', 'Allacc.') && <LuceESAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'ES', 'Prima Att.') && <LuceESPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'ES', 'Subentro') && <LuceESSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'ES', 'Cambio') && <LuceESCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'ES', 'Voltura') && <LuceESVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* AA */}

                    {showLuce(this.state, 'AA', 'Allacc.') && <LuceESAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'AA', 'Prima Att.') && <LuceESPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'AA', 'Subentro') && <LuceESSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'AA', 'Cambio') && <LuceESCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'AA', 'Voltura') && <LuceESVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* CV */}

                    {showLuce(this.state, 'CV', 'Allacc.') && <LuceCVAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'CV', 'Prima Att.') && <LuceCVPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'CV', 'Subentro') && <LuceCVSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'CV', 'Cambio') && <LuceCVCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'CV', 'Voltura') && <LuceCVVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                        {/* EG */}

                    {showLuce(this.state, 'EG', 'Allacc.') && <LuceEGAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'EG', 'Prima Att.') && <LuceEGPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'EG', 'Subentro') && <LuceEGSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'EG', 'Cambio') && <LuceEGCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'EG', 'Voltura') && <LuceEGVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* IL */}


                    {showLuce(this.state, 'IL', 'Allacc.') && <LuceILAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'IL', 'Prima Att.') && <LuceILPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'IL', 'Subentro') && <LuceILSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showLuce(this.state, 'IL', 'Cambio') && <LuceILCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showLuce(this.state, 'IL', 'Voltura') && <LuceILVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}



                        {/* GAS EI */}

                    {showGas(this.state, 'EI', 'Allacc.') && <GasEIAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'EI', 'Prima Att.') && <GasEIPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'EI', 'Subentro') && <GasEISubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'EI', 'Cambio') && <GasEICambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'EI', 'Voltura') && <GasEIVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                        
                        {/* EE */}


                    {showGas(this.state, 'EE', 'Allacc.') && <GasEEAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'EE', 'Prima Att.') && <GasEEPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'EE', 'Subentro') && <GasEESubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'EE', 'Cambio') && <GasEECambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'EE', 'Voltura') && <GasEEVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* IR */}

                    {showGas(this.state, 'IR', 'Allacc.') && <GasIRAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'IR', 'Prima Att.') && <GasIRPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'IR', 'Subentro') && <GasIRSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'IR', 'Cambio') && <GasIRCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'IR', 'Voltura') && <GasIRVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {/* GN */}


                    {showGas(this.state, 'GN', 'Allacc.') && <GasIRAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'GN', 'Prima Att.') && <GasIRPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'GN', 'Subentro') && <GasIRSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'GN', 'Cambio') && <GasIRCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'GN', 'Voltura') && <GasIRVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* SG */}


                    {showGas(this.state, 'SG', 'Allacc.') && <GasSGAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'SG', 'Prima Att.') && <GasSGPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'SG', 'Subentro') && <GasSGSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'SG', 'Cambio') && <GasSGCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'SG', 'Voltura') && <GasSGVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* WK */}

                    {showGas(this.state, 'WK', 'Allacc.') && <GasWKAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'WK', 'Prima Att.') && <GasWKPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'WK', 'Subentro') && <GasWKSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'WK', 'Cambio') && <GasWKCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'WK', 'Voltura') && <GasWKVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* ES */}


                    {showGas(this.state, 'ES', 'Allacc.') && <GasESAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'ES', 'Prima Att.') && <GasESPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'ES', 'Subentro') && <GasESSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'ES', 'Cambio') && <GasESCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'ES', 'Voltura') && <GasESVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* AA */}

                    {showGas(this.state, 'AA', 'Allacc.') && <GasESAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'AA', 'Prima Att.') && <GasESPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'AA', 'Subentro') && <GasESSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'AA', 'Cambio') && <GasESCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'AA', 'Voltura') && <GasESVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* CV */}


                    {showGas(this.state, 'CV', 'Allacc.') && <GasCVAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'CV', 'Prima Att.') && <GasCVPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'CV', 'Subentro') && <GasCVSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'CV', 'Cambio') && <GasCVCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'CV', 'Voltura') && <GasCVVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                        
                        
                        {/* EG */}


                    {showGas(this.state, 'EG', 'Allacc.') && <GasEGAllac 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'EG', 'Prima Att.') && <GasEGPrimaAtt 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'EG', 'Subentro') && <GasEGSubentro 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'EG', 'Cambio') && <GasEGCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                    {showGas(this.state, 'EG', 'Voltura') && <GasEGVoltura 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}


                        {/* GAS IL */}

                    {showGas(this.state, 'IL', 'Cambio') && <GasILCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}

                    {showGas(this.state, 'OP', 'Cambio') && <GasILCambio 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        data={formData} 
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        theSameAddress={theSameAddress}
                        />}



                    {/* 
                    
                    
                    NE PRODUCT
                    
                    
                    
                    */}

                    {showNeProduct(type_contract, product_ee) && <LuceNeProduct
                            product_ee={product_ee}
                            product_gas={product_gas}
                            operation_ee={operation_ee}
                            operation_gas={operation_gas}
                            data={formData} 
                            client_type={client_type}
                            setFieldValue={this.setFieldValue}
                            setFieldValueState={this.setFieldValueState}
                            theSameAddress={theSameAddress}
                    />}


                    {showNeProduct(type_contract, product_gas) && <GasNeProduct
                            product_ee={product_ee}
                            product_gas={product_gas}
                            operation_ee={operation_ee}
                            operation_gas={operation_gas}
                            data={formData} 
                            client_type={client_type}
                            setFieldValue={this.setFieldValue}
                            setFieldValueState={this.setFieldValueState}
                            theSameAddress={theSameAddress}
                    />}



                    {/* 
                    
                    
                    END NE PRODUCT
                    
                    
                    
                    */}

                        

                    {showEnergiaVoltura(this.state) && <EnergiaVoltura
                        data={formData} 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                        client_type={client_type}
                    />}

                    {showEnergiaPagamento(this.state) && 
                        <EnergiaPagamento
                            product_ee={product_ee}
                            product_gas={product_gas}
                            operation_ee={operation_ee}
                            operation_gas={operation_gas}
                            data={formData} 
                            client_type={client_type}
                            setFieldValue={this.setFieldValue}
                            openNotification={this.props.openNotification}
                            setFieldValueState={this.setFieldValueState}/>
                    }

                    {showEnergiaDelegato(this.state) &&  <div className='section -checkbox'>
                        <FormControlLabel
                            control={<Checkbox checked={delegato} name="delegato" />}
                            onChange={(event, value) => this.setFieldValueState('delegato', value)}
                            label="Delegato"
                        />
                    </div>}

                    {showEnergiaDelegato(this.state) && delegato &&
                        <EnergiaDelegato
                        data={formData} 
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}/>
                    }

                    {showEnergiaDocumento(this.state) && 
                        <EnergiaDocumento
                        data={formData} 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        operation_ee={operation_ee}
                        operation_gas={operation_gas}
                        client_type={client_type}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}/>
                    }

                    {showEnergiaPagamento(this.state) && 
                        <EnergiaDescription
                            product_ee={product_ee}
                            product_gas={product_gas}
                            operation_ee={operation_ee}
                            operation_gas={operation_gas}
                            client_type={client_type}
                            data={formData} 
                            setFieldValue={this.setFieldValue}
                            setFieldValueState={this.setFieldValueState}/>
                    }

                    {showEnergiaSubAcctions(this.state) && <div className='section -actions'>
                        <div className='actions'>
                            <Button 
                                onClick={() => {
                                    this.setFieldValueState('sub_contract', 'lead crosseling');
                                }}
                                className={(sub_contract === 'lead crosseling') ? 'action -selected': 'action'}>
                                Lead Crosseling
                            </Button>
                            <Button 
                                onClick={() => {
                                    transferInfoToVendite(formData, this.setFieldValue, type_contract);
                                    const valueToSet = sub_contract === 'vendite extra' ? null : 'vendite extra';
                                    this.setFieldValueState('sub_contract', valueToSet)}
                                } 
                                className={(sub_contract === 'vendite extra') ? 'action -selected': 'action'}>
                                Vendite Extra
                            </Button>
                            <Button 
                                onClick={() => {
                                    transferInfoToVendite(formData, this.setFieldValue, type_contract);
                                    const valueToSet = sub_insurance === 'Assicurazione' ? null : 'Assicurazione';
                                    this.setFieldValueState('sub_insurance', valueToSet)}
                                } 
                                className={(sub_insurance === 'Assicurazione') ? 'action -selected': 'action'}>
                                Vendite Assicurazione
                            </Button>
                            { <ClearDataExtra 
                                state={this.state}
                                formData={this.formData}
                                setFieldValue={this.setFieldValue}
                                setFieldValueState={this.setFieldValueState}
                                setState={(payload) => this.setState(payload)}
                            />}
                        </div>
                    </div>}

                    {showEnergiaOther(this.state) && <EnergiaOtherExtra
                        data={formData} 
                        product_ee={product_ee}
                        sub_contract={sub_contract}
                        product_gas={product_gas}
                        createLead={this.createLead}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                    />}

                    {showEnergiaCodice(this.state) &&  <EnergiaCodice
                        data={formData} 
                        product_ee={product_ee}
                        product_gas={product_gas}
                        setFieldValue={this.setFieldValue}
                        setFieldValueState={this.setFieldValueState}
                    />}

                    {/* {showEnergiaCheckbox(this.state) && <EnergiaCheckBox
                        data={formData} 
                        setFieldValue={this.setFieldValue}
                    />} */}






                    {/* INSURANCE */}

                    {/* EXTRA CONTRACT */}
                    {sub_insurance === 'Assicurazione' && <h2 className='vendita_extra_header'> Vendite Assicurazione </h2>}
                    {showProductInsurance(this.state, product_insurance) && <ProductInsurance 
                                                        data={formData} 
                                                        setFieldValue={this.setFieldValue} 
                                                        setFieldValueState={this.setFieldValueState}
                                                        product_insurance={product_insurance}
                                                        />}

                    {showContactInfoInsuranceMoto(this.state) && <ContactInfoInsurance product_insurance={product_insurance} data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoInsuranceHome(this.state) && <ContactInfoInsuranceHome product_insurance={product_insurance} data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}

                    {showDelegatoCheckboxInsurance(this.state) &&  <div className='section -checkbox'>
                        <FormControlLabel
                            control={<Checkbox checked={delegato} name="delegato" />}
                            onChange={(event, value) => this.setFieldValueState('delegato', value)}
                            label="Delegato"
                        />
                    </div>}

                    {showInsuranceDelegato(this.state) && <InsuranceDelegato
                                                        data={formData} 
                                                        setFieldValue={this.setFieldValue}
                                                        />
                    }

                    {type_contract === 'Assicurazione' && <NewsletterFields role={role} openManageScript={this.openManageScript} data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification} subscribeUser={this.subscribeUser}/>}



                    {sub_contract === 'vendite extra' && <h2 className='vendita_extra_header'> Vendite extra </h2>}


                    {/* EXTRA CONTRACT */}
                    {showProdotoExtra(this.state) && <ProductExtra 
                                                        data={formData} 
                                                        setFieldValue={this.setFieldValue} 
                                                        setFieldValueState={this.setFieldValueState}
                                                        prodotto={prodotto}
                                                        />}
                    {showContactInfoExtraIrRasa(this.state) && <ContactInfoExtraIrRasa subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoExtraIrManutenzione(this.state) && <ContactInfoExtraIrManutenzione subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoExtraLinkem(this.state) && <ContactInfoExtraLinkem subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoExtraTado(this.state) && <ContactInfoExtraTado subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoExtraEiFlexiManutenzione(this.state) && <ContactInfoExtraEiFlexiManutenzione subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoExtraSgFibra(this.state) && <ContactInfoExtraSgFibra subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoExtraCo2(this.state) && <ContactInfoExtraCo2 subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoExtraMelita(this.state) && <ContactInfoExtraMelita subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoExtraGpl(this.state) && <ContactInfoExtraGpl subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}
                    {showContactInfoExtraIrRasaBusiness(this.state) && <ContactInfoExtraIrRasaBusiness subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}

                    {showContactInfoExtraSgFibraBusiness(this.state) && <ContactInfoExtraSgFibraBusiness subContract={sub_contract} data={formData} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}

                    {showContactInfoExtraTimVision(this.state) && <ContactInfoExtraTimVision subContract={sub_contract} data={formData} prodotto={prodotto} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification}/>}

                    {showContactInfoExtraSgFibraBusiness(this.state) && <div className='section -add_info'>
                        <div className='add_info'>
                            <FormControl variant="outlined" className='field -select'>
                                <InputLabel id="demo-simple-select-outlined-label">Operazione</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={operation_extra}
                                    onChange={(event, val) => this.setFieldValueState('operation_extra', event.target.value)}
                                    label="Operazione"
                                    >
                                    <MenuItem value='cambio mobile'>Cambio - Mobile</MenuItem>
                                    <MenuItem value='cambio linea fissa'>Cambio - Linea Fissa</MenuItem>
                                    <MenuItem value='cambio modile linea fissa'>Cambio - Mobile + Linea Fissa</MenuItem>
                                    <MenuItem value='nuova attivazione'>Nuova Attivazione</MenuItem>
                                </Select>
                                <FormHelperText>operation</FormHelperText>
                            </FormControl>
                        </div>
                    </div>}

                    {showContactInfoExtraSgFibraBusiness(this.state) && (operation_extra && operation_extra !== 'nuova attivazione') && <MigrazioneExtraFields
                            subContract={sub_contract} 
                            data={formData} 
                            setFieldValue={this.setFieldValue}
                        />
                    }

                    {(sub_contract ==='vendite extra' && prodotto) && <ExtraCodice data={formData} setFieldValue={this.setFieldValue}/>}

                    {type_contract === 'vendite extra' && <NewsletterFields role={role} openManageScript={this.openManageScript} data={formData} setFieldValueState={this.setFieldValueState} setFieldValue={this.setFieldValue} openNotification={this.props.openNotification} subscribeUser={this.subscribeUser}/>}
                    
                    {showDelegatoExtra(this.state) &&  <div className='section -checkbox'>
                        <FormControlLabel
                            control={<Checkbox checked={delegato_extra} name="delegato" />}
                            onChange={(event, value) => this.setFieldValueState('delegato_extra', value)}
                            label="Delegato"
                        />
                    </div>}

                    {showDelegatoExtra(this.state) &&  delegato_extra && <DelegatoFieldsExtra data={formData} setFieldValue={this.setFieldValue}/>}

                    {showDescriptionExtra(this.state) && <DescriptionFieldsExtra data={formData} setFieldValue={this.setFieldValue}/>}

                    {(type_contract === 'vendite extra' && prodotto) && <ExtraCodice data={formData} setFieldValue={this.setFieldValue}/>}

                    {(type_contract === 'vendite extra' || sub_contract === 'vendite extra' && prodotto) && <div className='section -actions'>
                        <div className='actions'>
                            <Button 
                                className={(sub_contract === 'lead crosseling') ? 'action -selected' : 'action'}
                                onClick={() => {
                                    this.setFieldValueState('sub_contract', 'lead crosseling')
                                    // this.createLead();
                                }}
                                >
                                Lead Crosselling
                            </Button>
                        </div>
                    </div>}

                    {(sub_contract === 'vendite extra' && prodotto ) && <div className='section -actions'>
                        <div className='actions'>
                            <Button 
                                className={(sub_contract_extra === 'lead crosseling') ? 'action -selected' : 'action'}
                                onClick={() => {
                                    this.setFieldValueState('sub_contract_extra', 'lead crosseling')
                                }}
                                >
                                Lead Crosselling
                            </Button>
                        </div>
                    </div>}
                    {showDelegatoExtraOther(this.state) && <OtherExtra 
                        data={formData}
                        setFieldValue={this.setFieldValue}
                        createLead={this.createLead}
                    />}

                    {/* {showEnergiaCheckbox(this.state) && <div className='section -checkbox'>
                        <FormControlLabel
                            control={<Checkbox checked={newsletter} name="checkedA" />}
                            onChange={(event, value) => this.setFieldValueState('newsletter', value)}
                            label="Newsletter IT"
                            helperText='newsletter'
                            name='newsletter'
                        >
                        </FormControlLabel>
                        <FormControlLabel
                            control={<Checkbox checked={marketing_terzi} name="checkedA" />}
                            onChange={(event, value) => this.setFieldValueState('marketing_terzi', value)}
                            label="Marketing Terzi"
                            helperText='marketing_terzi'
                            name='marketing_terzi'
                        />
                    </div>} */}

                    {showEnergiaCheckbox(this.state) && <SendEmailFields
                            data={formData} 
                            openNotification={this.props.openNotification}
                            product_ee={product_ee}
                            product_gas={product_gas}
                            product={product}
                            subscribeUser={this.subscribeUser}
                            prodotto={prodotto}
                            operation_ee={operation_ee}
                            operation_gas={operation_gas}
                            operation={operation}
                            type_contract={type_contract}
                            client_type={client_type}
                            type_product={type_product}
                            setFieldValue={this.setFieldValue}
                            setFieldValueState={this.setFieldValueState}
                            newsletter={newsletter}
                            marketing_terzi={marketing_terzi}
                        />}

                    {showEnergiaCheckbox(this.state) && <Passaparola 
                            data={formData} 
                            openNotification={this.props.openNotification}
                            product_ee={product_ee}
                            product_gas={product_gas}
                            product={product}
                            prodotto={prodotto}
                            operation_ee={operation_ee}
                            operation_gas={operation_gas}
                            operation={operation}
                            type_contract={type_contract}
                            client_type={client_type}
                            type_product={type_product}
                            setFieldValue={this.setFieldValue}
                            setFieldValueState={this.setFieldValueState}
                            newsletter={newsletter}
                            marketing_terzi={marketing_terzi}
                        />
                    }

                    {/* {showEnergiaCheckbox(this.state) && newsletter &&
                        <SubscriptionFlow 
                            data={formData} 
                            openNotification={this.props.openNotification}
                            product_ee={product_ee}
                            product_gas={product_gas}
                            subscribeUser={this.subscribeUser}
                            setFieldValue={this.setFieldValue}
                            setFieldValueState={this.setFieldValueState}
                        />
                    } */}

                    {showEnergiaCheckbox(this.state) &&
                        <CodiceScontoManaged 
                            data={formData}
                            role={role}
                            type_product={type_product}
                            type_contract={type_contract}
                            managedFields={managedFields}
                            openNotification={this.props.openNotification}
                            statusManagePopup={this.statusManagePopup}
                            setFieldValue={this.setFieldValue}
                        />
                    }
                    

                    {/* {renderMigrazioneMobileLinea()} - 
                    {renderPagamentoRid()} - 
                    {renderDelegato()}
                    {renderDescription()} */}
                    
                
                {showEnergiaAction(this.state) && !id && <div className='actions' >
                    <Button className='action' type='submit'>
                        Add
                    </Button>
                </div>}

                {/* onSubmit={this.createRecord} */}

                {showEnergiaAction(this.state) && id && <div className='actions'>
                    <Button className='action' type='submit'>
                        Update
                    </Button>
                </div>}

                {/* onSubmit={this.updateRecord} */}

                {showDraftCreate({ ...this.state, ...this.formData}) && !id && <div className='actions'>
                    <Button className='action' onClick={this.createRecordDraft}>
                        Salva in bozza
                    </Button>
                </div>}

                {showDraftCreate({ ...this.state, ...this.formData}) && id && <div className='actions'>
                    <Button className='action' onClick={this.updateRecordDraft}>
                        Salva in bozza
                    </Button>
                </div>}
                </form>
                
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateRecord);

const defaultState = {
    type_contract: '',
    sub_contract: '',
    client_type: '',
    product: '',
    products: [],
    operations: [],
    usersZ: [],
    operation: '',
    payment_type: '',
    delegato: false,
    newsletter: false,
    marketing_terzi: false,
    delegato_extra: false,
    type_product: '',
    product_ee: {},
    product_gas: {},
    operation_ee: {},
    operation_gas: {},
};


const getAddressObject = (address_components) => {
    const ShouldBeComponent = {
        home: ["street_number"],
        postal_code: ["postal_code"],
        street: ["street_address", "route"],
        region: [
        "administrative_area_level_1",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5"
        ],
        region2: ["administrative_area_level_2"],
        city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4"
        ],
        country: ["country"]
    };

    let address = {
        home: "",
        postal_code: "",
        street: "",
        region: "",
        city: "",
        country: "",
        region2: ""
    };
    address_components.forEach(component => {
        for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
            address[shouldBe] = {
                short: component.short_name,
                long: component.long_name
            };
        }
        }
    });
    return address;
}


// START TELCO

function CRMFields (props) {
    const { setFieldValue, setFieldValueState, usersZ, data } = props;

    const [contact_id] = React.useState(data.contact_id || '');
    const [contact_owner] = React.useState(data.contact_owner || '');
    const [code] = React.useState(data.code || '');
    const [lead_id] = React.useState(data.lead_id || '');

    return (
        <div className='section'>
            <div className='head_text'>CRM Info</div>
            <div className='fields -crm'>
                <TextField
                    className='contact_id'
                    data-validators="isRequired"
                    defaultValue={contact_id}
                    helperText='contact_id'
                    name='contact_id'
                    onChange={event => setFieldValue('contact_id', event.target.value)}
                    label="Contact ID" />
                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('contact_owner', val)}
                    options={usersZ}
                    defaultValue={contact_owner}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Contact Owner"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='contact_owner'
                        name='contact_owner'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
                <TextField 
                    className='field' 
                    disabled
                    defaultValue={code}
                    helperText='code'
                    name='code'
                    label="Code" />
                <TextField 
                    className='field' 
                    required 
                    defaultValue={lead_id}
                    helperText='lead_id'
                    name='lead_id'
                    onChange={event => setFieldValue('lead_id', event.target.value)}
                    label="Leadsource" />
            </div>
        </div>
    )
}

function DomesticoFields (props) {
    const { data, client_type } = props;

    const [data_di_rilascio_del_documento, setDataDocumento] = React.useState(data.data_di_rilascio_del_documento || null);
    const [data_di_scandenza, setDataScadenza] = React.useState(data.data_di_scandenza || null);

    const [name, setName] = React.useState(data.name);
    const [last_name, setLastName] = React.useState(data.last_name);
    const [phone] = React.useState(data.phone);
    const [cellulare] = React.useState(data.cellulare);
    const [email] = React.useState(data.email);
    const [codice_fiscale, setCodiceFiscale] = React.useState(data.codice_fiscale);
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [data_di_nascita, setDataDiNascita] = React.useState(data.data_di_nascita || null);
    const [nazione_di_nascita] = React.useState(data.nazione_di_nascita);
    const [tipo_del_documento] = React.useState(data.tipo_del_documento);
    const [luogo_di_nascita, setLuogoDiNas] = React.useState(data.luogo_di_nascita || '');
    const [ente_di_rilascio] = React.useState(data.ente_di_rilascio);
    const [comune_di_rilascio] = React.useState(data.comune_di_rilascio);
    const [sesso, setSesso] = React.useState(data.sesso || '');
    

    const [numero_documento] = React.useState(data.numero_documento);

    const [phoneError, setPhoneError] = React.useState(false);
    const [cellulareError, setCellulareError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const checkCell = (phone) => {
        if(phone.length < 2)
            return setCellulareError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setCellulareError(true)

        if(!isMobilePhone(phone))
            return setCellulareError(true)

        setCellulareError(false)
    }

    

    const changeCodice = (codice) => {
        setCodiceFiscale(codice);

        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo);

        setFieldValue('data_di_nascita', birthday);
        setFieldValue('luogo_di_nascita', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso', status);

    }

    const { setFieldValue, setFieldValueState } = props;

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Intestatario Info</div>
            <div className='fields'>
                <TextField 
                    required
                    className='field' 
                    value={name}
                    onChange={event => {setFieldValue('name', event.target.value); setName(event.target.value) }}
                    helperText='name'
                    name='name'
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={last_name}
                    onChange={event => {setFieldValue('last_name', event.target.value); setLastName(event.target.value)}} 
                    helperText='last_name'
                    name='last_name'
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone}
                        error={true}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            defaultValue: phone
                        }}
                        onChange={value => {
                            setFieldValue('phone', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone</div>
                </div>
                

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone}
                    helperText='phone'
                    name='phone'
                    onChange={value => setFieldValue('phone', value)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='number'
                        /> */}

                <div className={ cellulareError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={cellulare}
                        inputProps={{
                            name: 'cellulare',
                            defaultValue: cellulare
                        }}
                        onChange={value => {
                            setFieldValue('cellulare', value);
                            checkCell(value);
                        }} 
                    />
                    <div className='helper_text'>cellulare</div>
                </div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email}
                    helperText='email'
                    name='email'
                    type='email'
                    onChange={event => setFieldValue('email', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale}
                    helperText='codice_fiscale'
                    name='codice_fiscale'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale', event.target.value)
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso}
                    helperText='sesso'
                    name='sesso'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale}&cognome=${last_name}&nome=${name}&data=${momentT(data_di_nascita).format('DD/MM/YYYY')}&comune=${luogo_di_nascita}&sesso=${sesso}`; 

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_nascita}
                    helperText='data_di_nascita'
                    name='data_di_nascita'
                    onChange={(date) => { setFieldValue('data_di_nascita', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </MuiPickersUtilsProvider>

                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita}
                    helperText='luogo_di_nascita'
                    name='luogo_di_nascita'
                    onChange={event => {
                        setFieldValue('luogo_di_nascita', event.target.value);
                        setLuogoDiNas(event.target.value);
                    }} 
                    label="Luogo Di Nascita" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={nazione_di_nascita}
                    helperText='nazione_di_nascita'
                    name='nazione_di_nascita'
                    onChange={event => setFieldValue('nazione_di_nascita', event.target.value)} 
                    label="Nazione Di Nascita" 
                        />

                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Del Documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_del_documento}
                        helperText='tipo_del_documento'
                    name='tipo_del_documento'
                        onChange={(event, val) => { setFieldValue('tipo_del_documento', event.target.value) }}
                        label="Tipo Del Documento"
                        >
                        <MenuItem value='c. identita'>C. Identità</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                </FormControl> */}

                {/* <TextField 
                    className='field' 
                    defaultValue={tipo_del_documento}
                        helperText='tipo_del_documento'
                    name='tipo_del_documento'
                    onChange={event => setFieldValue('tipo_del_documento', event.target.value)} 
                    label="Tipo Del Documento" 
                        /> */}

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Del documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_del_documento}
                        helperText='tipo_del_documento'
                    name='tipo_del_documento'
                        onChange={(event, val) => { setFieldValue('tipo_del_documento', event.target.value) }}
                        label="Tipo Del documento"
                        >
                        <MenuItem value='c. identita'>C. Identità</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                    <FormHelperText>tipo_del_documento</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_documento}
                    helperText='numero_documento'
                    name='numero_documento'
                    onChange={event => setFieldValue('numero_documento', event.target.value)} 
                    label="Numero documento" 
                        />
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Rilascio Del Documento"
                    value={data_di_rilascio_del_documento}
                    helperText='data_di_rilascio_del_documento'
                    name='data_di_rilascio_del_documento'
                    helperText='data_di_rilascio_del_documento'
                    name='data_di_rilascio_del_documento'
                    onChange={(date) => { setFieldValue('data_di_rilascio_del_documento', date); setDataDocumento(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Scandenza"
                    value={data_di_scandenza}
                    helperText='data_di_scandenza'
                    name='data_di_scandenza'
                    onChange={(date) => { setFieldValue('data_di_scandenza', date); setDataScadenza(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={ente_di_rilascio}
                    helperText='ente_di_rilascio'
                    name='ente_di_rilascio'
                    onChange={event => setFieldValue('ente_di_rilascio', event.target.value)} 
                    label="Ente Di Rilascio" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={comune_di_rilascio}
                    helperText='comune_di_rilascio'
                    name='comune_di_rilascio'
                    onChange={event => setFieldValue('comune_di_rilascio', event.target.value)} 
                    label="Comune Di Rilascio" 
                        />
            </div>

            
        </div>
    )
}

function BusinessFields (props) {
    const { setFieldValue, data, setFieldValueState } = props;

    const [data_di_rilascio_del_documento, setDataRilascioDoc] = React.useState(data.data_di_rilascio_del_documento || null);
    const [data_di_scandeza, setDataDiScandeza] = React.useState(data.data_di_scandeza || null);

    const [ragione_sociale, setRagSoc] = React.useState(data.ragione_sociale);
    const [phone] = React.useState(data.phone);
    const [cellulare] = React.useState(data.cellulare);
    const [email] = React.useState(data.email);
    const [p_iva] = React.useState(data.p_iva);
    const [tipo_iva] = React.useState(data.tipo_iva);
    const [cognome_referente, setCogRef] = React.useState(data.cognome_referente);
    const [nome_referente, setNomeRef] = React.useState(data.nome_referente);
    const [codice_fiscale_referente, setCodiceFiscale] = React.useState(data.codice_fiscale_referente);
    const [numero_del_documento] = React.useState(data.numero_del_documento);
    const [tipo_del_documento] = React.useState(data.tipo_del_documento);
    const [ente_di_rilascio] = React.useState(data.ente_di_rilascio);
    const [comune_di_rilascio] = React.useState(data.comune_di_rilascio);
    

    const [forma_societaria] = React.useState(data.forma_societaria);
    const [data_nascita_referente, setDataRef] = React.useState(data.data_nascita_referente || null);
    const [luogo_nascita_referente, setNasRef] = React.useState(data.luogo_nascita_referente || '');

    const [sesso_referente, setSesso] = React.useState(data.sesso_referente || '');

    const [numero_documento] = React.useState(data.numero_documento);

    const [phoneError, setPhoneError] = React.useState(false);
    const [cellulareError, setCellulareError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const checkCell = (phone) => {
        if(phone.length < 2)
            return setCellulareError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setCellulareError(true)

        if(!isMobilePhone(phone))
            return setCellulareError(true)

        setCellulareError(false)
    }

    // Errors
    const [errorCodice, setErrorCodice] = React.useState(false);


    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);

        setErrorCodice(!result);
        console.log(result, 'changeCodice');

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataRef(birthday);
        setNasRef(lugo)

        setFieldValue('data_nascita_referente', birthday);
        setFieldValue('luogo_nascita_referente', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_referente', status);
    }

    // const setChangeDCodice = (codice) => {
    //     setCodiceFiscale(codice)
    //     setCodice(codice, setDataDiNascita, setLuogoDiNas);
    // }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Intestatario Info</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={ragione_sociale}
                    helperText='ragione_sociale'
                    name='ragione_sociale'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('ragione_sociale', value);
                        setRagSoc(value);
                    }} 
                    label="Ragione Sociale" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Forma societaria</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={forma_societaria}
                        helperText='forma_societaria'
                    name='forma_societaria'
                        onChange={(event, val) => { setFieldValue('forma_societaria', event.target.value) }}
                        label="Forma societaria"
                        >
                        <MenuItem value='società cooperativa'>Società cooperativa</MenuItem>
                        <MenuItem value='snc'>SNC</MenuItem>
                        <MenuItem value='impresa individuale'>Impresa individuale</MenuItem>
                        <MenuItem value='srl'>SRL</MenuItem>
                        <MenuItem value='sapa'>SAPA</MenuItem>
                        <MenuItem value='sas'>SAS</MenuItem>
                        <MenuItem value='spa'>SPA</MenuItem>
                        <MenuItem value='srls'>SRLS</MenuItem>
                        <MenuItem value='ss'>SS</MenuItem>
                        <MenuItem value='ssrl'>SSRL</MenuItem>
                        <MenuItem value='associazione'>Associazione</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='fields'>
                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            defaultValue: phone
                        }}
                        onChange={value => {
                            setFieldValue('phone', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone</div>
                </div>
                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone}
                    helperText='phone'
                    name='phone'
                    onChange={value => setFieldValue('phone', value)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}

                <div className={ cellulareError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={cellulare}
                        inputProps={{
                            name: 'cellulare',
                            defaultValue: cellulare
                        }}
                        onChange={value => {
                            setFieldValue('cellulare', value);
                            checkCell(value);
                        }} 
                    />
                    <div className='helper_text'>cellulare</div>
                </div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={email}
                    helperText='email'
                    name='email'
                    type='email'
                    onChange={event => setFieldValue('email', event.target.value)} 
                    label="Email"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={p_iva}
                    helperText='p_iva'
                    name='p_iva'
                    onChange={event => setFieldValue('p_iva', event.target.value)} 
                    label="P. IVA" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={tipo_iva}
                    helperText='tipo_iva'
                    name='tipo_iva'
                    onChange={event => setFieldValue('tipo_iva', event.target.value)} 
                    label="Tipo Iva" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={cognome_referente}
                    helperText='cognome_referente'
                    name='cognome_referente'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cognome_referente', value);
                        setCogRef(value);
                    }} 
                    label="Cognome Referente" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={nome_referente}
                    helperText='nome_referente'
                    name='nome_referente'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('nome_referente', value);
                        setNomeRef(value);
                    }} 
                    label="Nome Referente" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    error={errorCodice}
                    value={codice_fiscale_referente}
                    helperText='codice_fiscale_referente'
                    name='codice_fiscale_referente'
                    onChange={event => {
                        setFieldValue('codice_fiscale_referente', event.target.value)
                        // setCodiceFiscale(event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale Referente" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Nascita Referente"
                    value={data_nascita_referente}
                    helperText='data_nascita_referente'
                    name='data_nascita_referente'
                    onChange={(date) => { setFieldValue('data_nascita_referente', date); setDataRef(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>

            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_referente}&cognome=${cognome_referente}&nome=${nome_referente}&data=${momentT(data_nascita_referente).format('DD/MM/YYYY')}&comune=${luogo_nascita_referente}&sesso=${sesso_referente}`; 

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');
                }}>Fiscal Code Check</div>
            </div>

            <div className='fields'>
                <TextField 
                    className='field' 
                    value={luogo_nascita_referente}
                    helperText='luogo_nascita_referente'
                    name='luogo_nascita_referente'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('luogo_nascita_referente', event.target.value)
                        setNasRef(value);
                    }} 
                    label="Luogo nascita referente" 
                        />

                <TextField 
                        required 
                        className='field' 
                        defaultValue={numero_del_documento}
                        helperText='numero_del_documento'
                    name='numero_del_documento'
                        onChange={event => setFieldValue('numero_del_documento', event.target.value)} 
                        label="Numero Del Documento" 
                            />
            </div>
            <div className='fields'>
                {/* <TextField 
                    className='field'
                    defaultValue={tipo_del_documento}
                        helperText='tipo_del_documento'
                    name='tipo_del_documento'
                    onChange={event => setFieldValue('tipo_del_documento', event.target.value)} 
                    label="Tipo Del Documento" 
                        /> */}

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Del documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_del_documento}
                        helperText='tipo_del_documento'
                    name='tipo_del_documento'
                        onChange={(event, val) => { setFieldValue('tipo_del_documento', event.target.value) }}
                        label="Tipo Del documento"
                        >
                        <MenuItem value='c. identita'>C. Identità</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                </FormControl>

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Rilascio Del Documento"
                    value={data_di_rilascio_del_documento}
                    helperText='data_di_rilascio_del_documento'
                    name='data_di_rilascio_del_documento'
                    onChange={(date) => { setFieldValue('data_di_rilascio_del_documento', date); setDataRilascioDoc(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            {/* <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_documento}
                    helperText='numero_documento'
                    name='numero_documento'
                    onChange={event => setFieldValue('numero_documento', event.target.value)} 
                    label="Numero documento" 
                        />
            </div> */}
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Scandeza"
                    value={data_di_scandeza}
                    helperText='data_di_scandeza'
                    name='data_di_scandeza'
                    onChange={(date) => { setFieldValue('data_di_scandeza', date); setDataDiScandeza(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={ente_di_rilascio}
                    helperText='ente_di_rilascio'
                    name='ente_di_rilascio'
                    onChange={event => setFieldValue('ente_di_rilascio', event.target.value)} 
                    label="Ente Di Rilasco" 
                        />
                
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={comune_di_rilascio}
                    helperText='comune_di_rilascio'
                    name='comune_di_rilascio'
                    onChange={event => setFieldValue('comune_di_rilascio', event.target.value)} 
                    label="Comune Di Rilasco" 
                        />
            </div>

            
        </div>
    )
}

function PuntoDiFornituraFields (props) {
    const { setFieldValue, data } = props;

    const [tipo_indirizzo_punto, setInd] = React.useState(data.tipo_indirizzo_punto || '');
    const [civico_punto, setCiv] = React.useState(data.civico_punto || '');
    const [cap_punto, setCap] = React.useState(data.cap_punto || '');
    const [localita_punto, setLocal] = React.useState(data.localita_punto || '');
    const [provincia_punto, setProv] = React.useState(data.provincia_punto || '');

    const [address, setAddressObj] = React.useState('');

    const [invalidAddress, setInvalidAddress] = React.useState('no checked');

    const setAddressAction = () => {
        const adr = address;

        setInd(adr.street);
        setCiv(adr.streetNumber);
        setCap(adr.postCode);
        setLocal(adr.city);
        setProv(adr.district);

        setFieldValue('tipo_indirizzo_punto', adr.street)
        setFieldValue('civico_punto', adr.streetNumber)
        setFieldValue('cap_punto', adr.postCode)
        setFieldValue('localita_punto', adr.city)
        setFieldValue('provincia_punto', adr.district)
    }

    const setAddress = (result, result2) => {
        setAddressObj(result);

        const {
            city,
            district,
            state
        } = result;

        if(city && district && state) {
            const payload = {
                city,
                state: district,
                regione: state
            };

            service.verifyAddress(payload).then(resp => {
                const { ok, exist } = resp.data;

                exist ? setInvalidAddress('valid') : setInvalidAddress('invalid');
            })
        } else {
            setInvalidAddress('invalid')
        }
    }

    return (
        <div className='section'>
            <div className='head_text' id='punto_di_fornitura_part'>Punto Di Fornitura</div>
            { invalidAddress === 'invalid' && <div className='top_notification -invalid'>Controlla che non sia una frazione o un errore di ortografia</div> }
            <div className='fields'>
                <LocationSearch name='Indirizzo Di Prevelio' nameField='google_punto' show={true} setAddress={setAddress}/>  
                <div className='action' onClick={setAddressAction}>Set Address</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    // defaultValue={tipo_indirizzo}
                    value={tipo_indirizzo_punto}
                    helperText='tipo_indirizzo_punto'
                    name='tipo_indirizzo_punto'
                    onChange={event => {
                        setFieldValue('tipo_indirizzo_punto', event.target.value)
                        setInd(event.target.value)
                    }} 
                    label="Tipo Indirizzo" 
                        />

                <TextField 
                    required 
                    className='field' 
                    // defaultValue={civico}
                    value={civico_punto}
                    helperText='civico_punto'
                    name='civico_punto'
                    onChange={event => {
                        setFieldValue('civico_punto', event.target.value)
                        setCiv(event.target.value)
                    }} 
                    label="Civico" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    // defaultValue={cap}
                    value={cap_punto}
                    helperText='cap_punto'
                    name='cap_punto'
                    onChange={event => {
                        setFieldValue('cap_punto', event.target.value)
                        setCap(event.target.value)
                    }} 
                    label="Cap" 
                        />

                <TextField 
                    required 
                    className='field'
                    // defaultValue={localita}
                    value={localita_punto}
                    helperText='localita_punto'
                    name='localita_punto'
                    onChange={event => {
                        setFieldValue('localita_punto', event.target.value)
                        setLocal(event.target.value)
                    }} 
                    label="Localita" 
                        />
            </div>
            <div className='fields'>

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_punto', val)}
                    options={cities}
                    value={provincia_punto}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_punto'
                        name='provincia_punto'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}

function SedeLegaleFields (props) {
    const { setFieldValue, data } = props;

    const [tipo_indirizzo_residenza, setIndi] = React.useState(data.tipo_indirizzo_residenza || '');
    const [indirizzo_residenza, setIndiT] = React.useState(data.indirizzo_residenza || '');
    const [civico_residenza, setCivico] = React.useState(data.civico_residenza || '');
    const [cap_residenza, setCap] = React.useState(data.cap_residenza || '');
    const [comune_residenza, setLocal] = React.useState(data.comune_residenza || '');
    const [provincia_residenza, setProv] = React.useState(data.provincia_residenza || '');

    const [addressFields, showAddressFields] = React.useState(false);

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto,
            civico_punto,
            cap_punto,
            localita_punto,
            provincia_punto
        } = props.data;

        let name = tipo_indirizzo_punto;
        let street = tipo_indirizzo_punto;
        let lastIndex = 0;
        let regexDoubleName = /(STRADA COMUNALE)|(STRADA PROVINCIALE)|(VIA PRIVATA)/g;

        if(regexDoubleName.test(name)) {
            lastIndex = name.indexOf(' ', name.indexOf(' ') +1);
            name = name.slice(0, lastIndex);

            if(street)
                street = street.slice(lastIndex);
        }else {
            lastIndex = name.indexOf(' ') +1;
            name = name.slice(0, lastIndex);

            if(street)
                street = street.slice(lastIndex);
        }

        setIndi(name);
        setIndiT(street);
        setCivico(civico_punto);
        setCap(cap_punto);
        setLocal(localita_punto);
        setProv(provincia_punto);


        setFieldValue('indirizzo_residenza', name);
        setFieldValue('tipo_indirizzo_residenza', street);
        setFieldValue('civico_residenza', civico_punto);
        setFieldValue('cap_residenza', cap_punto);
        setFieldValue('comune_residenza', localita_punto);
        setFieldValue('provincia_residenza', provincia_punto);
    }

    return (
        <div className='section'>
            <div className='head_text' id='side_legale_part'>Residenza - Sede Legale</div>

            <div className='actions'>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
            </div>

            {addressFields && <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={tipo_indirizzo_residenza}
                    helperText='tipo_indirizzo_residenza'
                    name='tipo_indirizzo_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('tipo_indirizzo_residenza', value);
                        setIndi(value);
                    }}
                    label="Tipo Indirizzo Residenza" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_residenza}
                    helperText='indirizzo_residenza'
                    name='indirizzo_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('indirizzo_residenza', value);
                        setIndiT(value);
                    }}
                    label="Indirizzo Residenza" 
                        />
            </div> }
            { addressFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_residenza}
                    helperText='civico_residenza'
                    name='civico_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('civico_residenza', value);
                        setCivico(value);
                    }}
                    label="Civico Residenza" 
                        />
                <TextField 
                    required 
                    className='field' 
                    value={cap_residenza}
                    helperText='cap_residenza'
                    name='cap_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('cap_residenza', value);
                        setCap(value);
                    }}
                    label="Cap Residenza" 
                        />
            </div> }
            
            { addressFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={comune_residenza}
                    helperText='comune_residenza'
                    name='comune_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('comune_residenza', value);
                        setLocal(value);
                    }}
                    label="Localita Residenza" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => {setFieldValue('provincia_residenza', val); setProv(val)}}
                    options={cities}
                    value={provincia_residenza}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_residenza'
                        name='provincia_residenza'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div> }
        </div>
    )
}


function IndirizzoFields (props) {
    const { setFieldValue, data } = props;

    const [showFields, setShowFirled] = React.useState(false);
    const [indirizzo, setIndirizzo] = React.useState(data.indirizzo);
    const [tipo_indirizzo_spedizione] = React.useState(data.tipo_indirizzo_spedizione);
    const [civico_spedizione] = React.useState(data.civico_spedizione);
    const [cap_spedizione] = React.useState(data.cap_spedizione);
    const [localita_spedizione] = React.useState(data.localita_spedizione);
    const [provincia_spedizione] = React.useState(data.provincia_spedizione);

    const handleShowFields = (value) => {
        setIndirizzo(value);

        if(value == 'alto')
            return setShowFirled(true);

        return setShowFirled(false);
    }

    useEffect(() => {
        handleShowFields(indirizzo)
    })

    return (
        <div className='section indirizzoDiSpedizione'>
            <div className='head_text'>Indirizzo Di Spedizione</div>

            <FormControl variant="outlined" className='field -select'>
                <InputLabel id="demo-simple-select-outlined-label">Indirizzo</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    defaultValue={indirizzo}
                    helperText='indirizzo'
                    name='indirizzo'
                    onChange={(event, val) => { setFieldValue('indirizzo', event.target.value); handleShowFields(event.target.value)}}
                    label="indirizzo"
                    >
                    <MenuItem value='fornitura'>SET THE SAME AS FORNITURA</MenuItem>
                    <MenuItem value='residenza'>SET THE NAME AS Residenza</MenuItem>
                    <MenuItem value='alto'>ALLTRO Indirizzo</MenuItem>
                </Select>
            </FormControl>
            {showFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={tipo_indirizzo_spedizione}
                    helperText='tipo_indirizzo_spedizione'
                    name='tipo_indirizzo_spedizione'
                    onChange={event => setFieldValue('tipo_indirizzo_spedizione', event.target.value)} 
                    label="Tipo Indirizzo" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={civico_spedizione}
                    helperText='civico_spedizione'
                    name='civico_spedizione'
                    onChange={event => setFieldValue('civico_spedizione', event.target.value)} 
                    label="Civico" 
                        />
            </div>}
            {showFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cap_spedizione}
                    helperText='cap_spedizione'
                    name='cap_spedizione'
                    onChange={event => setFieldValue('cap_spedizione', event.target.value)} 
                    label="Cap" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={localita_spedizione}
                    helperText='localita_spedizione'
                    name='localita_spedizione'
                    onChange={event => setFieldValue('localita_spedizione', event.target.value)} 
                    label="Localita" 
                        />
            </div>}
            {showFields &&  <div className='fields'>
                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_spedizione', val)}
                    options={cities}
                    value={provincia_spedizione}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_spedizione'
                        name='provincia_spedizione'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>}
        </div>
    )
}

// function IndirizzoFields (props) {
//     const [name, setName] = React.useState('');

//     const { setFieldValue } = props;

//     return (
//         <div className='section'>
//             <div className='head_text'>Indirizzo Di Spedizione</div>

//             <FormControl variant="outlined" className='field -select'>
//                 <InputLabel id="demo-simple-select-outlined-label">Indirizzo</InputLabel>
//                 <Select
//                     labelId="demo-simple-select-outlined-label"
//                     id="demo-simple-select-outlined"
//                     value={indirizzo}
//                     onChange={(event, val) => setFieldValue('indirizzo', event.target.value)}
//                     label="Type of client"
//                     >
//                     <MenuItem value='fornitura'>Set the same as fornitura</MenuItem>
//                     <MenuItem value='residenza'>Set the name as residenza</MenuItem>
//                     <MenuItem value='alto'>Alltro indirizzo</MenuItem>
//                 </Select>
//             </FormControl>
//             <div className='fields'>
//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('name', event.target.value)} 
//                     label="Nome" 
//                         />

//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('last_name', event.target.value)} 
//                     label="Cognome"  
//                         />
//             </div>
//             <div className='fields'>
//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('name', event.target.value)} 
//                     label="Nome" 
//                         />

//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('last_name', event.target.value)} 
//                     label="Cognome"  
//                         />
//             </div>
//             <div className='fields'>
//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('name', event.target.value)} 
//                     label="Nome" 
//                         />
//             </div>
//         </div>
//     )
// }

function MigrazioneMobileFields (props) {
    const { setFieldValue, data } = props;

    const [gestore_attuale] = React.useState(data.gestore_attuale);
    const [numero_attale] = React.useState(data.numero_attale);
    const [iccid_mobile_attale] = React.useState(data.iccid_mobile_attale);

    return (
        <div className='section'>
            <div className='head_text'>Migrazione</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={gestore_attuale}
                    helperText='gestore_attuale'
                    name='gestore_attuale'
                    onChange={event => setFieldValue('gestore_attuale', event.target.value)} 
                    label="Gestore Attuale" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_attale}
                    helperText='numero_attale'
                    name='numero_attale'
                    pattern=''
                    onChange={event => setFieldValue('numero_attale', event.target.value)} 
                    label="Numero Attuale" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={iccid_mobile_attale}
                    helperText='iccid_mobile_attale'
                    name='iccid_mobile'
                    onChange={event => setFieldValue('iccid_mobile_attale', event.target.value)} 
                    label="ICCID" 
                        />
            </div>
        </div>
    )
}

function MigrazioneLineaFields (props) {
    const { setFieldValue, data } = props;

    const [codice_migrazione_mobile] = React.useState(data.codice_migrazione_mobile);
    const [gestore_attuale_linea] = React.useState(data.gestore_attuale_linea);
    const [numero_attuale_linea] = React.useState(data.numero_attuale_linea);

    return (
        <div className='section'>
            <div className='head_text'>Migrazione</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={codice_migrazione_mobile}
                    helperText='codice_migrazione_mobile'
                    name='codice_migrazione_mobile'
                    onChange={event => setFieldValue('codice_migrazione_mobile', event.target.value)} 
                    label="Codice Migrazione" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={gestore_attuale_linea}
                    helperText='gestore_attuale_linea'
                    name='gestore_attuale_linea'
                    onChange={event => setFieldValue('gestore_attuale_linea', event.target.value)} 
                    label="Gestore Attuale" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_attuale_linea}
                    helperText='numero_attuale_linea'
                    name='numero_attuale_linea'
                    onChange={event => setFieldValue('numero_attuale_linea', event.target.value)} 
                    label="Numero Attuale" 
                        />
            </div>
        </div>
    )
}

function MigrazioneMobileLineaFields (props) {
    const { setFieldValue, data } = props;

    const [codice_migrazione] = React.useState(data.codice_migrazione);
    const [gestore_attuale_fisso] = React.useState(data.gestore_attuale_fisso);
    const [numero_attuale_fisso] = React.useState(data.numero_attuale_fisso);
    const [gestore_attuale_mobile] = React.useState(data.gestore_attuale_mobile);
    const [numero_attuale_mobile] = React.useState(data.numero_attuale_mobile);
    const [iccid_migrazione] = React.useState(data.iccid_migrazione);

    return (
        <div className='section'>
            <div className='head_text'>Migrazione</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={codice_migrazione}
                    helperText='codice_migrazione'
                    name='codice_migrazione'
                    onChange={event => setFieldValue('codice_migrazione', event.target.value)} 
                    label="Codice Migrazione" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={gestore_attuale_fisso} 
                    helperText='gestore_attuale_fisso'
                    name='gestore_attuale_fisso'
                    onChange={event => setFieldValue('gestore_attuale_fisso', event.target.value)} 
                    label="Gestore Attuale Fisso" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={numero_attuale_fisso} 
                    helperText='numero_attuale_fisso'
                    name='numero_attuale_fisso'
                    onChange={event => setFieldValue('numero_attuale_fisso', event.target.value)} 
                    label="Numero Attuale Fisso" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={gestore_attuale_mobile} 
                    helperText='gestore_attuale_mobile'
                    name='gestore_attuale_mobile'
                    onChange={event => setFieldValue('gestore_attuale_mobile', event.target.value)} 
                    label="Gestore Attuale Mobile" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={numero_attuale_mobile} 
                    helperText='numero_attuale_mobile'
                    name='numero_attuale_mobile'
                    onChange={event => setFieldValue('numero_attuale_mobile', event.target.value)} 
                    label="Numero Attuale Mobile" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={iccid_migrazione} 
                    helperText='iccid_migrazione'
                    name='iccid_migrazione'
                    onChange={event => setFieldValue('iccid_migrazione', event.target.value)} 
                    label="ICCID" 
                        />
            </div>
        </div>
    )
}

function PagamentoFields (props) {
    const { 
        setFieldValue,
        data,
        typeClient,
        typeClient: client_type 
    } = props;

    const [iban, setIban] = React.useState(data.iban || '');
    const [bic_swift, setBic] = React.useState(data.bic_swift || '');
    const [iban_cognome, setLast] = React.useState(data.iban_cognome || '');
    const [iban_nome, setName] = React.useState(data.iban_nome || '');
    const [iban_codice_fiscale, setFisc] = React.useState(data.iban_codice_fiscale || '');

    const [intestatario_iban, setIntesIban] = React.useState(data.intestatario_iban || 'azienda');
    const [iban_ragione_sociale, setIbanRagSoc] = React.useState(data.iban_ragione_sociale || '');

    const [errorIban, setError] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const checkSwift = (swift) => {
        if(swift.length < 4)
            return;

        // setErrorSwift(bic.isValid(swift));
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setIbanInfo = () => {
        if(typeClient === 'domestico') {
            const {
                name,
                last_name,
                codice_fiscale,
            } = data;
    
            setLast(last_name || '');
            setName(name || '');
            setFisc(codice_fiscale || '');
    
            setFieldValue('iban_cognome', last_name);
            setFieldValue('iban_nome', name);
            setFieldValue('iban_codice_fiscale', codice_fiscale);
        } else {
            const {
                nome_referente,
                cognome_referente,
                codice_fiscale_referente,
                ragione_sociale
            } = data;
    
            setLast(cognome_referente || '');
            setName(nome_referente || '');
            setFisc(codice_fiscale_referente || '');
            setIbanRagSoc(ragione_sociale || '');
    
            setFieldValue('iban_cognome', cognome_referente);
            setFieldValue('iban_nome', nome_referente);
            setFieldValue('iban_codice_fiscale', codice_fiscale_referente);
            setFieldValue('iban_ragione_sociale', ragione_sociale);

        }
    }

    return (
        <div className='section'>
            <div className='head_text'>Pagamento</div>
            <div className='top_actions'>
                <div className='action' onClick={setIbanInfo}>Set Nome, Cognome, Codice Fiscale as above</div>
            </div>

            {client_type === 'business' && <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Intestatario IBAN</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={intestatario_iban}
                        helperText='intestatario_iban'
                        name='intestatario_iban'
                        onChange={(event, val) => {
                            const value = event.target.value;

                            setFieldValue('intestatario_iban', value)
                            setIntesIban(value);
                        }}
                        label="Intestatario IBAN"
                        >
                        <MenuItem value='azienda'>Azienda</MenuItem>
                        <MenuItem value='rappresentante'>Rappresentante</MenuItem>
                    </Select>
                    <FormHelperText>intestatario_iban</FormHelperText>
                </FormControl>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={iban}
                    helperText='iban'
                    name='iban'
                    error={errorIban}
                    inputProps={{ pattern: "^.{1,34}$", title: "MAX: 34 digits" }}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="IBAN" 
                        />
                { iban.length > 2 && (iban[0] + iban[1]) != 'IT' ? <TextField 
                    className='field'
                    required
                    error={errorSwift}
                    value={bic_swift}
                    helperText='bic_swift'
                    name='bic_swift'
                    inputProps={{ pattern: "^.{1,11}$", title: "MAX: 11 digits" }}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('bic_swift', value);
                        setBic(value);

                        checkSwift(value);
                    }} 
                    label="BIC-Swift (banche estere)" 
                        />
                    : 

                    <TextField 
                        className='field'
                        error={errorSwift}
                        value={bic_swift}
                        helperText='bic_swift'
                        name='bic_swift'
                        inputProps={{ pattern: "^.{1,11}$", title: "MAX: 11 digits" }}
                        onChange={event => {
                            const value = event.target.value;

                            setFieldValue('bic_swift', value);
                            setBic(value);

                            checkSwift(value);
                        }} 
                        label="BIC-Swift (banche estere)" 
                            />
                    }
            </div>

            {(iban[0] == 'I'|| iban[0] == 'i' && iban[1] == 'T' || iban[1] == 't') && iban.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                <div className='icon -error'>Х</div>
                        <div className='text'> IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{iban.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}

            { client_type === 'domestico' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_nome}
                    helperText='iban_nome'
                    name='iban_nome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_nome', value);
                        setName(value);
                    }} 
                    label="IBAN Nome" 
                        />
                <TextField 
                    className='field' 
                    value={iban_cognome}
                    helperText='iban_cognome'
                    name='iban_cognome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_cognome', value);
                        setLast(value)
                    }} 
                    label="IBAN Cognome" 
                        />
            </div>}

            { client_type === 'business' && intestatario_iban === 'rappresentante' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_nome}
                    helperText='iban_nome'
                    name='iban_nome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_nome', value);
                        setName(value);
                    }} 
                    label="IBAN Nome" 
                        />
                <TextField 
                    className='field' 
                    value={iban_cognome}
                    helperText='iban_cognome'
                    name='iban_cognome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_cognome', value);
                        setLast(value)
                    }} 
                    label="IBAN Cognome" 
                        />
            </div>}

            { client_type === 'business' && intestatario_iban === 'azienda' && intestatario_iban === 'azienda' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_ragione_sociale} 
                    helperText='iban_ragione_sociale'
                    name='iban_ragione_sociale'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_ragione_sociale', value);
                        setIbanRagSoc(value);
                    }} 
                    label="IBAN Ragione Sociale"
                        />
            </div>}

            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={iban_codice_fiscale}
                    helperText='iban_codice_fiscale'
                    name='iban_codice_fiscale'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_codice_fiscale', value);
                        setFisc(value);
                    }} 
                    label="IBAN Codice Fiscale" 
                        />
            </div>
        </div>
    )
}

function DelegatoFields (props) {
    const { setFieldValue, data } = props;

    const [qualita_delegato] = React.useState(data.qualita_delegato);
    const [cognome_delegato] = React.useState(data.cognome_delegato);
    const [nome_delegato] = React.useState(data.nome_delegato);
    const [cf_delegato] = React.useState(data.cf_delegato);

    return (
        <div className='section'>
            <div className='head_text'>Delegato</div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Qualità Delegato</InputLabel>
                    <Select
                        labelId="Qualità Delegato"
                        id="demo-simple-select-outlined"
                        defaultValue={qualita_delegato}
                        helperText='qualita_delegato'
                    name='qualita_delegato'
                        onChange={(event) => {

                            setFieldValue('qualita_delegato', event.target.value);
                        }}
                        label="Qualità Delegato"
                        >
                        <MenuItem value='referente-aziendale'>Referente Aziendale</MenuItem>
                        <MenuItem value='moglie'>Moglie</MenuItem>
                        <MenuItem value='marito'>Marito</MenuItem>
                        <MenuItem value='figlio'>Figlio</MenuItem>
                        <MenuItem value='figlia'>Figlia</MenuItem>
                        <MenuItem value='genitore'>Genitore</MenuItem>
                    </Select>
                    <FormHelperText>qualita_delegato</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field'
                    defaultValue={cognome_delegato}
                    helperText='cognome_delegato'
                    name='cognome_delegato'
                    onChange={event => setFieldValue('cognome_delegato', event.target.value)} 
                    label="Cognome Delegato" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={nome_delegato}
                    helperText='nome_delegato'
                    name='nome_delegato'
                    onChange={event => setFieldValue('nome_delegato', event.target.value)} 
                    label="Nome Delegato" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={cf_delegato}
                    helperText='cf_delegato'
                    name='cf_delegato'
                    onChange={event => setFieldValue('cf_delegato', event.target.value)} 
                    label="CF Delegato" 
                        />
            </div>
        </div>
    )
}

function DescriptionFields (props) {
    const { setFieldValue, data } = props;

    const [description] = React.useState(data.description);

    return (
        <div className='section'>
            <div className='head_text'>Description</div>
            <div className='fields'>
                <TextField 
                    className='field'
                    defaultValue={description}
                    helperText='description'
                    name='description'
                    onChange={event => setFieldValue('description', event.target.value)} 
                    label="Description" 
                        />
            </div>
        </div>
    )
}

const showDomisticoIntestatario = (payload) => {
    const {
        type_contract,
        client_type,
        product
    } = payload;

    return (type_contract === 'telco' && client_type === 'domestico' && product) ? true : false;
}

const showBusinessIntestatario = (payload) => {
    const {
        type_contract,
        client_type,
        product
    } = payload;

    return (type_contract === 'telco' && client_type === 'business' && product) ? true : false;
}

const showPuntoFornitura = (payload) => {
    const {
        type_contract,
        client_type,
        product
    } = payload;

    return (type_contract === 'telco' && client_type && product) ? true : false;
}

const showResidenza = (payload) => {
    const {
        type_contract,
        client_type,
        product
    } = payload;

    const regExp = new RegExp(/^(FW|ML|LM|KN|TI)/gs)

    return (type_contract === 'telco' && product && client_type && regExp.test(product.product_name)) ? true : false;
}

const showIndirizzoFields = (payload) => {
    const {
        type_contract,
        product,
        client_type
    } = payload;

    return (type_contract === 'telco' && product && client_type) ? true : false;
}

const showMigrazioneMobileFields = (payload) => {
    const {
        type_contract,
        client_type,
        product,
        operation
    } = payload;

    return (type_contract === 'telco' && client_type && operation === 'cambio mobile' && product) ? true : false;
}

const showMigrazioneLineaFields = (payload) => {
    const {
        type_contract,
        client_type,
        operation,
        product
    } = payload;

    return (type_contract === 'telco' && client_type && operation === 'cambio linea fissa' && product) ? true : false;
}

const showMigrazioneMobileLineaFields = (payload) => {
    const {
        type_contract,
        client_type,
        operation,
        product
    } = payload;

    return (type_contract === 'telco' && client_type && operation === 'cambio modile linea fissa' && product) ? true : false;
}

const showPagamentoFields = (payload) => {
    const {
        type_contract,
        client_type,
        payment_type,
        operation,
        product
    } = payload;

    return (
            type_contract === 'telco' 
            && client_type 
            && payment_type === 'rid' 
            && operation && product) ? true : false;
}

const showDelegatoFields = (payload) => {
    const {
        type_contract,
        client_type,
        product,
        payment_type,
        operation,
        delegato
    } = payload;

    return (
        type_contract === 'telco' 
        && client_type 
        && payment_type === 'rid' 
        && operation 
        && delegato 
        && product
    ) ? true : false;
}

const showDelegatoCheckTelco = (payload) => {
    const {
        type_contract,
        client_type,
        product,
        payment_type,
        operation,
        delegato
    } = payload;

    return (
        type_contract === 'telco' 
        && client_type 
        && payment_type === 'rid' 
        && operation 
        && product
    ) ? true : false;
}

const showDescriptionFields = (payload) => {
    const {
        type_contract,
        client_type,
        payment_type,
        operation
    } = payload;

    return (
        type_contract === 'telco' 
        && client_type 
        && operation 
        && payment_type
    ) ? true : false;
}

const showTelcoOperation = (payload) => {
    const {
        type_contract,
        client_type,
        product
    } = payload;

    return (type_contract === 'telco' && client_type && product && product.product_name) ? true : false;
}

const itsTiProduct = (payload) => {
    const {
        type_contract,
        client_type,
        product
    } = payload;

    const tiProd = /^ti/gmi.test(product?.product_name);

    return (type_contract === 'telco' && client_type && product && product.product_name && tiProd) ? true : false;
}

const showTelcoPagamento = (payload) => {
    const {
        type_contract,
        client_type,
        product,
        operation
    } = payload;

    return (type_contract === 'telco' && client_type && product && product.product_name && operation) ? true : false;
}

const showTelcoMainContent = (payload) => {
    const {
        type_contract,
        client_type,
        product,
        operation,
        payment_type
    } = payload;

    return (
        type_contract === 'telco' 
        && client_type 
        && product 
        && product.product_name 
        && operation
        && payment_type
        ) ? true : false;
}

const processFields = (fields) => {
    console.log('data to save', fields);
    const {
        type_contract,
        product = {},
        contact_owner = {},
        name = '',
        last_name = '',
        operation_ee = {},
        operation_gas = {},
        contact_id,

        // for new search
        email = '',
        email_extra = '',
        phone = '',
        phone_extra = '',
        pdr = '',
        pod = '',
        p_iva = '',
        codice_fiscale = '',
        codice_fiscale_referente = '',
        remi = '',
        comune_resid_prec_intestatario = '',
        comune_extra = '',
        comune_punto = '',
        comune_pagamento = '',
        comune_residenza = '',
        comune_di_rilascio = '',
        comune_residenza_altra = '',
        comune_di_rilascio_punto = '',
        localita_punto = '',
        localita_spedizione = '',
        codice_fiscale_extra = '',
        cf = '',

        name_insurance = '',
        last_name_insurance = '',
        codice_fiscale_insurance = '',
        phone_insurance = '',
        email_insurance = ''
    } = fields;

    const payload = {
        type_contract,
        product: product.product_name,
        contact_owner: contact_owner.name,
        contact_id,
        name: name || name_insurance,
        last_name: last_name || last_name_insurance,

        // for new search
        email: email || email_extra || email_insurance,
        phone: phone || phone_extra || phone_insurance,
        pdr,
        pod,
        p_iva,
        cf: cf || codice_fiscale_insurance,
        remi,
        comune: `${comune_resid_prec_intestatario}${comune_extra}${comune_punto}${comune_pagamento}${comune_residenza}${comune_di_rilascio}${comune_residenza_altra}${comune_di_rilascio_punto}${localita_punto}${localita_spedizione}`,


        operation_luce: (operation_ee && operation_ee.name) ? operation_ee.name : '',
        operation_gas: (operation_gas && operation_gas.name) ? operation_gas.name : '',
        operation_luce_id: (operation_ee && operation_ee.id) ? operation_ee.id : null,
        operation_gas_id: (operation_gas && operation_gas.id) ? operation_gas.id : null,
        data: JSON.stringify(fields)
    };

    return payload;
}

const setCodice = (codice) => {
    if(!CodiceFiscale.check(codice))
        return false;

    let parsedCodice = new CodiceFiscale(codice);

    if(!parsedCodice)
        return false;

    const {
        birthplace: {nome, prov},
        birthday
    } = parsedCodice;

    return {
        birthday,
        lugo: nome + `(${prov})` 
    }
}

const showAddAction = (payload) => {
    const {
        payment_type
    } = payload;

    return (payment_type) ? true : false;
}



























// TELCO ADDITIONAL



function DomesticoFieldsAltra (props) {
    const { data, client_type } = props;

    const [data_di_rilascio_del_documento_altra, setDataDocumento] = React.useState(data.data_di_rilascio_del_documento || null);
    const [data_di_scandenza_altra, setDataScadenza] = React.useState(data.data_di_scandenza || null);

    const [name_altra, setName] = React.useState(data.name_altra || data.name);
    const [last_name_altra, setLastName] = React.useState(data.last_name_altra);
    const [phone_altra] = React.useState(data.phone_altra);
    const [cellulare_altra] = React.useState(data.cellulare_altra);
    const [email_altra] = React.useState(data.email_altra);
    const [codice_fiscale_altra, setCodiceFiscale] = React.useState(data.codice_fiscale_altra);
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [data_di_nascita_altra, setDataDiNascita] = React.useState(data.data_di_nascita_altra || null);
    const [nazione_di_nascita_altra] = React.useState(data.nazione_di_nascita_altra);
    const [tipo_del_documento_altra] = React.useState(data.tipo_del_documento_altra);
    const [luogo_di_nascita_altra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_altra || '');
    const [ente_di_rilascio_altra] = React.useState(data.ente_di_rilascio_altra);
    const [comune_di_rilascio_altra] = React.useState(data.comune_di_rilascio_altra);
    const [sesso_altra, setSesso] = React.useState(data.sesso_altra || '');

    const [numero_documento_altra] = React.useState(data.numero_documento_altra);

    const [phoneError, setPhoneError] = React.useState(false);
    const [cellulareError, setCellulareError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const checkCell = (phone) => {
        if(phone.length < 2)
            return setCellulareError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setCellulareError(true)

        if(!isMobilePhone(phone))
            return setCellulareError(true)

        setCellulareError(false)
    }

    const changeCodice = (codice) => {
        setCodiceFiscale(codice);

        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo);

        setFieldValue('data_di_nascita_altra', birthday);
        setFieldValue('luogo_di_nascita_altra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso', status);

    }

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Intestatario Info</div>
            <div className='fields'>
                <TextField 
                    required
                    className='field' 
                    value={name_altra}
                    onChange={event => {setFieldValue('name_altra', event.target.value); setName(event.target.value) }}
                    helperText='name_altra'
                    name='name'
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={last_name_altra}
                    onChange={event => {setFieldValue('last_name_altra', event.target.value); setLastName(event.target.value)}} 
                    helperText='last_name_altra'
                    name='last_name'
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
            <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_altra}
                        inputProps={{
                            required: true,
                            name: 'phone_altra',
                            defaultValue: phone_altra
                        }}
                        onChange={value => {
                            setFieldValue('phone_altra', value);
                            checkMob(value);
                        }}
                    />
                    <div className='helper_text'>phone_altra</div>
                </div>
                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_altra}
                    helperText='phone_altra'
                    name='phone'
                    onChange={phone => setFieldValue('phone_altra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='number'
                        /> */}

                <div className={ cellulareError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={cellulare_altra}
                        inputProps={{
                            name: 'cellulare',
                            defaultValue: cellulare_altra
                        }}
                        onChange={value => {
                            setFieldValue('cellulare_altra', value);
                            checkCell(value);
                        }} 
                    />
                    <div className='helper_text'>cellulare_altra</div>
                </div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_altra}
                    helperText='email_altra'
                    name='email'
                    type='email'
                    onChange={event => setFieldValue('email_altra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_altra}
                    helperText='codice_fiscale_altra'
                    name='codice_fiscale'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_altra', event.target.value)
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso_altra}
                    helperText='sesso_altra'
                    name='sesso'
                    onChange={event => {setFieldValue('sesso_altra', event.target.value); setSesso(event.target.value)}}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_altra}&cognome=${last_name_altra}&nome=${name_altra}&data=${momentT(data_di_nascita_altra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_altra}&sesso=${sesso_altra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_nascita_altra}
                    helperText='data_di_nascita_altra'
                    name='data_di_nascita'
                    onChange={(date) => { setFieldValue('data_di_nascita_altra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </MuiPickersUtilsProvider>

                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_altra}
                    helperText='luogo_di_nascita_altra'
                    name='luogo_di_nascita'
                    onChange={event => {
                        setFieldValue('luogo_di_nascita_altra', event.target.value);
                        setLuogoDiNas(event.target.value);
                    }} 
                    label="Luogo Di Nascita" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={nazione_di_nascita_altra}
                    helperText='nazione_di_nascita_altra'
                    name='nazione_di_nascita'
                    onChange={event => setFieldValue('nazione_di_nascita_altra', event.target.value)} 
                    label="Nazione Di Nascita" 
                        />

                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Del Documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_del_documento}
                        helperText='tipo_del_documento'
                    name='tipo_del_documento'
                        onChange={(event, val) => { setFieldValue('tipo_del_documento', event.target.value) }}
                        label="Tipo Del Documento"
                        >
                        <MenuItem value='c. identita'>C. Identità</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                </FormControl> */}

                {/* <TextField 
                    className='field' 
                    defaultValue={tipo_del_documento}
                        helperText='tipo_del_documento'
                    name='tipo_del_documento'
                    onChange={event => setFieldValue('tipo_del_documento', event.target.value)} 
                    label="Tipo Del Documento" 
                        /> */}

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Del documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_del_documento_altra}
                        helperText='tipo_del_documento_altra'
                        name='tipo_del_documento'
                        onChange={(event, val) => { setFieldValue('tipo_del_documento_altra', event.target.value) }}
                        label="Tipo Del documento"
                        >
                        <MenuItem value='c. identita'>C. Identità</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                    <FormHelperText>tipo_del_documento</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_documento_altra}
                    helperText='numero_documento_altra'
                    name='numero_documento_altra'
                    onChange={event => setFieldValue('numero_documento_altra', event.target.value)} 
                    label="Numero documento" 
                        />
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Rilascio Del Documento"
                    value={data_di_rilascio_del_documento_altra}
                    helperText='data_di_rilascio_del_documento_altra'
                    name='data_di_rilascio_del_documento'
                    onChange={(date) => { setFieldValue('data_di_rilascio_del_documento_altra', date); setDataDocumento(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Scandenza"
                    value={data_di_scandenza_altra}
                    helperText='data_di_scandenza_altra'
                    name='data_di_scandenza'
                    onChange={(date) => { setFieldValue('data_di_scandenza_altra', date); setDataScadenza(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={ente_di_rilascio_altra}
                    helperText='ente_di_rilascio_altra'
                    name='ente_di_rilascio'
                    onChange={event => setFieldValue('ente_di_rilascio_altra', event.target.value)} 
                    label="Ente Di Rilascio" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={comune_di_rilascio_altra}
                    helperText='comune_di_rilascio_altra'
                    name='comune_di_rilascio'
                    onChange={event => setFieldValue('comune_di_rilascio_altra', event.target.value)} 
                    label="Comune Di Rilascio" 
                        />
            </div>
        </div>
    )
}

function BusinessFieldsAltra (props) {
    const { setFieldValue, data } = props;

    const [data_di_rilascio_del_documento_altra, setDataRilascioDoc] = React.useState(data.data_di_rilascio_del_documento_altra || null);
    const [data_di_scandeza_altra, setDataDiScandeza] = React.useState(data.data_di_scandeza_altra || null);

    const [ragione_sociale_altra] = React.useState(data.ragione_sociale_altra);
    const [phone_altra] = React.useState(data.phone_altra);
    const [cellulare_altra] = React.useState(data.cellulare_altra);
    const [email_altra] = React.useState(data.email_altra);
    const [p_iva_altra] = React.useState(data.p_iva_altra);
    const [tipo_iva_altra] = React.useState(data.tipo_iva_altra);
    const [cognome_referente_altra] = React.useState(data.cognome_referente_altra);
    const [nome_referente_altra] = React.useState(data.nome_referente_altra);
    const [codice_fiscale_referente_altra, setCodiceFiscale] = React.useState(data.codice_fiscale_referente_altra);
    const [numero_del_documento_altra] = React.useState(data.numero_del_documento_altra);
    const [tipo_del_documento_altra] = React.useState(data.tipo_del_documento_altra);
    const [ente_di_rilascio_altra] = React.useState(data.ente_di_rilascio_altra);
    const [comune_di_rilascio_altra] = React.useState(data.comune_di_rilascio_altra);

    const [forma_societaria_altra] = React.useState(data.forma_societaria_altra);
    const [data_nascita_referente_altra, setDataRef] = React.useState(data.data_nascita_referente_altra || null);
    const [luogo_nascita_referente_altra, setNasRef] = React.useState(data.luogo_nascita_referente_altra || '');

    const [numero_documento_altra] = React.useState(data.numero_documento_altra);

    // Errors
    const [errorCodice, setErrorCodice] = React.useState(false);

    const [phoneError, setPhoneError] = React.useState(false);
    const [cellulareError, setCellulareError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const checkCell = (phone) => {
        if(phone.length < 2)
            return setCellulareError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setCellulareError(true)

        if(!isMobilePhone(phone))
            return setCellulareError(true)

        setCellulareError(false)
    }


    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);

        setErrorCodice(!result);
        console.log(result, 'changeCodice');

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataRef(birthday);
        setNasRef(lugo)

        setFieldValue('data_nascita_referente_altra', birthday);
        setFieldValue('luogo_nascita_referente_altra', lugo);
    }

    // const setChangeDCodice = (codice) => {
    //     setCodiceFiscale(codice)
    //     setCodice(codice, setDataDiNascita, setLuogoDiNas);
    // }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Intestatario Info</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={ragione_sociale_altra}
                    helperText='ragione_sociale_altra'
                    name='ragione_sociale'
                    onChange={event => setFieldValue('ragione_sociale_altra', event.target.value)} 
                    label="Ragione Sociale" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Forma societaria</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={forma_societaria_altra}
                        helperText='forma_societaria_altra'
                        name='forma_societaria'
                        onChange={(event, val) => { setFieldValue('forma_societaria_altra', event.target.value) }}
                        label="Forma societaria"
                        >
                        <MenuItem value='società cooperativa'>Società cooperativa</MenuItem>
                        <MenuItem value='snc'>SNC</MenuItem>
                        <MenuItem value='impresa individuale'>Impresa individuale</MenuItem>
                        <MenuItem value='srl'>SRL</MenuItem>
                        <MenuItem value='sapa'>SAPA</MenuItem>
                        <MenuItem value='sas'>SAS</MenuItem>
                        <MenuItem value='spa'>SPA</MenuItem>
                        <MenuItem value='srls'>SRLS</MenuItem>
                        <MenuItem value='ss'>SS</MenuItem>
                        <MenuItem value='ssrl'>SSRL</MenuItem>
                        <MenuItem value='associazione'>Associazione</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='fields'>
            <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_altra}
                        inputProps={{
                            required: true,
                            name: 'phone_altra',
                            defaultValue: phone_altra
                        }}
                        onChange={value => {
                            setFieldValue('phone', value);
                            checkMob(value);
                        }}
                    />
                    <div className='helper_text'>phone_altra</div>
                </div>
                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_altra}
                    helperText='phone_altra'
                    name='phone'
                    onChange={phone => setFieldValue('phone_altra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}

                <div className={ cellulareError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={cellulare_altra}
                        inputProps={{
                            name: 'cellulare',
                            defaultValue: cellulare_altra
                        }}
                        onChange={value => {
                            setFieldValue('cellulare_altra', value);
                            checkCell(value);
                        }} 
                    />
                    <div className='helper_text'>cellulare_altra</div>
                </div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={email_altra}
                    helperText='email_altra'
                    name='email'
                    type='email'
                    onChange={event => setFieldValue('email_altra', event.target.value)} 
                    label="Email"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={p_iva_altra}
                    helperText='p_iva_altra'
                    name='p_iva'
                    onChange={event => setFieldValue('p_iva_altra', event.target.value)} 
                    label="P. IVA" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={tipo_iva_altra}
                    helperText='tipo_iva_altra'
                    name='tipo_iva'
                    onChange={event => setFieldValue('tipo_iva_altra', event.target.value)} 
                    label="Tipo Iva" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cognome_referente_altra}
                    helperText='cognome_referente_altra'
                    name='cognome_referente'
                    onChange={event => setFieldValue('cognome_referente_altra', event.target.value)} 
                    label="Cognome Referente" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={nome_referente_altra}
                    helperText='nome_referente_altra'
                    name='nome_referente'
                    onChange={event => setFieldValue('nome_referente_altra', event.target.value)} 
                    label="Nome Referente" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    error={errorCodice}
                    value={codice_fiscale_referente_altra}
                    helperText='codice_fiscale_referente_altra'
                    name='codice_fiscale_referente'
                    onChange={event => {
                        setFieldValue('codice_fiscale_referente_altra', event.target.value)
                        // setCodiceFiscale(event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale Referente" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Nascita Referente"
                    value={data_nascita_referente_altra}
                    helperText='data_nascita_referente_altra'
                    name='data_nascita_referente'
                    onChange={(date) => { setFieldValue('data_nascita_referente_altra', date); setDataRef(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>

            <div className='fields'>
                <TextField 
                    className='field' 
                    value={luogo_nascita_referente_altra}
                    helperText='luogo_nascita_referente_altra'
                    name='luogo_nascita_referente'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('luogo_nascita_referente_altra', event.target.value)
                        setNasRef(value);
                    }} 
                    label="Luogo nascita referente" 
                        />

                <TextField 
                        required 
                        className='field' 
                        defaultValue={numero_del_documento_altra}
                        helperText='numero_del_documento_altra'
                        name='numero_del_documento'
                        onChange={event => setFieldValue('numero_del_documento_altra', event.target.value)} 
                        label="Numero Del Documento" 
                            />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${luogo_nascita_referente_altra}&cognome=${cognome_referente_altra}&nome=${nome_referente_altra}&data=${momentT(data_nascita_referente_altra).format('DD/MM/YYYY')}&comune=${luogo_nascita_referente_altra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    // window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                {/* <TextField 
                    className='field'
                    defaultValue={tipo_del_documento}
                        helperText='tipo_del_documento'
                    name='tipo_del_documento'
                    onChange={event => setFieldValue('tipo_del_documento', event.target.value)} 
                    label="Tipo Del Documento" 
                        /> */}

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Del documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_del_documento_altra}
                        helperText='tipo_del_documento_altra'
                    name='tipo_del_documento'
                        onChange={(event, val) => { setFieldValue('tipo_del_documento_altra', event.target.value) }}
                        label="Tipo Del documento"
                        >
                        <MenuItem value='c. identita'>C. Identità</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                </FormControl>

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Rilascio Del Documento"
                    value={data_di_rilascio_del_documento_altra}
                    helperText='data_di_rilascio_del_documento_altra'
                    name='data_di_rilascio_del_documento'
                    onChange={(date) => { setFieldValue('data_di_rilascio_del_documento_altra', date); setDataRilascioDoc(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>

            {/* <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_documento_altra}
                    helperText='numero_documento_altra'
                    name='numero_documento_altra'
                    onChange={event => setFieldValue('numero_documento_altra', event.target.value)} 
                    label="Numero documento" 
                        />
            </div> */}
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Scandeza"
                    value={data_di_scandeza_altra}
                    helperText='data_di_scandeza_altra'
                    name='data_di_scandeza'
                    onChange={(date) => { setFieldValue('data_di_scandeza_altra', date); setDataDiScandeza(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={ente_di_rilascio_altra}
                    helperText='ente_di_rilascio_altra'
                    name='ente_di_rilascio'
                    onChange={event => setFieldValue('ente_di_rilascio_altra', event.target.value)} 
                    label="Ente Di Rilasco" 
                        />
                
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={comune_di_rilascio_altra}
                    helperText='comune_di_rilascio_altra'
                    name='comune_di_rilascio'
                    onChange={event => setFieldValue('comune_di_rilascio_altra', event.target.value)} 
                    label="Comune Di Rilasco" 
                        />
            </div>
        </div>
    )
}

function PuntoDiFornituraFieldsAltra (props) {
    const { setFieldValue, data } = props;

    const [tipo_indirizzo_punto_altra, setInd] = React.useState(data.tipo_indirizzo_punto_altra || '');
    const [civico_punto_altra, setCiv] = React.useState(data.civico_punto_altra || '');
    const [cap_punto_altra, setCap] = React.useState(data.cap_punto_altra || '');
    const [localita_punto_altra, setLocal] = React.useState(data.localita_punto_altra || '');
    const [provincia_punto_altra, setProv] = React.useState(data.provincia_punto_altra || '');

    const [address, setAddressObj] = React.useState('');

    const [invalidAddress, setInvalidAddress] = React.useState('no checked');

    const setAddressAction = () => {
        const adr = address;

        setInd(adr.street);
        setCiv(adr.streetNumber);
        setCap(adr.postCode);
        setLocal(adr.city);
        setProv(adr.district);

        setFieldValue('tipo_indirizzo_punto_altra', adr.street)
        setFieldValue('civico_punto_altra', adr.streetNumber)
        setFieldValue('cap_punto_altra', adr.postCode)
        setFieldValue('localita_punto_altra', adr.city)
        setFieldValue('provincia_punto_altra', adr.district)
    }

    const setAddress = (result, result2) => {
        setAddressObj(result);

        const {
            city,
            district,
            state
        } = result;

        if(city && district && state) {
            const payload = {
                city,
                state: district,
                regione: state
            };

            service.verifyAddress(payload).then(resp => {
                const { ok, exist } = resp.data;

                exist ? setInvalidAddress('valid') : setInvalidAddress('invalid');
            })
        } else {
            setInvalidAddress('invalid')
        }
    }

    return (
        <div className='section'>
            <div className='head_text' id='punto_di_fornitura_part'>Punto Di Fornitura</div>
            { invalidAddress === 'invalid' && <div className='top_notification -invalid'>Controlla che non sia una frazione o un errore di ortografia</div> }
            <div className='fields'>
                <LocationSearch name='Indirizzo Di Prevelio' nameField='google_punto' show={true} setAddress={setAddress}/>  
                <div className='action' onClick={setAddressAction}>Set Address</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    // defaultValue={tipo_indirizzo}
                    value={tipo_indirizzo_punto_altra}
                    helperText='tipo_indirizzo_punto_altra'
                    name='tipo_indirizzo_punto'
                    onChange={event => {
                        setFieldValue('tipo_indirizzo_punto_altra', event.target.value)
                        setInd(event.target.value)
                    }} 
                    label="Tipo Indirizzo" 
                        />

                <TextField 
                    required 
                    className='field' 
                    // defaultValue={civico}
                    value={civico_punto_altra}
                    helperText='civico_punto_altra'
                    name='civico_punto'
                    onChange={event => {
                        setFieldValue('civico_punto_altra', event.target.value)
                        setCiv(event.target.value)
                    }} 
                    label="Civico" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    // defaultValue={cap}
                    value={cap_punto_altra}
                    helperText='cap_punto_altra'
                    name='cap_punto'
                    onChange={event => {
                        setFieldValue('cap_punto_altra', event.target.value)
                        setCap(event.target.value)
                    }} 
                    label="Cap" 
                        />

                <TextField 
                    required 
                    className='field'
                    // defaultValue={localita}
                    value={localita_punto_altra}
                    helperText='localita_punto_altra'
                    name='localita_punto'
                    onChange={event => {
                        setFieldValue('localita_punto_altra', event.target.value)
                        setLocal(event.target.value)
                    }} 
                    label="Localita" 
                        />
            </div>
            <div className='fields'>
                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => {setFieldValue('provincia_punto_altra', val); setProv(val)}}
                    options={cities}
                    value={provincia_punto_altra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_punto_altra'
                        name='provincia_punto_altra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}

function SedeLegaleFieldsAltra (props) {
    const { setFieldValue, data } = props;

    const [tipo_indirizzo_residenza_altra, setIndi] = React.useState(data.tipo_indirizzo_residenza_altra || '');
    const [indirizzo_residenza_altra, setIndiT] = React.useState(data.indirizzo_residenza_altra || '');
    const [civico_residenza_altra, setCivico] = React.useState(data.civico_residenza_altra || '');
    const [cap_residenza_altra, setCap] = React.useState(data.cap_residenza_altra || '');
    const [comune_residenza_altra, setLocal] = React.useState(data.comune_residenza_altra || '');
    const [provincia_residenza_altra, setProv] = React.useState(data.provincia_residenza_altra || '');

    const [addressFields, showAddressFields] = React.useState(false);

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto,
            civico_punto,
            cap_punto,
            localita_punto,
            provincia_punto
        } = props.data;

        let name = tipo_indirizzo_punto;
        let street = tipo_indirizzo_punto;
        let lastIndex = 0;
        let regexDoubleName = /(STRADA COMUNALE)|(STRADA PROVINCIALE)|(VIA PRIVATA)/g;

        if(regexDoubleName.test(name)) {
            lastIndex = name.indexOf(' ', name.indexOf(' ') +1);
            name = name.slice(0, lastIndex);

            if(street)
                street = street.slice(lastIndex);
        }else {
            lastIndex = name.indexOf(' ') +1;
            name = name.slice(0, lastIndex);

            if(street)
                street = street.slice(lastIndex);
        }

        setIndi(name);
        setIndiT(street);
        setCivico(civico_punto);
        setCap(cap_punto);
        setLocal(localita_punto);
        setProv(provincia_punto);


        setFieldValue('indirizzo_residenza_altra', name);
        setFieldValue('tipo_indirizzo_residenza_altra', street);
        setFieldValue('civico_residenza_altra', civico_punto);
        setFieldValue('cap_residenza_altra', cap_punto);
        setFieldValue('comune_residenza_altra', localita_punto);
        setFieldValue('provincia_residenza_altra', provincia_punto);
    }

    return (
        <div className='section'>
            <div className='head_text' id='side_legale_part'>Residenza - Sede Legale</div>

            <div className='actions'>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
            </div>

            {addressFields && <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={tipo_indirizzo_residenza_altra}
                    helperText='tipo_indirizzo_residenza_altra'
                    name='tipo_indirizzo_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('tipo_indirizzo_residenza_altra', value);
                        setIndi(value);
                    }}
                    label="Tipo Indirizzo Residenza" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_residenza_altra}
                    helperText='indirizzo_residenza_altra'
                    name='indirizzo_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('indirizzo_residenza_altra', value);
                        setIndiT(value);
                    }}
                    label="Indirizzo Residenza" 
                        />
            </div> }
            { addressFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_residenza_altra}
                    helperText='civico_residenza_altra'
                    name='civico_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('civico_residenza_altra', value);
                        setCivico(value);
                    }}
                    label="Civico Residenza" 
                        />
                <TextField 
                    required 
                    className='field' 
                    value={cap_residenza_altra}
                    helperText='cap_residenza_altra'
                    name='cap_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('cap_residenza_altra', value);
                        setCap(value);
                    }}
                    label="Cap Residenza" 
                        />
            </div> }
            
            { addressFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={comune_residenza_altra}
                    helperText='comune_residenza_altra'
                    name='comune_residenza'
                    onChange={event => {
                        const value =  event.target.value;

                        setFieldValue('comune_residenza_altra', value);
                        setLocal(value);
                    }}
                    label="Localita Residenza" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => {setFieldValue('provincia_residenza_altra', val); setProv(val)}}
                    options={cities}
                    value={provincia_residenza_altra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza Altra"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_residenza_altra'
                        name='provincia_residenza_altra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div> }
        </div>
    )
}


function IndirizzoFieldsAltra (props) {
    const { setFieldValue, data } = props;

    const [showFields, setShowFirled] = React.useState(false);

    const [indirizzo_altra, setIndirizzo] = React.useState(data.indirizzo_altra);
    const [tipo_indirizzo_spedizione_altra] = React.useState(data.tipo_indirizzo_spedizione_altra);
    const [civico_spedizione_altra] = React.useState(data.civico_spedizione_altra);
    const [cap_spedizione_altra] = React.useState(data.cap_spedizione_altra);
    const [localita_spedizione_altra] = React.useState(data.localita_spedizione_altra);
    const [provincia_spedizione_altra] = React.useState(data.provincia_spedizione_altra);

    const handleShowFields = (value) => {
        setIndirizzo(value);

        if(value == 'alto')
            return setShowFirled(true);

        return setShowFirled(false);
    }

    useEffect(() => {
        handleShowFields(indirizzo_altra)
    })

    return (
        <div className='section'>
            <div className='head_text'>Indirizzo Di Spedizione</div>

            <FormControl variant="outlined" className='field -select'>
                <InputLabel id="demo-simple-select-outlined-label">Indirizzo</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    defaultValue={indirizzo_altra}
                    helperText='indirizzo_altra'
                    name='indirizzo'
                    onChange={(event, val) => { setFieldValue('indirizzo_altra', event.target.value); handleShowFields(event.target.value)}}
                    label="indirizzo"
                    >
                    <MenuItem value='fornitura'>SET THE SAME AS FORNITURA</MenuItem>
                    <MenuItem value='residenza'>SET THE NAME AS Residenza</MenuItem>
                    <MenuItem value='alto'>ALLTRO Indirizzo</MenuItem>
                </Select>
            </FormControl>
            {showFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={tipo_indirizzo_spedizione_altra}
                    helperText='tipo_indirizzo_spedizione_altra'
                    name='tipo_indirizzo_spedizione'
                    onChange={event => setFieldValue('tipo_indirizzo_spedizione_altra', event.target.value)} 
                    label="Tipo Indirizzo" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={civico_spedizione_altra}
                    helperText='civico_spedizione_altra'
                    name='civico_spedizione'
                    onChange={event => setFieldValue('civico_spedizione_altra', event.target.value)} 
                    label="Civico" 
                        />
            </div>}
            {showFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cap_spedizione_altra}
                    helperText='cap_spedizione_altra'
                    name='cap_spedizione'
                    onChange={event => setFieldValue('cap_spedizione_altra', event.target.value)} 
                    label="Cap" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={localita_spedizione_altra}
                    helperText='localita_spedizione_altra'
                    name='localita_spedizione'
                    onChange={event => setFieldValue('localita_spedizione_altra', event.target.value)} 
                    label="Localita" 
                        />
            </div>}
            {showFields &&  <div className='fields'>
                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_spedizione_altra', val)}
                    options={cities}
                    value={provincia_spedizione_altra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_spedizione_altra'
                        name='provincia_spedizione_altra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>}
        </div>
    )
}

// function IndirizzoFields (props) {
//     const [name, setName] = React.useState('');

//     const { setFieldValue } = props;

//     return (
//         <div className='section'>
//             <div className='head_text'>Indirizzo Di Spedizione</div>

//             <FormControl variant="outlined" className='field -select'>
//                 <InputLabel id="demo-simple-select-outlined-label">Indirizzo</InputLabel>
//                 <Select
//                     labelId="demo-simple-select-outlined-label"
//                     id="demo-simple-select-outlined"
//                     value={indirizzo}
//                     onChange={(event, val) => setFieldValue('indirizzo', event.target.value)}
//                     label="Type of client"
//                     >
//                     <MenuItem value='fornitura'>Set the same as fornitura</MenuItem>
//                     <MenuItem value='residenza'>Set the name as residenza</MenuItem>
//                     <MenuItem value='alto'>Alltro indirizzo</MenuItem>
//                 </Select>
//             </FormControl>
//             <div className='fields'>
//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('name', event.target.value)} 
//                     label="Nome" 
//                         />

//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('last_name', event.target.value)} 
//                     label="Cognome"  
//                         />
//             </div>
//             <div className='fields'>
//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('name', event.target.value)} 
//                     label="Nome" 
//                         />

//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('last_name', event.target.value)} 
//                     label="Cognome"  
//                         />
//             </div>
//             <div className='fields'>
//                 <TextField 
//                     className='field' 
//                     onChange={event => setFieldValue('name', event.target.value)} 
//                     label="Nome" 
//                         />
//             </div>
//         </div>
//     )
// }

function MigrazioneMobileFieldsAltra (props) {
    const { setFieldValue, data } = props;

    const [gestore_attuale_altra] = React.useState(data.gestore_attuale_altra);
    const [numero_attale_altra] = React.useState(data.numero_attale_altra);
    const [iccid_mobile_altra] = React.useState(data.iccid_mobile_altra);

    return (
        <div className='section'>
            <div className='head_text'>Migrazione</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={gestore_attuale_altra}
                    helperText='gestore_attuale_altra'
                    name='gestore_attuale'
                    onChange={event => setFieldValue('gestore_attuale_altra', event.target.value)} 
                    label="Gestore Attuale" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_attale_altra}
                    helperText='numero_attale_altra'
                    name='numero_attale'
                    onChange={event => setFieldValue('numero_attale_altra', event.target.value)} 
                    label="Numero Attuale" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={iccid_mobile_altra}
                    helperText='iccid_mobile_altra'
                    name='iccid_mobile'
                    onChange={event => setFieldValue('iccid_mobile_altra', event.target.value)} 
                    label="ICCID" 
                        />
            </div>
        </div>
    )
}

function MigrazioneLineaFieldsAltra (props) {
    const { setFieldValue, data } = props;

    const [codice_migrazione_mobile_altra] = React.useState(data.codice_migrazione_mobile_altra);
    const [gestore_attuale_linea_altra] = React.useState(data.gestore_attuale_linea_altra);
    const [numero_attuale_linea_altra] = React.useState(data.numero_attuale_linea_altra);

    return (
        <div className='section'>
            <div className='head_text'>Migrazione</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={codice_migrazione_mobile_altra}
                    helperText='codice_migrazione_mobile_altra'
                    name='codice_migrazione_mobile'
                    onChange={event => setFieldValue('codice_migrazione_mobile_altra', event.target.value)} 
                    label="Codice Migrazione" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={gestore_attuale_linea_altra}
                    helperText='gestore_attuale_linea_altra'
                    name='gestore_attuale_linea'
                    onChange={event => setFieldValue('gestore_attuale_linea_altra', event.target.value)} 
                    label="Gestore Attuale" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_attuale_linea_altra}
                    helperText='numero_attuale_linea_altra'
                    name='numero_attuale_linea'
                    onChange={event => setFieldValue('numero_attuale_linea_altra', event.target.value)} 
                    label="Numero Attuale" 
                        />
            </div>
        </div>
    )
}

function MigrazioneMobileLineaFieldsAltra (props) {
    const { setFieldValue, data } = props;

    const [codice_migrazione_altra] = React.useState(data.codice_migrazione_altra);
    const [gestore_attuale_fisso_altra] = React.useState(data.gestore_attuale_fisso_altra);
    const [numero_attuale_fisso_altra] = React.useState(data.numero_attuale_fisso_altra);
    const [gestore_attuale_mobile_altra] = React.useState(data.gestore_attuale_mobile_altra);
    const [numero_attuale_mobile_altra] = React.useState(data.numero_attuale_mobile_altra);
    const [iccid_migrazione_altra] = React.useState(data.iccid_migrazione_altra);

    return (
        <div className='section'>
            <div className='head_text'>Migrazione</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={codice_migrazione_altra}
                    helperText='codice_migrazione_altra'
                    name='codice_migrazione'
                    onChange={event => setFieldValue('codice_migrazione_altra', event.target.value)} 
                    label="Codice Migrazione" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={gestore_attuale_fisso_altra} 
                    helperText='gestore_attuale_fisso_altra'
                    name='gestore_attuale_fisso'
                    onChange={event => setFieldValue('gestore_attuale_fisso_altra', event.target.value)} 
                    label="Gestore Attuale Fisso" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={numero_attuale_fisso_altra} 
                    helperText='numero_attuale_fisso_altra'
                    name='numero_attuale_fisso'
                    onChange={event => setFieldValue('numero_attuale_fisso_altra', event.target.value)} 
                    label="Numero Attuale Fisso" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={gestore_attuale_mobile_altra} 
                    helperText='gestore_attuale_mobile_altra'
                    name='gestore_attuale_mobile'
                    onChange={event => setFieldValue('gestore_attuale_mobile_altra', event.target.value)} 
                    label="Gestore Attuale Mobile" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={numero_attuale_mobile_altra} 
                    helperText='numero_attuale_mobile_altra'
                    name='numero_attuale_mobile'
                    onChange={event => setFieldValue('numero_attuale_mobile_altra', event.target.value)} 
                    label="Numero Attuale Mobile" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={iccid_migrazione_altra} 
                    helperText='iccid_migrazione_altra'
                    name='iccid_migrazione'
                    onChange={event => setFieldValue('iccid_migrazione_altra', event.target.value)} 
                    label="ICCID" 
                        />
            </div>
        </div>
    )
}

function PagamentoFieldsAltra (props) {
    const { 
        setFieldValue,
        data,
        typeClient,
        typeClient: client_type 
    } = props;

    const [iban_altra, setIban] = React.useState(data.iban_altra || '');
    const [bic_swift_altra, setBic] = React.useState(data.bic_swift_altra || '');
    const [iban_cognome_altra, setLast] = React.useState(data.iban_cognome_altra || '');
    const [iban_nome_altra, setName] = React.useState(data.iban_nome_altra || '');
    const [iban_codice_fiscale_altra, setFisc] = React.useState(data.iban_codice_fiscale_altra || '');

    const [intestatario_iban_altra, setIntesIban] = React.useState(data.intestatario_iban_altra || 'azienda');
    const [iban_ragione_sociale_altra, setIbanRagSoc] = React.useState(data.iban_ragione_sociale_altra || '');

    const [errorIban, setError] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setIbanInfo = () => {
        if(typeClient === 'domestico') {
            const {
                name_altra,
                last_name_altra,
                codice_fiscale_altra,
            } = data;
    
            setLast(last_name_altra);
            setName(name_altra);
            setFisc(codice_fiscale_altra);
    
            setFieldValue('iban_cognome_altra', last_name_altra);
            setFieldValue('iban_nome_altra', name_altra);
            setFieldValue('iban_codice_fiscale_altra', codice_fiscale_altra);
        } else {
            const {
                nome_referente_altra,
                cognome_referente_altra,
                codice_fiscale_referente_altra,
                ragione_sociale_altra
            } = data;
    
            setLast(cognome_referente_altra);
            setName(nome_referente_altra);
            setFisc(codice_fiscale_referente_altra);
            setIbanRagSoc(ragione_sociale_altra);
    
            setFieldValue('iban_cognome_altra', cognome_referente_altra);
            setFieldValue('iban_nome_altra', nome_referente_altra);
            setFieldValue('iban_codice_fiscale_altra', codice_fiscale_referente_altra);
            setFieldValue('iban_ragione_sociale_altra', ragione_sociale_altra);
        }
    }

    return (
        <div className='section'>
            <div className='head_text'>Pagamento</div>
            <div className='top_actions'>
                <div className='action' onClick={setIbanInfo}>Set Nome, Cognome, Codice Fiscale as above</div>
            </div>

            {client_type === 'business' && <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Intestatario IBAN</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={intestatario_iban_altra}
                        helperText='intestatario_iban_altra'
                        name='intestatario_iban'
                        onChange={(event, val) => {
                            const value = event.target.value;

                            setFieldValue('intestatario_iban_altra', value)
                            setIntesIban(value);
                        }}
                        label="Intestatario IBAN"
                        >
                        <MenuItem value='azienda'>Azienda</MenuItem>
                        <MenuItem value='rappresentante'>Rappresentante</MenuItem>
                    </Select>
                    <FormHelperText>intestatario_iban</FormHelperText>
                </FormControl>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={iban_altra}
                    helperText='iban_altra'
                    name='iban'
                    error={errorIban}
                    inputProps={{ pattern: "^.{1,34}$", title: "MAX: 34 digits" }}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_altra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="IBAN" 
                        />
                <TextField 
                    className='field' 
                    error={errorSwift}
                    value={bic_swift_altra}
                    helperText='bic_swift_altra'
                    name='bic_swift'
                    inputProps={{ pattern: "^.{1,11}$", title: "MAX: 11 digits" }}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('bic_swift_altra', value);
                        setBic(value);

                        setErrorSwift(!bic.isValid(value))
                    }} 
                    label="BIC-Swift (banche estere)" 
                        />
            </div>

            {(iban_altra[0] == 'I'|| iban_altra[0] == 'i' && iban_altra[1] == 'T' || iban_altra[1] == 't') && iban_altra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{bic_swift_altra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}

            { client_type === 'domestico' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_nome_altra}
                    helperText='iban_nome_altra'
                    name='iban_nome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_nome_altra', value);
                        setName(value);
                    }} 
                    label="IBAN Nome" 
                        />
                <TextField 
                    className='field' 
                    value={iban_cognome_altra}
                    helperText='iban_cognome_altra'
                    name='iban_cognome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_cognome_altra', value);
                        setLast(value)
                    }} 
                    label="IBAN Cognome" 
                        />
            </div>}

            { client_type === 'business' && intestatario_iban_altra === 'rappresentante' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_nome_altra}
                    helperText='iban_nome_altra'
                    name='iban_nome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_nome_altra', value);
                        setName(value);
                    }} 
                    label="IBAN Nome" 
                        />
                <TextField 
                    className='field' 
                    value={iban_cognome_altra}
                    helperText='iban_cognome_altra'
                    name='iban_cognome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_cognome_altra', value);
                        setLast(value)
                    }} 
                    label="IBAN Cognome" 
                        />
            </div>}

            { client_type === 'business' && intestatario_iban_altra === 'azienda' && intestatario_iban_altra === 'azienda' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_ragione_sociale_altra} 
                    helperText='iban_ragione_sociale_altra'
                    name='iban_ragione_sociale'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_ragione_sociale_altra', value);
                        setIbanRagSoc(value);
                    }} 
                    label="IBAN Ragione Sociale"
                        />
            </div>}

            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={iban_codice_fiscale_altra}
                    helperText='iban_codice_fiscale_altra'
                    name='iban_codice_fiscale'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_codice_fiscale_altra', value);
                        setFisc(value);
                    }} 
                    label="IBAN Codice Fiscale" 
                        />
            </div>
        </div>
    )
}

function DelegatoFieldsAltra (props) {
    const { setFieldValue, data } = props;

    const [qualita_delegato_altra] = React.useState(data.qualita_delegato_altra);
    const [cognome_delegato_altra] = React.useState(data.cognome_delegato_altra);
    const [nome_delegato_altra] = React.useState(data.nome_delegato_altra);
    const [cf_delegato_altra] = React.useState(data.cf_delegato_altra);

    return (
        <div className='section'>
            <div className='head_text'>Delegato</div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Qualità Delegato</InputLabel>
                    <Select
                        labelId="Qualità Delegato"
                        id="demo-simple-select-outlined"
                        defaultValue={qualita_delegato_altra}
                        helperText='qualita_delegato_altra'
                    name='qualita_delegato'
                        onChange={(event) => {

                            setFieldValue('qualita_delegato_altra', event.target.value);
                        }}
                        label="Qualità Delegato"
                        >
                        <MenuItem value='referente-aziendale'>Referente Aziendale</MenuItem>
                        <MenuItem value='moglie'>Moglie</MenuItem>
                        <MenuItem value='marito'>Marito</MenuItem>
                        <MenuItem value='figlio'>Figlio</MenuItem>
                        <MenuItem value='figlia'>Figlia</MenuItem>
                        <MenuItem value='genitore'>Genitore</MenuItem>
                    </Select>
                    <FormHelperText>qualita_delegato</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field'
                    defaultValue={cognome_delegato_altra}
                    helperText='cognome_delegato_altra'
                    name='cognome_delegato'
                    onChange={event => setFieldValue('cognome_delegato_altra', event.target.value)} 
                    label="Cognome Delegato" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={nome_delegato_altra}
                    helperText='nome_delegato_altra'
                    name='nome_delegato'
                    onChange={event => setFieldValue('nome_delegato_altra', event.target.value)} 
                    label="Nome Delegato" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={cf_delegato_altra}
                    helperText='cf_delegato_altra'
                    name='cf_delegato'
                    onChange={event => setFieldValue('cf_delegato_altra', event.target.value)} 
                    label="CF Delegato" 
                        />
            </div>
        </div>
    )
}

function DescriptionFieldsAltra (props) {
    const { setFieldValue, data } = props;

    const [description_altra] = React.useState(data.description);

    return (
        <div className='section'>
            <div className='head_text'>Description</div>
            <div className='fields'>
                <TextField 
                    className='field'
                    defaultValue={description_altra}
                    helperText='description_altra'
                    name='description'
                    onChange={event => setFieldValue('description_altra', event.target.value)} 
                    label="Description" 
                        />
            </div>
        </div>
    )
}

const showDomisticoIntestatarioAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        product_altra
    } = payload;

    return (type_contract === 'telco' && client_type_altra === 'domestico' && product_altra) ? true : false;
}

const showBusinessIntestatarioAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        product_altra
    } = payload;

    return (type_contract === 'telco' && client_type_altra === 'business' && product_altra) ? true : false;
}

const showPuntoFornituraAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        product_altra
    } = payload;

    return (type_contract === 'telco' && client_type_altra && product_altra) ? true : false;
}

const showResidenzaAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        product_altra
    } = payload;

    const regExp = new RegExp(/^(FW|ML|LM|KN|TI)/gs)

    return (type_contract === 'telco' && product_altra && client_type_altra && regExp.test(product_altra.product_name)) ? true : false;
}

const showIndirizzoFieldsAltra = (payload) => {
    const {
        type_contract,
        product_altra,
        client_type_altra
    } = payload;

    return (type_contract === 'telco' && product_altra && client_type_altra) ? true : false;
}

const showMigrazioneMobileFieldsAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        product_altra,
        operation_altra
    } = payload;

    return (type_contract === 'telco' && client_type_altra && operation_altra === 'cambio mobile' && product_altra) ? true : false;
}

const showMigrazioneLineaFieldsAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        operation_altra,
        product_altra
    } = payload;

    return (type_contract === 'telco' && client_type_altra && operation_altra === 'cambio linea fissa' && product_altra) ? true : false;
}

const showMigrazioneMobileLineaFieldsAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        operation_altra,
        product_altra
    } = payload;

    return (type_contract === 'telco' && client_type_altra && operation_altra === 'cambio modile linea fissa' && product_altra) ? true : false;
}

const showPagamentoFieldsAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        payment_type_altra,
        operation_altra,
        product_altra
    } = payload;

    return (
            type_contract === 'telco' 
            && client_type_altra 
            && payment_type_altra === 'rid' 
            && operation_altra && product_altra) ? true : false;
}

const showDelegatoFieldsAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        product_altra,
        payment_type,
        operation_altra,
        delegato
    } = payload;

    return (
        type_contract === 'telco' 
        && client_type_altra 
        && payment_type === 'rid' 
        && operation_altra 
        && delegato 
        && product_altra
    ) ? true : false;
}

const showDelegatoCheckTelcoAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        product_altra,
        payment_type,
        operation_altra,
        delegato
    } = payload;

    return (
        type_contract === 'telco' 
        && client_type_altra 
        && payment_type === 'rid' 
        && operation_altra 
        && product_altra
    ) ? true : false;
}

const showDescriptionFieldsAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        payment_type,
        operation_altra
    } = payload;

    return (
        type_contract === 'telco' 
        && client_type_altra 
        && operation_altra 
        && payment_type
    ) ? true : false;
}

const showTelcoOperationAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        product_altra
    } = payload;

    return (type_contract === 'telco' && client_type_altra && product_altra && product_altra.product_name) ? true : false;
}

const showTelcoPagamentoAltra = (payload) => {
    const {
        type_contract,
        client_type_altra,
        product_altra,
        operation_altra
    } = payload;

    console.log(type_contract,
        client_type_altra,
        product_altra,
        operation_altra, 'test');

    return (type_contract === 'telco' && client_type_altra && product_altra && product_altra.product_name && operation_altra) ? true : false;
}

const showTelcoMainContentAltra = (payload) => {
    const {
        type_contract,
        client_type,
        product_altra,
        operation_altra,
        payment_type
    } = payload;

    return (
        type_contract === 'telco' 
        && client_type 
        && product_altra 
        && product_altra.product_name 
        && operation_altra
        && payment_type
        ) ? true : false;
}

const processFieldsAltra = (fields) => {
    console.log('data to save', fields);
    const {
        type_contract,
        product = {},
        contact_owner = {},
        name = '',
        last_name = '',
        operation_ee = {},
        operation_gas = {},
        contact_id
    } = fields;

    const payload = {
        type_contract,
        product: product.product_name,
        contact_owner: contact_owner.name,
        contact_id,
        name,
        last_name,
        operation_luce: (operation_ee && operation_ee.name) ? operation_ee.name : '',
        operation_gas: (operation_gas && operation_gas.name) ? operation_gas.name : '',
        operation_luce_id: (operation_ee && operation_ee.id) ? operation_ee.id : null,
        operation_gas_id: (operation_gas && operation_gas.id) ? operation_gas.id : null,
        data: JSON.stringify(fields)
    };

    return payload;
}

const setCodiceAltra = (codice) => {
    if(!CodiceFiscale.check(codice))
        return false;

    let parsedCodice = new CodiceFiscale(codice);

    if(!parsedCodice)
        return false;

    const {
        birthplace: {nome, prov},
        birthday
    } = parsedCodice;

    return {
        birthday,
        lugo: nome + `(${prov})` 
    }
}

const showAddActionAltra = (payload) => {
    const {
        payment_type
    } = payload;

    return (payment_type) ? true : false;
}








// END TELCO ADDITIONAL













// END TELCO




//  INSURANCE


function ProductInsurance (props) {
    const { setFieldValue, setFieldValueState, data } = props;

    const {
        product_insurance
    } = data;

    return (
        <div className='section -actions -left'>
            <FormControl variant="outlined" className='field -select'>
                <InputLabel id="demo-simple-select-outlined-label">PRODOTTO</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={product_insurance}
                    helperText='prodotto'
                    name='prodotto'
                    onChange={(event, val) => { setFieldValueState('product_insurance', event.target.value); setFieldValue('product_insurance', event.target.value)}}
                    label="PRODOTTO"
                    >
                    <MenuItem value='sa rc moto'>SA RC MOTO</MenuItem>
                    <MenuItem value='sa rc auto'>SA RC AUTO</MenuItem>
                    <MenuItem value='yolo_home'>YOLO Home</MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}

const showProductInsurance = (payload) => {
    const {
        type_contract,
        sub_insurance,
    } = payload;

    return (
        (   
            type_contract === 'Assicurazione'
            || sub_insurance === 'Assicurazione'
        )
    ) ? true : false;
}



function ContactInfoInsurance (props) {
    const { setFieldValue, data, setFieldValueState } = props;

    const [name_insurance] = React.useState(data.name_insurance || '');
    const [last_name_insurance] = React.useState(data.last_name_insurance || '');
    const [codice_fiscale_insurance, setCodiceFiscale] = React.useState(data.codice_fiscale_insurance || '');
    const [phone_insurance] = React.useState(data.phone_insurance || '');
    const [email_insurance] = React.useState(data.email_insurance || '');
    const [brand, setBrand] = React.useState(data.brand || '');
    const [modello, setModello] = React.useState(data.modello || '');
    const [targa, setTarga] = React.useState(data.targa || '');
    const [compagnia_scelta, setCompagnia] = React.useState(data.compagnia_scelta || '');
    const [errorCodice, setErrorCodice] = React.useState(false);

    const [data_di_nascita, setDataDiNascita] = React.useState(data.data_di_nascita || new Date());
    const [luogo_di_nascita, setLuogoDiNas] = React.useState(data.luogo_di_nascita);
    const [sesso, setSesso] = React.useState(data.sesso);

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }


    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_insurance}
                    helperText='name_insurance'
                    name='name_insurance'
                    onChange={event => setFieldValue('name_insurance', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_insurance}
                    helperText='last_name_insurance'
                    name='last_name_insurance'
                    onChange={event => setFieldValue('last_name_insurance', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_insurance}
                    helperText='codice_fiscale_insurance'
                    name='codice_fiscale_insurance'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_insurance', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso}
                    helperText='sesso'
                    name='sesso'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_insurance}&cognome=${data.last_name_insurance}&nome=${data.name_insuranse}&data=${momentT(data_di_nascita).format('DD/MM/YYYY')}&comune=${luogo_di_nascita}&sesso=${sesso}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    //window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_nascita}
                    helperText='data_di_nascita'
                    name='data_di_nascita'
                    onChange={(date) => { setFieldValue('data_di_nascita', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </MuiPickersUtilsProvider>

                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita}
                    helperText='luogo_di_nascita'
                    name='luogo_di_nascita'
                    onChange={event => {
                        setFieldValue('luogo_di_nascita', event.target.value);
                        setLuogoDiNas(event.target.value);
                    }} 
                    label="Luogo Di Nascita" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_insurance}
                    helperText='email_insurance'
                    name='email_insurance'
                    type='email'
                    onChange={event => setFieldValue('email_insurance', event.target.value)} 
                    label="Email"  
                        />
                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_insurance}
                        inputProps={{
                            required: true,
                            name: 'phone_insurance',
                            defaultValue: phone_insurance
                        }}
                        onChange={value => {
                            setFieldValue('phone_insurance', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_insurance</div>
                </div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={brand}
                    helperText='brand'
                    name='brand'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('brand', value);
                        setBrand(value)
                    }} 
                    label="Brand" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={modello}
                    helperText='modello'
                    name='modello'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('modello', value);
                        setModello(value)
                    }} 
                    label="Modello" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={targa}
                    helperText='targa'
                    name='targa'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('targa', value);
                        setTarga(value)
                    }} 
                    label="Targa" 
                        />

            <FormControl variant="outlined" className='field -select'>
                <InputLabel id="demo-simple-select-outlined-label">Compagnia Scelta</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={compagnia_scelta}
                    helperText='compagnia_scelta'
                    name='compagnia_scelta'
                    onChange={(event, val) => { setFieldValueState('compagnia_scelta', event.target.value); setFieldValue('compagnia_scelta', event.target.value); setCompagnia(event.target.value)}}
                    label="Compagnia Scelta"
                    >
                    <MenuItem value='Genertel'>GENERTEL</MenuItem>
                    <MenuItem value='Genialclick'>Genialclick</MenuItem>
                    <MenuItem value='Prima.it'>Prima.it</MenuItem>
                    <MenuItem value='Linear'>Linear</MenuItem>
                    <MenuItem value='Quixa'>Quixa</MenuItem>
                    <MenuItem value='Allianz Direct'>Allianz Direct</MenuItem>
                    <MenuItem value='Verti'>Verti</MenuItem>
                    <MenuItem value='Zurich'>Zurich</MenuItem>
                    <MenuItem value='ConTe'>ConTe</MenuItem>
                </Select>
            </FormControl>
            </div>
        </div>
    )
}


function ContactInfoInsuranceHome (props) {
    const { setFieldValue, data, setFieldValueState } = props;

    const [name_insurance] = React.useState(data.name_insurance || '');
    const [last_name_insurance] = React.useState(data.last_name_insurance || '');
    const [phone_insurance] = React.useState(data.phone_insurance || '');
    const [email_insurance] = React.useState(data.email_insurance || '');
    const [costo_mensile] = React.useState(data.costo_mensile || '');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_insurance}
                    helperText='name_insurance'
                    name='name_insurance'
                    onChange={event => setFieldValue('name_insurance', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_insurance}
                    helperText='last_name_insurance'
                    name='last_name_insurance'
                    onChange={event => setFieldValue('last_name_insurance', event.target.value)} 
                    label="Cognome"  
                        />
            </div>

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_insurance}
                    helperText='email_insurance'
                    name='email_insurance'
                    type='email'
                    onChange={event => setFieldValue('email_insurance', event.target.value)} 
                    label="Email"  
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_insurance}
                        inputProps={{
                            required: true,
                            name: 'phone_insurance',
                            defaultValue: phone_insurance
                        }}
                        onChange={value => {
                            setFieldValue('phone_insurance', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_insurance</div>
                </div>
            </div>

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={costo_mensile}
                    helperText='costo_mensile'
                    name='costo_mensile'
                    type='text'
                    onChange={event => setFieldValue('costo_mensile', event.target.value)} 
                    label="Costo mensile"  
                        />
            </div>
        </div>
    )
}

const showContactInfoInsuranceMoto = (payload) => {
    const {
        type_contract,
        product_insurance,
        sub_insurance
    } = payload;

    return (
        (type_contract === 'Assicurazione' || sub_insurance === 'Assicurazione')
        && product_insurance
        && product_insurance !== 'yolo_home'
    ) ? true : false;
}

const showContactInfoInsuranceHome = (payload) => {
    const {
        type_contract,
        product_insurance,
        sub_insurance
    } = payload;

    return (
        (type_contract === 'Assicurazione' || sub_insurance === 'Assicurazione')
        && product_insurance === 'yolo_home'
    ) ? true : false;
}


const showSubContactInfoInsuranceMoto = (payload) => {
    const {
        type_contract,
        sub_insurance,
        sub_contract,
        product_insurance,
    } = payload;

    return (
        type_contract === 'Enegria'
        && sub_insurance === 'Assicurazione'
        && sub_contract !== 'vendite extra'
        && product_insurance
        && product_insurance !== 'yolo_home'
    ) ? true : false;
}

const showSubContactInfoInsuranceHome = (payload) => {
    const {
        type_contract,
        sub_insurance,
        sub_contract,
        product_insurance,
    } = payload;

    return (
        type_contract === 'Enegria'
        && sub_contract !== 'vendite extra'
        && sub_insurance === 'Assicurazione'
        && product_insurance === 'yolo_home'
    ) ? true : false;
}

const showSubExtraContactInfoInsuranceMoto = (payload) => {
    const {
        type_contract,
        sub_insurance,
        sub_contract,
        product_insurance,
    } = payload;

    return (
        type_contract === 'Enegria'
        && sub_insurance === 'Assicurazione'
        && sub_contract === 'vendite extra'
        && product_insurance
        && product_insurance !== 'yolo_home'
    ) ? true : false;
}

const showSubExtraContactInfoInsuranceHome = (payload) => {
    const {
        type_contract,
        sub_insurance,
        sub_contract,
        product_insurance,
    } = payload;

    return (
        type_contract === 'Enegria'
        && sub_contract == 'vendite extra'
        && sub_insurance === 'Assicurazione'
        && product_insurance === 'yolo_home'
    ) ? true : false;
}


function InsuranceDelegato(props) {
    const { setFieldValue, data } = props;

    const [qualita_delegato] = React.useState(data.qualita_delegato);
    const [cognome_delegato] = React.useState(data.cognome_delegato);
    const [nome_delegato] = React.useState(data.nome_delegato);
    const [cf_delegato] = React.useState(data.cf_delegato);
    const [tipo_di_documento_delegato] = React.useState(data.tipo_di_documento_delegato);
    const [numero_del_documento_delegato] = React.useState(data.numero_del_documento_delegato);
    const [data_di_scadenza_delegato, setDataDiNascita] = React.useState(data.data_di_scadenza_delegato);

    return (
        <div className='section'>
            <div className='head_text'>Delegato</div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Qualità Delegato</InputLabel>
                    <Select
                        labelId="Qualità Delegato"
                        id="demo-simple-select-outlined"
                        defaultValue={qualita_delegato}
                        helperText='qualita_delegato'
                    name='qualita_delegato'
                        onChange={(event) => {

                            setFieldValue('qualita_delegato', event.target.value);
                        }}
                        label="Qualità Delegato"
                        >
                        <MenuItem value='referente-aziendale'>Referente Aziendale</MenuItem>
                        <MenuItem value='moglie'>Moglie</MenuItem>
                        <MenuItem value='marito'>Marito</MenuItem>
                        <MenuItem value='figlio'>Figlio</MenuItem>
                        <MenuItem value='figlia'>Figlia</MenuItem>
                        <MenuItem value='genitore'>Genitore</MenuItem>
                    </Select>
                    <FormHelperText>qualita_delegato</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field'
                    defaultValue={cognome_delegato}
                    helperText='cognome_delegato' 
                    name='cognome_delegato' 
                    onChange={event => setFieldValue('cognome_delegato', event.target.value)} 
                    label="Cognome Delegato" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={nome_delegato}
                    helperText='nome_delegato' 
                    name='nome_delegato' 
                    onChange={event => setFieldValue('nome_delegato', event.target.value)} 
                    label="Nome Delegato" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={cf_delegato}
                    helperText='cf_delegato'
                    name='cf_delegato'
                    onChange={event => setFieldValue('cf_delegato', event.target.value)} 
                    label="CF Delegato" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Di Documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_di_documento_delegato}
                        helperText='tipo_di_documento_delegato'
                    name='tipo_di_documento_delegato'
                        onChange={(event, val) => { setFieldValue('tipo_di_documento_delegato', event.target.value) }}
                        label="Tipo Di Documento"
                        >
                        <MenuItem value='c. identita'>C. Identità</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                </FormControl>

                <TextField 
                    required 
                    className='field'
                    defaultValue={numero_del_documento_delegato}
                    helperText='numero_del_documento_delegato'
                    name='numero_del_documento_delegato'
                    onChange={event => setFieldValue('numero_del_documento_delegato', event.target.value)} 
                    label="Numero Del Documento" 
                        />
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di scadenza"
                    value={data_di_scadenza_delegato}
                    helperText='data_di_scadenza_delegato'
                    name='data_di_scadenza_delegato'
                    onChange={(date) => { setFieldValue('data_di_scadenza_delegato', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    )
}

const showInsuranceDelegato = (payload) => {
    const {
        type_contract,
        delegato
    } = payload;

    return (
        type_contract === 'Assicurazione'
        && delegato
    ) ? true : false;
}

const showDelegatoCheckboxInsurance = (payload) => {
    const {
        type_contract,
        product_insurance
    } = payload;

    return (
        type_contract === 'Assicurazione'
        && product_insurance
    ) ? true : false;
}




// END INSURANCE























// EXTRA 

function ProductExtra (props) {
    const { setFieldValue, setFieldValueState, data } = props;

    const {
        prodotto,
        indirizzo
    } = data;

    const [showFields, setShowFirled] = React.useState(indirizzo);

    const handleShowFields = (value) => {
        if(value == 'alto')
            return setShowFirled(true);

        return setShowFirled(false);
    }

    return (
        <div className='section -actions -left'>
            <FormControl variant="outlined" className='field -select'>
                <InputLabel id="demo-simple-select-outlined-label">PRODOTTO</InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={prodotto}
                    helperText='prodotto'
                    name='prodotto'
                    onChange={(event, val) => { setFieldValueState('prodotto', event.target.value); setFieldValue('prodotto', event.target.value); handleShowFields(event.target.value)}}
                    label="PRODOTTO"
                    >
                    <MenuItem value='ir_casa_h24'>IR CASA H24+</MenuItem>
                    <MenuItem value='ir_fibra'>IR Fibra</MenuItem>
                    <MenuItem value='es_fibra'>ES Fibra</MenuItem>
                    <MenuItem value='ir_casa_h24_'>IR CASA H24</MenuItem>
                    <MenuItem value='ir_manutenzione_caldaia'>IR MANUTENZIONE CALDAIA</MenuItem>
                    <MenuItem value='linkem'>LINKEM</MenuItem>
                    <MenuItem value='tado'>TADO</MenuItem>
                    <MenuItem value='ei_flexi_manutezione'>EI FLEXI MANUTENZIONE</MenuItem>
                    <MenuItem value='sg_fibra'>SG FIBRA</MenuItem>
                    <MenuItem value='sg_fibra_business'>SG Fibra Business</MenuItem>
                    <MenuItem value='melita'>EE + Fibra</MenuItem>
                    <MenuItem value='es_impianto_luce'>ES IMPIANTO LUCE</MenuItem>
                    <MenuItem value='es_impianto_gas'>ES IMPIANTO GAS</MenuItem>
                    <MenuItem value='ir_casa_h24+_(telco)'>IR Casa H24+ (TELCO)</MenuItem>
                    <MenuItem value='offset_elec'>Offset Elec</MenuItem>
                    <MenuItem value='offset_gas'>Offset Gas</MenuItem>
                    <MenuItem value='offset_household'>Offset Household</MenuItem>
                    <MenuItem value='offset_perso'>Offset Perso</MenuItem>
                    <MenuItem value='un_prospect_gpl'>UN Prospect GPL</MenuItem>
                    <MenuItem value='lq_prospect_gpl'>LQ Prospect GPL</MenuItem>
                    <MenuItem value='ir_h24_business_energia'>IR H24 BUSINESS ENERGIA</MenuItem>
                    <MenuItem value='ir_h24_business_telco'>IR H24 BUSINESS TELCO</MenuItem>

                    <MenuItem value='ei_impianto_luce'>EI IMPIANTO LUCE</MenuItem>
                    <MenuItem value='ei_impianto_gas'>EI IMPIANTO GAS</MenuItem>

                    <MenuItem value='ok_tuttofare_24'>OK TUTTOFARE 24</MenuItem>
                    
                    {/* <MenuItem value='yo_casa_sicura_silver'>YO Casa Sicura SILVER</MenuItem>
                    <MenuItem value='yo_casa_sicura_gold'>YO Casa Sicura Gold</MenuItem> */}
                    <MenuItem value='ti_tim_vision_gold'>TI Tim vision Gold</MenuItem>
                    <MenuItem value='ti_tim_vision'>TI Tim vision</MenuItem>
                    <MenuItem value='aa_life_care'>A2A LIFE CARE</MenuItem>
                    <MenuItem value='fw_protect'>FW Protect</MenuItem>

                    <MenuItem value='aa_casa_sicure_h24'>AA Casa Sicura H24</MenuItem>
                    <MenuItem value='aa_case_sicura_caldaia'>AA Casa Sicura Caldaia</MenuItem>

                    <MenuItem value='yo_casa_full'>YO Casa Full</MenuItem>
                    <MenuItem value='yo_casa_light'>YO Casa Light</MenuItem>

                </Select>
            </FormControl>
        </div>
    )
}


function ContactInfoExtraIrRasaBusiness (props) {
    const { setFieldValue, data, subContract } = props;

    const [ragione_sociale_extra] = React.useState(data.ragione_sociale_extra || '');
    const [p_iva_extra, setPIva] = React.useState(data.p_iva_extra || '');
    const [nome_referente_extra] = React.useState(data.nome_referente_extra || '');
    const [cognome_referente_extra] = React.useState(data.cognome_referente_extra || '');
    const [codice_fiscale_referente_extra, setCodiceFiscale] = React.useState(data.codice_fiscale_referente_extra || '');
    const [data_di_rilascio_extra, setDataDiNascita] = React.useState(data.data_di_rilascio_extra || null);
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [sesso_extra, setSesso] = React.useState(data.sesso_extra || '');

    const [errorIban, setError] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }


    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }

    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo)

        setFieldValue('data_di_rilascio_extra', birthday);
        setFieldValue('luogo_di_nascita_extra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }

    const setIbanFields = () => {
        const {
            iban
        } = data;

        setIban(iban);

        setFieldValue('codice_iban_extra', iban)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={ragione_sociale_extra}
                    helperText='ragione_sociale_extra'
                    name='ragione_sociale_extra'
                    onChange={event => setFieldValue('ragione_sociale_extra', event.target.value)} 
                    label="Ragione Sociale" 
                        />
            </div>
            <div className='fields'>
                <TextField
                    required 
                    className='field' 
                    value={p_iva_extra}
                    helperText='p_iva_extra'
                    name='p_iva_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('p_iva_extra', event.target.value);
                        setPIva(event.target.value);
                    }} 
                    label="P. IVA" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_referente_extra}&cognome=${data.cognome_referente_extra}&nome=${data.nome_referente_extra}&data=${momentT(data_di_rilascio_extra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_extra}&sesso=${sesso_extra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    //window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setLuogoDiNas(event.target.value);
                        setFieldValue('luogo_di_nascita_extra', event.target.value)
                    }} 
                    label="Luogo Di Nascita" 
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            required: true,
                            name: 'phone_extra',
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>
                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => {setFieldValue('provincia_extra', val); setProv(val)}}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                {/* <TextField 
                    className='field' 
                    defaultValue={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    onChange={event => setFieldValue('codice_iban_extra', event.target.value)} 
                    label="Codice IBAN" 
                        /> */}

                <TextField 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />
            </div>
            
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cognome_referente_extra}
                    helperText='cognome_referente_extra'
                    name='cognome_referente_extra'
                    onChange={event => setFieldValue('cognome_referente_extra', event.target.value)} 
                    label="Cognome Referente"  
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={nome_referente_extra}
                    helperText='nome_referente_extra'
                    name='nome_referente_extra'
                    onChange={event => setFieldValue('nome_referente_extra', event.target.value)} 
                    label="Nome Referente" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_referente_extra}
                    helperText='codice_fiscale_referente_extra'
                    name='codice_fiscale_referente_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_referente_extra', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale Referente" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={sesso_extra}
                    helperText='sesso_extra'
                    name='sesso_extra'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso_extra', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}



function ContactInfoExtraSgFibraBusiness (props) {
    const { setFieldValue, data, subContract } = props;

    const [ragione_sociale_extra] = React.useState(data.ragione_sociale_extra || '');
    const [p_iva_extra, setPIva] = React.useState(data.p_iva_extra || '');
    const [nome_referente_extra] = React.useState(data.nome_referente_extra || '');
    const [cognome_referente_extra] = React.useState(data.cognome_referente_extra || '');
    const [codice_fiscale_referente_extra, setCodiceFiscale] = React.useState(data.codice_fiscale_referente_extra || '');
    const [data_di_rilascio_extra, setDataDiNascita] = React.useState(data.data_di_rilascio_extra || null);
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [sesso_extra, setSesso] = React.useState(data.sesso_extra || '');

    const [errorIban, setError] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const { numero_del_documento } = data;

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }


    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }

    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo)

        setFieldValue('data_di_rilascio_extra', birthday);
        setFieldValue('luogo_di_nascita_extra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }

    const setIbanFields = () => {
        const {
            iban
        } = data;

        setIban(iban);

        setFieldValue('codice_iban_extra', iban)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={ragione_sociale_extra}
                    helperText='ragione_sociale_extra'
                    name='ragione_sociale_extra'
                    onChange={event => setFieldValue('ragione_sociale_extra', event.target.value)} 
                    label="Ragione Sociale" 
                        />
            </div>
            <div className='fields'>
                <TextField
                    required 
                    className='field' 
                    value={p_iva_extra}
                    helperText='p_iva_extra'
                    name='p_iva_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('p_iva_extra', event.target.value);
                        setPIva(event.target.value);
                    }} 
                    label="P. IVA" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_referente_extra}&cognome=${data.cognome_referente_extra}&nome=${data.nome_referente_extra}&data=${momentT(data_di_rilascio_extra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_extra}&sesso=${sesso_extra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    //window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setLuogoDiNas(event.target.value);
                        setFieldValue('luogo_di_nascita_extra', event.target.value)
                    }} 
                    label="Luogo Di Nascita" 
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            required: true,
                            name: 'phone_extra',
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>
                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => {setFieldValue('provincia_extra', val); setProv(val)}}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                {/* <TextField 
                    className='field' 
                    defaultValue={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    onChange={event => setFieldValue('codice_iban_extra', event.target.value)} 
                    label="Codice IBAN" 
                        /> */}

                <TextField 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />
            </div>
            
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cognome_referente_extra}
                    helperText='cognome_referente_extra'
                    name='cognome_referente_extra'
                    onChange={event => setFieldValue('cognome_referente_extra', event.target.value)} 
                    label="Cognome Referente"  
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={nome_referente_extra}
                    helperText='nome_referente_extra'
                    name='nome_referente_extra'
                    onChange={event => setFieldValue('nome_referente_extra', event.target.value)} 
                    label="Nome Referente" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_referente_extra}
                    helperText='codice_fiscale_referente_extra'
                    name='codice_fiscale_referente_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_referente_extra', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale Referente" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={sesso_extra}
                    helperText='sesso_extra'
                    name='sesso_extra'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso_extra', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>


            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_del_documento}
                    helperText='numero_del_documento'
                    name='numero_del_documento'
                    onChange={event => setFieldValue('numero_del_documento', event.target.value)} 
                    label="Numero Del Documento" 
                        />
            </div>

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}


function MigrazioneExtraFields (props) {
    const { setFieldValue, data } = props;

    const [gestore_attuale_extra] = React.useState(data.gestore_attuale);
    const [iccid_mobile_attale_extra] = React.useState(data.iccid_mobile_attale);

    return (
        <div className='section'>
            <div className='head_text'>Migrazione</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={gestore_attuale_extra}
                    helperText='gestore_attuale_extra'
                    name='gestore_attuale_extra'
                    onChange={event => setFieldValue('gestore_attuale_extra', event.target.value)} 
                    label="Gestore Attuale" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={iccid_mobile_attale_extra}
                    helperText='iccid_mobile_attale_extra'
                    name='iccid_mobile_attale_extra'
                    onChange={event => setFieldValue('iccid_mobile_attale_extra', event.target.value)} 
                    label="ICCID" 
                        />
            </div>
        </div>
    )
}

function ContactInfoExtraIrRasa (props) {
    const { setFieldValue, data, subContract, setFieldValueState } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [codice_fiscale_extra, setCodiceFiscale] = React.useState(data.codice_fiscale_extra || '');
    const [data_di_rilascio_extra, setDataDiNascita] = React.useState(data.data_di_rilascio_extra || null);
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [sesso_extra, setSesso] = React.useState(data.sesso_extra || '');
    

    const [errorIban, setError] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }


    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }

    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo)

        setFieldValue('data_di_rilascio_extra', birthday);
        setFieldValue('luogo_di_nascita_extra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }

    const setIbanFields = () => {
        const {
            iban
        } = data;

        setIban(iban);

        setFieldValue('codice_iban_extra', iban)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_extra}
                    helperText='codice_fiscale_extra'
                    name='codice_fiscale_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_extra', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso_extra}
                    helperText='sesso_extra'
                    name='sesso_extra'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso_extra', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_extra}&cognome=${data.last_name_extra}&nome=${data.name_extra}&data=${momentT(data_di_rilascio_extra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_extra}&sesso=${sesso_extra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    //window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setLuogoDiNas(event.target.value);
                        setFieldValue('luogo_di_nascita_extra', event.target.value)
                    }} 
                    label="Luogo Di Nascita" 
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            required: true,
                            name: 'phone_extra',
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>
                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => {setFieldValue('provincia_extra', val); setProv(val)}}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                {/* <TextField 
                    className='field' 
                    defaultValue={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    onChange={event => setFieldValue('codice_iban_extra', event.target.value)} 
                    label="Codice IBAN" 
                        /> */}

                <TextField 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />
            </div>

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}

            
        </div>
    )
}


function ContactInfoExtraTimVision (props) {
    const { setFieldValue, data, subContract, setFieldValueState, prodotto } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [costo_mensile_extra] = React.useState(data.costo_mensile_extra || '');

    

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            required: true,
                            name: 'phone_extra',
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>
            </div>

            { prodotto !== 'fw_protect' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={costo_mensile_extra}
                    helperText='costo_mensile_extra'
                    name='costo_mensile_extra'
                    onChange={event => setFieldValue('costo_mensile_extra', event.target.value)} 
                    label="Costo mensile" 
                        />
            </div> }
        </div>
    )
}

function ContactInfoExtraIrManutenzione (props) {
    const { setFieldValue, data, subContract, setFieldValueState } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [codice_fiscale_extra, setCodiceFiscale] = React.useState(data.codice_fiscale_extra || '');
    const [data_di_rilascio_extra, setDataDiNascita] = React.useState(data.data_di_rilascio_extra || null);
    const [madalita_di_pagamento_extra] = React.useState(data.madalita_di_pagamento_extra || '');
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [sesso_extra, setSesso] = React.useState(data.sesso_extra || '');
    

    const [errorIban, setError] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }


    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo);

        setFieldValue('data_di_rilascio_extra', birthday);
        setFieldValue('luogo_di_nascita_extra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }

    const [showField, setShowField] = React.useState((madalita_di_pagamento_extra === 'rid') ? true : false);

    const handleShowFields = (value) => {
        if(value == 'rid')
            return setShowField(true);

        return setShowField(false);
    }

    const setIbanFields = () => {
        const {
            iban
        } = data;

        setIban(iban);

        setFieldValue('codice_iban_extra', iban)
    }
    
    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_extra}
                    helperText='codice_fiscale_extra'
                    name='codice_fiscale_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_extra', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso_extra}
                    helperText='sesso_extra'
                    name='sesso_extra'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso_extra', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_extra}&cognome=${data.last_name_extra}&nome=${data.name_extra}&data=${momentT(data_di_rilascio_extra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_extra}&sesso=${sesso_extra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    // window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setLuogoDiNas(event.target.value);
                        setFieldValue('luogo_di_nascita_extra', event.target.value)
                    }} 
                    label="Luogo Di Nascita" 
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            required: true,
                            name: 'phone_extra',
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone_extra', value);
                            checkMob(value);
                        }}
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_extra', val)}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Modalità Di Pagamento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={madalita_di_pagamento_extra}
                        helperText='madalita_di_pagamento_extra'
                    name='madalita_di_pagamento_extra'
                        onChange={(event, val) => { setFieldValue('madalita_di_pagamento_extra', event.target.value); handleShowFields(event.target.value)}}
                        label="Modalità Di Pagamento"
                        >
                        <MenuItem value='rid'>Rid</MenuItem>
                    </Select>
                </FormControl>

               {showField &&  <TextField 
                    required 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />
                }
            </div>

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}

            
        </div>
    )
}


function ContactInfoExtraLinkem (props) {
    const { setFieldValue, data, subContract, setFieldValueState } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [codice_fiscale_extra, setCodiceFiscale] = React.useState(data.codice_fiscale_extra || '');
    const [data_di_rilascio_extra, setDataDiNascita] = React.useState(data.data_di_rilascio_extra || null);
    const [madalita_di_pagamento_extra] = React.useState(data.madalita_di_pagamento_extra || '');
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [modalita_di_vendita] = React.useState(data.modalita_di_vendita || '');
    const [nuova_attivazione_extra] = React.useState(data.nuova_attivazione_extra || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [sesso_extra, setSesso] = React.useState(data.sesso_extra || '');
    

    const [errorIban, setError] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }


    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo);
        
        setFieldValue('data_di_rilascio_extra', birthday);
        setFieldValue('luogo_di_nascita_extra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }

    const [showField, setShowField] = React.useState(false);

    const handleShowFields = (value) => {
        if(value == 'rid')
            return setShowField(true);

        return setShowField(false);
    }

    const setIbanFields = () => {
        const {
            iban
        } = data;

        setIban(iban);

        setFieldValue('codice_iban_extra', iban)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_extra}
                    helperText='codice_fiscale_extra'
                    name='codice_fiscale_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_extra', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso_extra}
                    helperText='sesso_extra'
                    name='sesso_extra'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso_extra', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_extra}&cognome=${data.last_name_extra}&nome=${data.name_extra}&data=${momentT(data_di_rilascio_extra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_extra}&sesso=${sesso_extra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    // window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setLuogoDiNas(event.target.value);
                        setFieldValue('luogo_di_nascita_extra', event.target.value)
                    }} 
                    label="Luogo Di Nascita" 
                        />
                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            name: 'phone_extra',
                            required: true,
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone_extra', value);
                            checkMob(value);
                        }}
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_extra', val)}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Modalità di adebitto del canone di attivazione</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={madalita_di_pagamento_extra}
                        helperText='madalita_di_pagamento_extra'
                    name='madalita_di_pagamento_extra'
                        onChange={(event, val) => { setFieldValue('madalita_di_pagamento_extra', event.target.value); handleShowFields(event.target.value)}}
                        label="Modalità di adebitto del canone di attivazione"
                        >
                        <MenuItem value='unica_soluzione'>Unica Soluzione</MenuItem>
                        <MenuItem value='pagamento_rateizzato'>Pagamento Rateizzato</MenuItem>
                    </Select>
                </FormControl>
            </div>

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}


            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Durata Rateizzazione</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={showField}
                        helperText='durata_rateizzazione_extra'
                    name='durata_rateizzazione_extra'
                        onChange={(event, val) => { setFieldValue('durata_rateizzazione_extra', event.target.value); handleShowFields(event.target.value)}}
                        label="Durata Rateizzazione"
                        >
                        <MenuItem value='24'>24 MESI</MenuItem>
                        <MenuItem value='48'>48  MESI</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Modalità Di Vendita</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={modalita_di_vendita}
                        helperText='modalita_di_vendita'
                    name='modalita_di_vendita'
                        onChange={(event, val) => { setFieldValue('modalita_di_vendita', event.target.value) }}
                        label="Modalità Di Vendita"
                        >
                        <MenuItem value='in bundle'>In Bundle</MenuItem>
                        <MenuItem value='stand alone'>Stand Alone</MenuItem>
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Nuova Attivazione</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={nuova_attivazione_extra}
                        helperText='nuova_attivazione_extra'
                    name='nuova_attivazione_extra'
                        onChange={(event, val) => { setFieldValue('nuova_attivazione_extra', event.target.value); handleShowFields(event.target.value)}}
                        label="Nuova Attivazione"
                        >
                        <MenuItem value='si'>Si</MenuItem>
                        <MenuItem value='no'>No</MenuItem>
                    </Select>
                </FormControl>
            </div>

            
        </div>
    )
}

function ContactInfoExtraTado (props) {
    const { setFieldValue, data, subContract, setFieldValueState } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [codice_fiscale_extra, setCodiceFiscale] = React.useState(data.codice_fiscale_extra || '');
    const [data_di_rilascio_extra, setDataDiNascita] = React.useState(data.data_di_rilascio_extra || null);
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [modalita_di_vendita] = React.useState(data.modalita_di_vendita || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [sesso_extra, setSesso] = React.useState(data.sesso_extra || '');
    

    const [errorIban, setError] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }


    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo);

        setFieldValue('data_di_rilascio_extra', birthday);
        setFieldValue('luogo_di_nascita_extra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }

    const setIbanFields = () => {
        const {
            iban
        } = data;

        setIban(iban);

        setFieldValue('codice_iban_extra', iban)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_extra}
                    helperText='codice_fiscale_extra'
                    name='codice_fiscale_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_extra', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso_extra}
                    helperText='sesso_extra'
                    name='sesso_extra'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso_extra', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_extra}&cognome=${data.last_name_extra}&nome=${data.name_extra}&data=${momentT(data_di_rilascio_extra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_extra}&sesso=${sesso_extra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');
                    
                    // window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setLuogoDiNas(event.target.value);
                        setFieldValue('luogo_di_nascita_extra', event.target.value)
                    }}
                    label="Luogo Di Nascita" 
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            name: 'phone_extra',
                            required: true,
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone_extra', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_extra', val)}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Modalità Di Vendita</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={modalita_di_vendita}
                        helperText='modalita_di_vendita'
                    name='modalita_di_vendita'
                        onChange={(event, val) => { setFieldValue('modalita_di_vendita', event.target.value) }}
                        label="Modalità Di Vendita"
                        >
                        <MenuItem value='in bundle'>In Bundle</MenuItem>
                        <MenuItem value='stand alone'>Stand Alone</MenuItem>
                    </Select>
                </FormControl>
            </div>

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}

            
        </div>
    )
}

function ContactInfoExtraEiFlexiManutenzione (props) {
    const { setFieldValue, data, subContract, setFieldValueState } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [codice_fiscale_extra, setCodiceFiscale] = React.useState(data.codice_fiscale_extra || '');
    const [madalita_di_pagamento_extra] = React.useState(data.madalita_di_pagamento_extra || '');
    const [data_di_rilascio_extra, setDataDiNascita] = React.useState(data.data_di_rilascio_extra || null);
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [tipo_di_documento_extra] = React.useState(data.tipo_di_documento_extra || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [numero_del_documento_extra] = React.useState(data.numero_del_documento_extra);
    const [sesso_extra, setSesso] = React.useState(data.sesso_extra || '');
    

    const [errorIban, setError] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }


    const [showField, setShowField] = React.useState(false);

    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo);

        setFieldValue('data_di_rilascio_extra', birthday);
        setFieldValue('luogo_di_nascita_extra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }

    const handleShowFields = (value) => {
        if(value == 'rid')
            return setShowField(true);

        return setShowField(false);
    }

    const setIbanFields = () => {
        const {
            iban
        } = data;

        setIban(iban);

        setFieldValue('codice_iban_extra', iban)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_extra}
                    helperText='codice_fiscale_extra'
                    name='codice_fiscale_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_extra', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso_extra}
                    helperText='sesso_extra'
                    name='sesso_extra'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso_extra', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_extra}&cognome=${data.last_name_extra}&nome=${data.name_extra}&data=${momentT(data_di_rilascio_extra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_extra}&sesso=${sesso_extra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    // window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setLuogoDiNas(event.target.value);
                        setFieldValue('luogo_di_nascita_extra', event.target.value)
                    }}
                    label="Luogo Di Nascita" 
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            name: 'phone_extra',
                            required: true,
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone_extra', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Di Documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_di_documento_extra}
                        helperText='tipo_di_documento_extra'
                    name='tipo_di_documento_extra'
                        onChange={(event, val) => { setFieldValue('tipo_di_documento_extra', event.target.value) }}
                        label="Tipo Di Documento"
                        >
                        <MenuItem value='c. identita'>C. Identità</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                    <FormHelperText>tipo_di_documento_extra</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={numero_del_documento_extra}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('numero_del_documento_extra', value);
                        setIndi(value)
                    }} 
                    label="Numero Del Documento" 
                        />
            </div>
            <div className='fields'>    
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Rialscio"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_extra', val)}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Modalità Di Pagamento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={madalita_di_pagamento_extra}
                        helperText='madalita_di_pagamento_extra'
                    name='madalita_di_pagamento_extra'
                        onChange={(event, val) => { setFieldValue('madalita_di_pagamento_extra', event.target.value); handleShowFields(event.target.value)}}
                        label="Modalità Di Pagamento"
                        >
                        <MenuItem value='bollettino'>Bollettino</MenuItem>
                        <MenuItem value='rid'>Rid</MenuItem>
                    </Select>
                </FormControl>

               {showField &&  <TextField 
                    required 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />
                }
            </div>

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}

            
        </div>
    )
}


function ContactInfoExtraSgFibra (props) {
    const { setFieldValue, data, subContract, setFieldValueState } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [codice_fiscale_extra, setCodiceFiscale] = React.useState(data.codice_fiscale_extra || '');
    const [data_di_rilascio_extra, setDataDiNascita] = React.useState(data.data_di_rilascio_extra || null);
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [madalita_di_pagamento_extra] = React.useState(data.madalita_di_pagamento_extra || '');
    const [tipo_di_documento_extra] = React.useState(data.tipo_di_documento_extra || '');
    const [nuova_attivazione_extra] = React.useState(data.nuova_attivazione_extra || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [codice_migrazione_extra] = React.useState(data.codice_migrazione_extra);
    const [paese_di_rilascia_extra] = React.useState(data.paese_di_rilascia_extra);
    const [numero_di_documento_extra] = React.useState(data.numero_di_documento_extra);
    const [showCodDi, setShowCodDi] = React.useState(data.nuova_attivazione_extra == 'si' ? false : true);
    

    const [sesso_extra, setSesso] = React.useState(data.sesso_extra || '');

    const [iban_nome_extra, setIbanNome] = React.useState(data.iban_nome_extra || '');
    const [iban_cognome_extra, setIbanCognome] = React.useState(data.iban_nome_extra || '');
    const [iban_codice_fiscale_extra, setIbanCodiceFiscale] = React.useState(data.iban_codice_fiscale_extra || '');

    const [errorIban, setError] = React.useState(false);
    const [showField, setShowField] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }


    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo);

        setFieldValue('data_di_rilascio_extra', birthday);
        setFieldValue('luogo_di_nascita_extra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }

    const setIbanFields = () => {
        const {
            iban,
            iban_nome,
            iban_cognome,
            iban_codice_fiscale
        } = data;

        setIban(iban);
        setIbanNome(iban_nome);
        setIbanCognome(iban_cognome);
        setIbanCodiceFiscale(iban_codice_fiscale);

        setFieldValue('codice_iban_extra', iban)
        setFieldValue('iban_nome_extra', iban_nome)
        setFieldValue('iban_cognome_extra', iban_cognome)
        setFieldValue('iban_codice_fiscale_extra', iban_codice_fiscale)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_extra}
                    helperText='codice_fiscale_extra'
                    name='codice_fiscale_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_extra', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso_extra}
                    helperText='sesso_extra'
                    name='sesso_extra'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso_extra', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_extra}&cognome=${data.last_name_extra}&nome=${data.name_extra}&data=${momentT(data_di_rilascio_extra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_extra}&sesso=${sesso_extra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    // window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setLuogoDiNas(event.target.value);
                        setFieldValue('luogo_di_nascita_extra', event.target.value)
                    }}
                    label="Luogo Di Nascita" 
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            name: 'phone_extra',
                            required: true,
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone_extra', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_extra', val)}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_di_documento_extra}
                    onChange={event => setFieldValue('numero_di_documento_extra', event.target.value)} 
                    label="Numero di documento" 
                        />
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Di Documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_di_documento_extra}
                        helperText='tipo_di_documento_extra'
                    name='tipo_di_documento_extra'
                        onChange={(event, val) => { setFieldValue('tipo_di_documento_extra', event.target.value) }}
                        label="Tipo Di Documento"
                        >
                        <MenuItem value='c. indetita'>C. Identità</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                    <FormHelperText>tipo_di_documento_extra</FormHelperText>
                </FormControl>
            </div>

            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Modalità Di Pagamento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={madalita_di_pagamento_extra}
                        helperText='madalita_di_pagamento_extra'
                    name='madalita_di_pagamento_extra'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('madalita_di_pagamento_extra', value);
                            
                            (value === 'rid') ? setShowField(true) : setShowField(false);

                        }}
                        label="Modalità Di Pagamento"
                        >
                        <MenuItem value='carta-di-credito'>Carta di credito</MenuItem>
                        <MenuItem value='rid'>Rid</MenuItem>
                    </Select>
                    <FormHelperText>madalita_di_pagamento_extra</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={paese_di_rilascia_extra}
                    onChange={event => setFieldValue('paese_di_rilascia_extra', event.target.value)} 
                    label="Paese di rilascio" 
                        />
            </div>

            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Nuova Attivazione</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={nuova_attivazione_extra}
                        helperText='nuova_attivazione_extra'
                    name='nuova_attivazione_extra'
                        onChange={(event, val) => { 
                            const value = event.target.value

                            setFieldValue('nuova_attivazione_extra', value);
                            setShowCodDi((value === 'si') ? false : true);
                        }}
                        label="Nuova Attivazione"
                        >
                        <MenuItem value='si'>Si</MenuItem>
                        <MenuItem value='no'>No</MenuItem>
                    </Select>
                    <FormHelperText>nuova_attivazione_extra</FormHelperText>
                </FormControl>

                { showCodDi && <TextField 
                    required 
                    className='field' 
                    defaultValue={codice_migrazione_extra}
                    onChange={event => setFieldValue('codice_migrazione_extra', event.target.value)} 
                    label="Codice Di Migrazione" 
                        />}
            </div>

            {showField && subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setIbanFields}>Set the same as residenza</div> 
            </div>}

            {showField && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_nome_extra}
                    helperText='iban_nome_extra'
                    name='iban_nome_extra'
                    onChange={event => { 
                        const value = event.target.value;
                        
                        setFieldValue('iban_nome_extra', value); 
                        setIbanNome(value)
                    }} 
                    label="Nome IBAN" 
                        />
                <TextField 
                    required 
                    className='field' 
                    value={iban_cognome_extra}
                    helperText='iban_cognome_extra'
                    name='iban_cognome_extra'
                    onChange={event => { 
                        const value = event.target.value;
                        
                        setFieldValue('iban_cognome_extra', value); 
                        setIbanCognome(value)
                    }} 
                    label="Cognome IBAN" 
                        />
            </div> }

            { showField && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_codice_fiscale_extra}
                    helperText='iban_codice_fiscale_extra'
                    name='iban_codice_fiscale_extra'
                    onChange={event => { 
                        const value = event.target.value;

                        setFieldValue('iban_codice_fiscale_extra', value); 
                        setIbanCodiceFiscale(value)
                    }} 
                    label="Codice Fiscale IBAN" 
                        />
                <TextField 
                    required 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />
            </div> }

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}

            
        </div>
    )
}

function ContactInfoExtraCo2 (props) {
    const { setFieldValue, data, subContract, setFieldValueState } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [prodotto_extra] = React.useState(data.prodotto_extra || '');
    const [salutations_extra] = React.useState(data.salutations_extra || '');
    const [consumi_del_cliente_extra] = React.useState(data.consumi_del_cliente_extra || '');
    const [bic_extra] = React.useState(data.bic_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [custom_tons_extra] = React.useState(data.custom_tons_extra);
    
    
    const [errorIban, setError] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }

    const setIbanFields = () => {
        const {
            iban
        } = data;

        setIban(iban);

        setFieldValue('codice_iban_extra', iban)
    }


    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Prodotto</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={prodotto_extra}
                        helperText='prodotto_extra'
                    name='prodotto_extra'
                        onChange={(event, val) => { setFieldValue('prodotto_extra', event.target.value)}}
                        label="Prodotto"
                        >
                        <MenuItem value='offset_elec'>Offset Elec</MenuItem>
                        <MenuItem value='offset_gas'>Offset Gas</MenuItem>
                        <MenuItem value='offset_household'>Offset Household</MenuItem>
                        <MenuItem value='offset_perso'>Offset Perso</MenuItem>
                    </Select>
                    <FormHelperText>prodotto_extra</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Salutations</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={salutations_extra}
                        helperText='salutations_extra'
                    name='salutations_extra'
                        onChange={(event, val) => { setFieldValue('salutations_extra', event.target.value)}}
                        label="Salutations"
                        >
                        <MenuItem value='mr'>Mr</MenuItem>
                        <MenuItem value='mrs'>Mrs</MenuItem>
                    </Select>
                    <FormHelperText>salutations_extra</FormHelperText>
                </FormControl>
            </div>

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            name: 'phone_extra',
                            required: true,
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone_extra', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                    /> */}
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                    />
            </div>
            <div className='fields'>
                

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />
            </div>
            <div className='fields'>
                

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />
            </div>
            <div className='fields'>
                

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_extra', val)}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />

                <TextField 
                    required 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />
            </div>

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}


            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={bic_extra}
                    inputProps={{ pattern: "^.{1,11}$", title: "MAX: 11 digits" }}
                    helperText='bic_extra'
                    name='bic_extra'
                    onChange={event => setFieldValue('bic_extra', event.target.value)} 
                    label="BIC" 
                        />
            </div>

            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Consumi Del Cliente</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={consumi_del_cliente_extra}
                        helperText='consumi_del_cliente_extra'
                    name='consumi_del_cliente_extra'
                        onChange={(event, val) => { setFieldValue('consumi_del_cliente_extra', event.target.value)}}
                        label="Consumi Del Cliente"
                        >
                        <MenuItem value='mr'>Mr</MenuItem>
                        <MenuItem value='mrs'>Mrs</MenuItem>
                    </Select>
                    <FormHelperText>consumi_del_cliente_extra</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={custom_tons_extra}
                    helperText='custom_tons_extra'
                    name='custom_tons_extra'
                    onChange={event => setFieldValue('custom_tons_extra', event.target.value)} 
                    label="Custom Tons" 
                        />
            </div>

            
        </div>
    )
}

function ContactInfoExtraMelita (props) {
    const { setFieldValue, data, subContract, setFieldValueState } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [codice_fiscale_extra, setCodiceFiscale] = React.useState(data.codice_fiscale_extra || '');
    const [madalita_di_pagamento_extra] = React.useState(data.madalita_di_pagamento_extra || '');
    const [data_di_rilascio_extra, setDataDiNascita] = React.useState(data.data_di_rilascio_extra || null);
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [indirizzo_extra, setIndi] = React.useState(data.indirizzo_extra || '');
    const [civico_extra, setCivico] = React.useState(data.civico_extra || '');
    const [cap_extra, setCap] = React.useState(data.cap_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [nuova_attivazione_extra] = React.useState(data.nuova_attivazione_extra || '');
    const [codice_iban_extra, setIban] = React.useState(data.codice_iban_extra || '');
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [sesso_extra, setSesso] = React.useState(data.sesso_extra || '');
    

    const [iban_nome_extra, setIbanNome] = React.useState(data.iban_nome_extra || '');
    const [iban_cognome_extra, setIbanCognome] = React.useState(data.iban_nome_extra || '');
    const [iban_codice_fiscale_extra, setIbanCodiceFiscale] = React.useState(data.iban_codice_fiscale_extra || '');

    const [errorIban, setError] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [addressFields, showAddressFields] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setTheSameResidenza = () => {
        const {
            indirizzo_residenza = '',
            civico_residenza = '',
            cap_residenza = '',
            comune_residenza = '',
            provincia_residenza = ''
        } = props.data;

        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('indirizzo_extra', indirizzo_residenza);
        setFieldValue('civico_extra', civico_residenza);
        setFieldValue('cap_extra', cap_residenza);
        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setIndi(tipo_indirizzo_punto + indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto || localita_punto);
        setProv(provincia_punto);

        setFieldValue('indirizzo_extra', tipo_indirizzo_punto + indirizzo_punto);
        setFieldValue('civico_extra', civico_punto);
        setFieldValue('cap_extra', cap_punto);
        setFieldValue('comune_extra', comune_punto || localita_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }


    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo);

        setFieldValue('data_di_rilascio_extra', birthday);
        setFieldValue('luogo_di_nascita_extra', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso_extra', status);
    }
    

    const [showFields, setShowFields] = React.useState(false);

    const handleShowFields = (value) => {
        if(value == 'rid')
            return setShowFields(true);

        return setShowFields(false);
    }

    const setIbanFields = () => {
        const {
            iban,
            iban_nome,
            iban_cognome,
            iban_codice_fiscale
        } = data;

        setIban(iban);
        setIbanNome(iban_nome);
        setIbanCognome(iban_cognome);
        setIbanCodiceFiscale(iban_codice_fiscale);

        setFieldValue('codice_iban_extra', iban)
        setFieldValue('iban_nome_extra', iban_nome)
        setFieldValue('iban_cognome_extra', iban_cognome)
        setFieldValue('iban_codice_fiscale_extra', iban_codice_fiscale)
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_extra}
                    helperText='codice_fiscale_extra'
                    name='codice_fiscale_extra'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_extra', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />

                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_rilascio_extra}
                    helperText='data_di_rilascio_extra'
                    name='data_di_rilascio_extra'
                    onChange={(date) => { setFieldValue('data_di_rilascio_extra', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso_extra}
                    helperText='sesso_extra'
                    name='sesso_extra'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso_extra', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_extra}&cognome=${data.last_name_extra}&nome=${data.name_extra}&data=${momentT(data_di_rilascio_extra).format('DD/MM/YYYY')}&comune=${luogo_di_nascita_extra}&sesso=${sesso_extra}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    // window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setLuogoDiNas(event.target.value);
                        setFieldValue('luogo_di_nascita_extra', event.target.value)
                    }}
                    label="Luogo Di Nascita" 
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            name: 'phone_extra',
                            required: true,
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone_extra', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_extra}
                    helperText='indirizzo_extra'
                    name='indirizzo_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('indirizzo_extra', value);
                        setIndi(value)
                    }} 
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_extra}
                    helperText='civico_extra'
                    name='civico_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('civico_extra', value);
                        setCivico(value)
                    }} 
                    label="Civico" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={cap_extra}
                    helperText='cap_extra'
                    name='cap_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('cap_extra', value);
                        setCap(value)
                    }} 
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_extra', val)}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />

            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Modalita Di Pagamento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={madalita_di_pagamento_extra}
                        helperText='madalita_di_pagamento_extra'
                    name='madalita_di_pagamento_extra'
                        onChange={(event, val) => { setFieldValue('madalita_di_pagamento_extra', event.target.value); handleShowFields(event.target.value)}}
                        label="Modalita Di Pagomento"
                        >
                        <MenuItem value='bollettino'>Bollettino</MenuItem>
                        <MenuItem value='rid'>Rid</MenuItem>
                    </Select>
                    <FormHelperText>madalita_di_pagamento_extra</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Nuova Attivazione</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={nuova_attivazione_extra}
                        helperText='nuova_attivazione_extra'
                    name='nuova_attivazione_extra'
                        onChange={(event, val) => { setFieldValue('nuova_attivazione_extra', event.target.value); handleShowFields(event.target.value)}}
                        label="Nuova Attivazione"
                        >
                        <MenuItem value='si'>Si</MenuItem>
                        <MenuItem value='no'>No</MenuItem>
                    </Select>
                    <FormHelperText>nuova_attivazione_extra</FormHelperText>
                </FormControl>
            </div>

            {showFields && subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setIbanFields}>Set the same as residenza</div> 
            </div>}

            {showFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_nome_extra}
                    helperText='iban_nome_extra'
                    name='iban_nome_extra'
                    onChange={event => { 
                        const value = event.target.value;
                        
                        setFieldValue('iban_nome_extra', value); 
                        setIbanNome(value)
                    }} 
                    label="Nome IBAN" 
                        />
                <TextField 
                    required 
                    className='field' 
                    value={iban_cognome_extra}
                    helperText='iban_cognome_extra'
                    name='iban_cognome_extra'
                    onChange={event => { 
                        const value = event.target.value;
                        
                        setFieldValue('iban_cognome_extra', value); 
                        setIbanCognome(value)
                    }} 
                    label="Cognome IBAN" 
                        />
            </div> }

            { showFields && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_codice_fiscale_extra}
                    helperText='iban_codice_fiscale_extra'
                    name='iban_codice_fiscale_extra'
                    onChange={event => { 
                        const value = event.target.value;

                        setFieldValue('iban_codice_fiscale_extra', value); 
                        setIbanCodiceFiscale(value)
                    }} 
                    label="Codice Fiscale IBAN" 
                        />
                <TextField 
                    required 
                    className='field'
                    value={codice_iban_extra}
                    helperText='codice_iban_extra'
                    name='codice_iban_extra'
                    error={errorIban}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('codice_iban_extra', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="Codice IBAN" 
                        />
            </div> }

            {(codice_iban_extra[0] == 'I'|| codice_iban_extra[0] == 'i' && codice_iban_extra[1] == 'T' || codice_iban_extra[1] == 't') && codice_iban_extra.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            { showFields && ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{codice_iban_extra.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}

            
        </div>
    )
}

function ContactInfoExtraGpl (props) {
    const { setFieldValue, data, subContract, setFieldValueState } = props;

    const [name_extra] = React.useState(data.name_extra || '');
    const [last_name_extra] = React.useState(data.last_name_extra || '');
    const [luogo_di_nascita_extra, setLuogoDiNas] = React.useState(data.luogo_di_nascita_extra || '');
    const [phone_extra] = React.useState(data.phone_extra || '');
    const [email_extra] = React.useState(data.email_extra || '');
    const [comune_extra, setComune] = React.useState(data.comune_extra || '');
    const [provincia_extra, setProv] = React.useState(data.provincia_extra || '');
    const [operazione_extra] = React.useState(data.operazione_extra || '');
    


    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const [addressFields, showAddressFields] = React.useState(false);

    const setTheSameResidenza = () => {
        const {
            comune_residenza,
            provincia_residenza
        } = props.data;

        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('comune_extra', comune_residenza);
        setFieldValue('provincia_extra', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            comune_punto,
            provincia_punto
        } = props.data;

        setComune(comune_punto);
        setProv(provincia_punto);
        
        setFieldValue('comune_extra', comune_punto );
        setFieldValue('provincia_extra', provincia_punto);
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Contract Info</div>

            { subContract === 'vendite extra' && <div className='actions'>
                <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>
                <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>
                <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>
            </div> }

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name_extra}
                    helperText='name_extra'
                    name='name_extra'
                    onChange={event => setFieldValue('name_extra', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name_extra}
                    helperText='last_name_extra'
                    name='last_name_extra'
                    onChange={event => setFieldValue('last_name_extra', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita_extra}
                    helperText='luogo_di_nascita_extra'
                    name='luogo_di_nascita_extra'
                    onChange={event => {
                        setFieldValue('luogo_di_nascita_extra', event.target.value);
                        setLuogoDiNas(event.target.value);
                    }}
                    label="Luogo Di Nascita" 
                        />

                <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone_extra}
                        inputProps={{
                            name: 'phone_extra',
                            required: true,
                            defaultValue: phone_extra
                        }}
                        onChange={value => {
                            setFieldValue('phone_extra', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone_extra</div>
                </div>

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone_extra}
                    helperText='phone_extra'
                    name='phone_extra'
                    onChange={phone => setFieldValue('phone_extra', phone)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email_extra}
                    helperText='email_extra'
                    name='email_extra'
                    type='email'
                    onChange={event => setFieldValue('email_extra', event.target.value)} 
                    label="Email"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={comune_extra}
                    helperText='comune_extra'
                    name='comune_extra'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('comune_extra', value);
                        setComune(value)
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_extra', val)}
                    options={cities}
                    value={provincia_extra}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_extra'
                        name='provincia_extra'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label" id='operazione_part'>Operazione</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={operazione_extra}
                        helperText='operazione_extra'
                        name='operazione_extra'
                        onChange={(event, val) => { setFieldValue('operazione_extra', event.target.value);}}
                        label="Operazione"
                        >
                        <MenuItem value='cambio_gestore'>Cambio Gestore</MenuItem>
                        <MenuItem value='attivazione'>Attivazione</MenuItem>
                    </Select>
                    <FormHelperText>operazione_extra</FormHelperText>
                </FormControl>
            </div>

            
        </div>
    )
}

function DelegatoFieldsExtra (props) {
    const { setFieldValue, data } = props;

    const [qualita_delegato_extra] = React.useState(data.qualita_delegato_extra);
    const [cognome_delegato_extra] = React.useState(data.cognome_delegato_extra);
    const [nome_delegato_extra] = React.useState(data.nome_delegato_extra);
    const [cf_delegato_extra] = React.useState(data.cf_delegato_extra);

    return (
        <div className='section'>
            <div className='head_text'>Delegato</div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Qualità Delegato</InputLabel>
                    <Select
                        labelId="Qualità Delegato"
                        id="demo-simple-select-outlined"
                        defaultValue={qualita_delegato_extra}
                        helperText='qualita_delegato_extra'
                    name='qualita_delegato_extra'
                        onChange={(event) => {

                            setFieldValue('qualita_delegato_extra', event.target.value);
                        }}
                        label="Qualità Delegato"
                        >
                        <MenuItem value='referente-aziendale'>Referente Aziendale</MenuItem>
                        <MenuItem value='moglie'>Moglie</MenuItem>
                        <MenuItem value='marito'>Marito</MenuItem>
                        <MenuItem value='figlio'>Figlio</MenuItem>
                        <MenuItem value='figlia'>Figlia</MenuItem>
                        <MenuItem value='genitore'>Genitore</MenuItem>
                    </Select>
                    <FormHelperText>qualita_delegato_extra</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field'
                    defaultValue={cognome_delegato_extra} 
                    helperText='cognome_delegato_extra'
                    name='cognome_delegato_extra'
                    onChange={event => setFieldValue('cognome_delegato_extra', event.target.value)} 
                    label="Cognome Delegato" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={nome_delegato_extra} 
                    helperText='nome_delegato_extra'
                    name='nome_delegato_extra'
                    onChange={event => setFieldValue('nome_delegato_extra', event.target.value)} 
                    label="Nome Delegato" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={cf_delegato_extra} 
                    helperText='cf_delegato_extra'
                    name='cf_delegato_extra'
                    onChange={event => setFieldValue('cf_delegato_extra', event.target.value)} 
                    label="CF Delegato" 
                        />
            </div>
        </div>
    )
}

function DescriptionFieldsExtra (props) {
    const { setFieldValue, data } = props;

    const [description_extra] = React.useState(data.description_extra);

    return (
        <div className='section'>
            <div className='head_text'>Description</div>
            <div className='fields'>
                <TextField 
                    className='field'
                    multiline
                    rows={5}
                    defaultValue={description_extra} 
                    helperText='description_extra'
                    name='description_extra'
                    onChange={event => setFieldValue('description_extra', event.target.value)} 
                    label="Description" 
                        />
            </div>
        </div>
    )
}


function OtherExtra (props) {
    const { 
        setFieldValue, 
        data,
        sub_contract,
        createLead
    } = props;

    const [callback_time_extra, setCallbackTime] = React.useState(data.callback_time_extra || null);
    const [passaggio_diretto_extra, setPassaggio] = React.useState(data.passaggio_diretto_extra);

    return (
        <div className='section'>
            <div className='head_text'>Other</div>
            <div className='fields -other'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDateTimePicker
                        // clearable
                        className='date' 
                        value={callback_time_extra}
                        onChange={(date) => {setFieldValue('callback_time_extra', date); setCallbackTime(date) }}
                        helperText="Callback time"
                        disablePast
                        format="DD/MM/YYYY HH:mm"
                        ampm={false}
                    />
                </MuiPickersUtilsProvider>
                <div className='action'
                    onClick={createLead}
                >Create Lead</div>
            </div>

            <div className='section -checkbox'>
                <FormControlLabel
                    control={<Checkbox checked={passaggio_diretto_extra} name="passaggio_diretto" />}
                    onChange={(event, value) => {setFieldValue('passaggio_diretto_extra', value); setPassaggio(value)}}
                    label="Passaggio diretto"
                />
            </div>
        </div>
    )
}

const showProdotoExtra = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
    ) ? true : false;
}


const showContactInfoExtraIrRasa = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && (prodotto === 'ir_casa_h24' || prodotto === 'ir_fibra' || prodotto === 'es_fibra' || prodotto === 'ir_casa_h24_' || prodotto === 'ir_casa_h24+_(telco)' || prodotto === 'ei_impianto_luce' || prodotto === 'ei_impianto_gas' || prodotto === 'ok_tuttofare_24' || prodotto === 'yo_casa_sicura_silver' || prodotto === 'yo_casa_sicura_gold' || prodotto === 'aa_life_care' || prodotto === 'aa_life_care' || prodotto === 'aa_case_sicura_caldaia' || prodotto === 'aa_casa_sicure_h24' || prodotto === 'yo_casa_light' || prodotto === 'yo_casa_full')
    ) ? true : false;
}

const showContactInfoExtraTimVision = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && (prodotto === 'ti_tim_vision_gold' || prodotto === 'ti_tim_vision' || prodotto === 'fw_protect')
    ) ? true : false;
}

const showContactInfoExtraIrRasaBusiness = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && (prodotto === 'ir_h24_business_energia' || prodotto === 'ir_h24_business_telco')
    ) ? true : false;
}


const showContactInfoExtraSgFibraBusiness = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && (prodotto === 'sg_fibra_business')
    ) ? true : false;
}

const showContactInfoExtraIrManutenzione = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && (prodotto === 'ir_manutenzione_caldaia' || prodotto === 'es_impianto_luce' || prodotto === 'es_impianto_gas')
    ) ? true : false;
}

const showContactInfoExtraLinkem = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && prodotto === 'linkem'
    ) ? true : false;
}

const showContactInfoExtraTado = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && prodotto === 'tado'
    ) ? true : false;
}

const showContactInfoExtraEiFlexiManutenzione = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && prodotto === 'ei_flexi_manutezione'
    ) ? true : false;
}

const showContactInfoExtraSgFibra = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && prodotto === 'sg_fibra'
    ) ? true : false;
}

const showContactInfoExtraMelita = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && prodotto === 'melita'
    ) ? true : false;
}

const showContactInfoExtraCo2 = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && (prodotto === 'offset_elec' || prodotto === 'offset_gas' || prodotto === 'offset_household' || prodotto === 'offset_perso')
    ) ? true : false;
}

const showContactInfoExtraGpl = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && (prodotto === 'un_prospect_gpl' || prodotto === 'lq_prospect_gpl')
    ) ? true : false;
}

const showDelegatoExtra = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract,
        sub_contract_extra
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && prodotto
    ) ? true : false;
}

const showDelegatoExtraOther = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract,
        sub_contract_extra
    } = payload;

    return (
        (   
            (type_contract === 'vendite extra' && sub_contract === 'lead crosseling')
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra' && sub_contract_extra === 'lead crosseling')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra' && sub_contract_extra === 'lead crosseling')
        )
        && prodotto
    ) ? true : false;
}

const showDescriptionExtra = (payload) => {
    const {
        type_contract,
        prodotto,
        sub_contract
    } = payload;

    return (
        (   
            type_contract === 'vendite extra' 
            || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
            || (type_contract === 'telco'  && sub_contract === 'vendite extra')
        )
        && prodotto
    ) ? true : false;
}


// Vendite Extra END






















// ENERGIA PART

function DomesticoEnergiaFields(props) {
    const { data, setFieldValue, setFieldValueState } = props;

    const [name] = React.useState(data.name);
    const [last_name] = React.useState(data.last_name);
    const [phone] = React.useState(data.phone);
    const [cellulare] = React.useState(data.cellulare);
    const [email] = React.useState(data.email);
    const [codice_fiscale, setCodiceFiscale] = React.useState(data.codice_fiscale);
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [data_di_nascita, setDataDiNascita] = React.useState(data.data_di_nascita || null);
    const [luogo_di_nascita, setLuogoDiNas] = React.useState(data.luogo_di_nascita || '');
    const [nazione_di_nascita] = React.useState(data.nazione_di_nascita);
    const [sesso, setSesso] = React.useState(data.sesso || '');
    

    const [phoneError, setPhoneError] = React.useState(false);
    const [cellulareError, setCellulareError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const checkCell = (phone) => {
        if(phone.length < 2)
            return setCellulareError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setCellulareError(true)

        if(!isMobilePhone(phone))
            return setCellulareError(true)

        setCellulareError(false)
    }

    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataDiNascita(birthday);
        setLuogoDiNas(lugo)

        setFieldValue('data_di_nascita', birthday);
        setFieldValue('luogo_di_nascita', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso', status);
    }

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Intestatario Info</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={name}
                    helperText='name'
                    name='name'
                    onChange={event => setFieldValue('name', event.target.value)} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={last_name}
                    helperText='last_name'
                    name='last_name'
                    onChange={event => setFieldValue('last_name', event.target.value)} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
            <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            defaultValue: phone
                        }}
                        onChange={value => {
                            setFieldValue('phone', value);
                            checkMob(value);
                        }} 
                    />
                    <div className='helper_text'>phone</div>
                </div>
                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone}
                    helperText='phone'
                    name='phone'
                    onChange={value => setFieldValue('phone', value)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}

                <div className={ cellulareError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={cellulare}
                        inputProps={{
                            name: 'cellulare',
                            defaultValue: cellulare
                        }}
                        onChange={value => {
                            setFieldValue('cellulare', value);
                            checkCell(value);
                        }} 
                    />
                    <div className='helper_text'>cellulare</div>
                </div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={email}
                    helperText='email'
                    name='email'
                    type='email'
                    onChange={event => setFieldValue('email', event.target.value)} 
                    label="Email"  
                        />

                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale}
                    helperText='codice_fiscale'
                    name='codice_fiscale'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale', event.target.value);
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso}
                    helperText='sesso'
                    name='sesso'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale}&cognome=${data.last_name}&nome=${data.name}&data=${momentT(data_di_nascita).format('DD/MM/YYYY')}&comune=${luogo_di_nascita}&sesso=${sesso}`;

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');

                    // window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf', '_blank');
                }}>Fiscal Code Check</div>
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Nascita"
                    value={data_di_nascita}
                    helperText='data_di_nascita'
                    name='data_di_nascita'
                    onChange={(date) => { setFieldValue('data_di_nascita', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </MuiPickersUtilsProvider>

                <TextField 
                    required 
                    className='field' 
                    value={luogo_di_nascita}
                    helperText='luogo_di_nascita'
                    name='luogo_di_nascita'
                    onChange={event => {
                        setFieldValue('luogo_di_nascita', event.target.value);
                        setLuogoDiNas(event.target.value);
                    }} 
                    label="Luogo Di Nascita" 
                        />
            </div>

            
        </div>
    )
}

function BusinessEnergiaFields(props) {
    const { data, client_type, setFieldValueState } = props;

    const [ragione_sociale] = React.useState(data.ragione_sociale);
    const [phone] = React.useState(data.phone);
    const [cellulare] = React.useState(data.cellulare);
    const [email] = React.useState(data.email);
    const [p_iva] = React.useState(data.p_iva);
    const [nome_referente, setNomeReferente] = React.useState(data.nome_referente);
    const [cognome_referente, setCognomeReferente] = React.useState(data.cognome_referente);
    const [codice_fiscale_referente, setCodiceFiscale] = React.useState(data.codice_fiscale_referente);
    const [errorCodice, setErrorCodice] = React.useState(false);
    const [sesso, setSesso] = React.useState(data.sesso || '');

    const [cellulareError, setCellulareError] = React.useState(false);

    // changes 03.03.2021
    const [forma_societaria] = React.useState(data.forma_societaria);
    const [data_nascita_referente, setDataRef] = React.useState(data.data_nascita_referente || null);
    const [luogo_nascita_referente, setNasRef] = React.useState(data.luogo_nascita_referente || '');

    const [phoneError, setPhoneError] = React.useState(false);

    const checkMob = (phone) => {
        if(phone.length < 2)
            return setPhoneError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setPhoneError(true)

        if(!isMobilePhone(phone))
            return setPhoneError(true)

        setPhoneError(false)
    }

    const checkCell = (phone) => {
        if(phone.length < 2)
            return setCellulareError(false);

        if(phone[0] == '0' && phone[1] == '0')
            return setCellulareError(true)

        if(!isMobilePhone(phone))
            return setCellulareError(true)

        setCellulareError(false)
    }

    const changeCodice = (codice) => {
        setCodiceFiscale(codice);
        
        const result = setCodice(codice);
        setErrorCodice(!result);

        if(!result)
            return;

        const {
            birthday,
            lugo
        } = result;

        setDataRef(birthday);
        setNasRef(lugo)

        setFieldValue('data_nascita_referente', birthday);
        setFieldValue('luogo_nascita_referente', lugo);

        const num = +(codice[9] + codice[10]);

        const status = num > 40 ? 'F' : 'M';

        setSesso(status);
        setFieldValue('sesso', status);
    }

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='intestatario_info_part'>Intestatario Info</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={ragione_sociale}
                    helperText='ragione_sociale'
                    name='ragione_sociale'
                    onChange={event => setFieldValue('ragione_sociale', event.target.value)} 
                    label="Ragione Sociale" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Forma societaria</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={forma_societaria}
                        helperText='forma_societaria'
                    name='forma_societaria'
                        onChange={(event, val) => { setFieldValue('forma_societaria', event.target.value) }}
                        label="Forma societaria"
                        >
                        <MenuItem value='società cooperativa'>Società cooperativa</MenuItem>
                        <MenuItem value='snc'>SNC</MenuItem>
                        <MenuItem value='impresa individuale'>Impresa individuale</MenuItem>
                        <MenuItem value='srl'>SRL</MenuItem>
                        <MenuItem value='sapa'>SAPA</MenuItem>
                        <MenuItem value='sas'>SAS</MenuItem>
                        <MenuItem value='spa'>SPA</MenuItem>
                        <MenuItem value='srls'>SRLS</MenuItem>
                        <MenuItem value='ss'>SS</MenuItem>
                        <MenuItem value='ssrl'>SSRL</MenuItem>
                        <MenuItem value='associazione'>Associazione</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='fields'>
            <div className={ phoneError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                    <PhoneInput
                        preferredCountries={['it']}
                        country={'it'}
                        value={phone}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            defaultValue: phone
                        }}
                        onChange={value => {
                            setFieldValue('phone', value);
                            checkMob(value);
                        }}
                    />
                    <div className='helper_text'>phone</div>
                </div>
                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={phone}
                    helperText='phone'
                    name='phone'
                    onChange={value => setFieldValue('phone', value)} 
                    label="Telefono"
                    inputProps={{ pattern: "^[+][0-9]{7,}$", title: "EXAMPLE: +39066784343" }}
                    type='tel'
                        /> */}
            <div className={ cellulareError ? 'field -phone_field -error_phone' : 'field -phone_field'}>
                <PhoneInput
                    preferredCountries={['it']}
                    country={'it'}
                    value={cellulare}
                    inputProps={{
                        name: 'cellulare',
                        defaultValue: cellulare
                    }}
                    onChange={value => {
                        setFieldValue('cellulare', value);
                        checkCell(value);
                    }} 
                />
                <div className='helper_text'>cellulare</div>
            </div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={email}
                    helperText='email'
                    name='email'
                    type='email'
                    onChange={event => setFieldValue('email', event.target.value)} 
                    label="Email"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={p_iva}
                    helperText='p_iva'
                    name='p_iva'
                    onChange={event => setFieldValue('p_iva', event.target.value)} 
                    label="P. IVA" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={cognome_referente}
                    helperText='cognome_referente'
                    name='cognome_referente'
                    onChange={event => {setFieldValue('cognome_referente', event.target.value); setCognomeReferente(event.target.value)}} 
                    label="Cognome Referente" 
                        />
            </div>
            <div className='fields'>

                <TextField 
                    className='field' 
                    defaultValue={nome_referente}
                    helperText='nome_referente'
                    name='nome_referente'
                    onChange={event => { setFieldValue('nome_referente', event.target.value); setNomeReferente(event.target.value)}} 
                    label="Nome Referente" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={codice_fiscale_referente}
                    helperText='codice_fiscale_referente'
                    name='codice_fiscale_referente'
                    error={errorCodice}
                    onChange={event => {
                        setFieldValue('codice_fiscale_referente', event.target.value)
                        changeCodice(event.target.value);
                    }} 
                    label="Codice Fiscale Referente" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={sesso}
                    helperText='sesso'
                    name='sesso'
                    onChange={event => {
                        const value = event.target.value.toUpperCase();

                        setFieldValue('sesso', value); 
                        setSesso(value)
                    }}
                    label="Sesso" 
                        />
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Nascita Referente"
                    value={data_nascita_referente}
                    helperText='data_nascita_referente'
                    name='data_nascita_referente'
                    onChange={(date) => { setFieldValue('data_nascita_referente', date); setDataRef(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>

                <TextField 
                    className='field' 
                    value={luogo_nascita_referente}
                    helperText='luogo_nascita_referente'
                    name='luogo_nascita_referente'
                    onChange={event => {setFieldValue('luogo_nascita_referente', event.target.value); setNasRef(event.target.value)}} 
                    label="Luogo nascita referente" 
                        />
            </div>

            <div className='actions'>
                <div className='action' onClick={() => {
                    const params = `cf=${codice_fiscale_referente}&cognome=${cognome_referente}&nome=${nome_referente}&data=${momentT(data_nascita_referente).format('DD/MM/YYYY')}&comune=${luogo_nascita_referente}&sesso=${sesso}`; 

                    window.open('https://telematici.agenziaentrate.gov.it/VerificaCF/VerificaCfPf.do?' + params, '_blank');
                }}>Fiscal Code Check</div>
            </div>

            
        </div>
    )
}

const showEnergiaDomestico = (payload) => {
    const {
        type_contract,
        client_type
    } = payload;

    return (
        type_contract === 'contratto energia'
        && client_type === 'domestico'
    ) ? true : false;
}

const showEnergiaBusiness = (payload) => {
    const {
        type_contract,
        client_type
    } = payload;

    return (
        type_contract === 'contratto energia'
        && client_type === 'business'
    ) ? true : false;
}


function ResidenzaEnergiaFields(props) {
    const { data, setFieldValue } = props;

    const [tipo_indirizzo_residenza, setIndR] = React.useState(data.tipo_indirizzo_residenza || '');
    const [indirizzo_residenza, setInd] = React.useState(data.indirizzo_residenza || '');
    const [civico_residenza, setCiv] = React.useState(data.civico_residenza || '');
    const [cap_residenza, setCap] = React.useState(data.cap_residenza || '');
    const [comune_residenza, setCom] = React.useState(data.comune_residenza || '');
    const [provincia_residenza, setProv] = React.useState(data.provincia_residenza || '');
    const [address, setAddressObj] = React.useState('');

    const [invalidAddress, setInvalidAddress] = React.useState('no checked');

    const setAddressAction = () => {
        const adr = address;

        let name = adr.street;
        let street = adr.street;
        let lastIndex = 0;
        let regexDoubleName = /(STRADA COMUNALE)|(STRADA PROVINCIALE)|(VIA PRIVATA)/g;

        if(regexDoubleName.test(name)) {
            lastIndex = name.indexOf(' ', name.indexOf(' ') +1);
            name = name.slice(0, lastIndex);

            if(street)
                street = street.slice(lastIndex);
        }else {
            lastIndex = name.indexOf(' ') +1;
            name = name.slice(0, lastIndex);

            if(street)
                street = street.slice(lastIndex);
        }

        setIndR(name);
        setInd(street);
        setCiv(adr.streetNumber);
        setCap(adr.postCode);
        setCom(adr.city);
        setProv(adr.district);

        setFieldValue('tipo_indirizzo_residenza', name)
        setFieldValue('indirizzo_residenza', street)
        setFieldValue('civico_residenza', adr.streetNumber)
        setFieldValue('cap_residenza', adr.postCode)
        setFieldValue('comune_residenza', adr.city)
        setFieldValue('provincia_residenza', adr.district)
    }

    const setAddress = (result, result2) => {
        setAddressObj(result);

        const {
            city,
            district,
            state
        } = result;

        if(city && district && state) {
            const payload = {
                city,
                state: district,
                regione: state
            };

            service.verifyAddress(payload).then(resp => {
                const { ok, exist } = resp.data;

                exist ? setInvalidAddress('valid') : setInvalidAddress('invalid');
            })
        } else {
            setInvalidAddress('invalid')
        }
    }

    return (
        <div className='section'>
            <div className='head_text' id='side_legale_part'>Residenza - Sede Legale</div>
            { invalidAddress === 'invalid' && <div className='top_notification -invalid'>Controlla che non sia una frazione o un errore di ortografia</div> }
            <div className='fields'>
                <LocationSearch name='Indirizzo Di Residenza/Sede Legale' nameField='google_residenza' show={true} setAddress={setAddress}/>  
                <div className='action -address' onClick={setAddressAction}>Set Address</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={tipo_indirizzo_residenza}
                    helperText='tipo_indirizzo_residenza'
                    name='tipo_indirizzo_residenza'
                    onChange={event => {
                        const val = event.target.value;
                        setFieldValue('tipo_indirizzo_residenza', val);
                        setIndR(val);
                    }} 
                    label="Tipo Indirizzo Residenza" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_residenza}
                    helperText='indirizzo_residenza'
                    name='indirizzo_residenza'
                    onChange={event => {
                        const val = event.target.value;
                        setFieldValue('indirizzo_residenza', val);
                        setInd(val);
                    }} 
                    label="Indirizzo Residenza" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_residenza}
                    helperText='civico_residenza'
                    name='civico_residenza'
                    onChange={event => {
                        const val = event.target.value;
                        setFieldValue('civico_residenza', val);
                        setCiv(val);
                    }} 
                    label="Civico Residenza" 
                        />
                <TextField 
                    required 
                    className='field' 
                    value={cap_residenza}
                    helperText='cap_residenza'
                    name='cap_residenza'
                    onChange={event => {
                        const val = event.target.value;
                        setFieldValue('cap_residenza', val);
                        setCap(val);
                    }} 
                    label="Cap Residenza" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={comune_residenza}
                    helperText='comune_residenza'
                    name='comune_residenza'
                    onChange={event => {
                        const val = event.target.value;
                        setFieldValue('comune_residenza', val);
                        setCom(val);
                    }} 
                    label="Comune Residenza" 
                        />

            <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    value={(event, val) => setFieldValue('provincia_residenza', val)}
                    options={cities}
                    value={provincia_residenza}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_residenza'
                        name='provincia_residenza'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
                
            </div>
        </div>
    )
}

const showEnergiaResidenza = (payload) => {
    const {
        type_contract,
        client_type
    } = payload;

    return (
        type_contract === 'contratto energia'
        && client_type
    ) ? true : false;
}

function PotentialInfoEnergiaFields(props) {
    const { 
        data,
        setFieldValue,
        setFieldValueState,
        products,
        typeProduct,
        type_product,
        operations,
        client_type
    } = props;

    const [product_ee, setEE] = React.useState(props.product_ee || '');
    const [product_gas, setGAS] = React.useState(props.product_gas || '');
    const [operation_ee, setOpEE] = React.useState(props.operation_ee || '');
    const [operation_gas, setOpGAS] = React.useState(props.operation_gas || '');


    const filterOp = (product, operations) => {
        if(!product || !product.product_name)
            return operations;

        const name = product.product_name[0] + product.product_name[1];

        return operations.filter(operation => new RegExp('^'+name, 'gim').test(operation.name));
    };



    return (
        <div className='section potentialInfo'>
            <div className='head_text'>Potential Info</div>
            {(typeProduct === 'gas' || typeProduct === 'dual') && <div className='fields'>
                <Autocomplete
                    freeSolo
                    className='field -product'
                    onChange={(event, val) => {
                        setFieldValueState('product_gas', val || {});
                        setGAS(val || {});
                    }}
                    options={products.filter(prod => {
                        return (prod.type === 'Gas' && ((client_type === 'domestico' && prod.customer_type === 'Private') || (client_type === 'business' && prod.customer_type === 'Business' )))
                    })}
                    defaultValue={product_gas}
                    helperText='product_gas'
                    name='product_gas'
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.product_name)}
                    renderInput={(params) => (
                    <TextField 
                    required
                        {...params}
                        label="Product Gas"
                        margin="normal"
                        variant="outlined"
                        helperText='product_gas'
                    name='product_gas'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />

                <Autocomplete
                    freeSolo
                    required
                    className='field -product'
                    onChange={(event, val) => setFieldValueState('operation_gas', val || {})}
                    options={filterOp(product_gas, operations).filter(op => op.tipo_di_utenza === 'gas' && op.client_type === client_type)}
                    defaultValue={operation_gas}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Operation Gas"
                        margin="normal"
                        variant="outlined"
                        helperText='operation_gas'
                    name='operation_gas'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>}
            {(typeProduct === 'luce' || typeProduct === 'dual') && <div className='fields'>
                <Autocomplete
                    freeSolo
                    required
                    className='field -product'
                    onChange={(event, val) => {
                        setFieldValueState('product_ee', val || {});
                        setEE(val || {});
                    }}
                    options={products.filter(prod => {
                        return (prod.type === 'Power' && ((client_type === 'domestico' && prod.customer_type === 'Private') || (client_type === 'business' && prod.customer_type === 'Business' )))
                    })}
                    defaultValue={product_ee}
                    helperText='product_ee'
                    name='product_ee'
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.product_name)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Product EE"
                        margin="normal"
                        variant="outlined"
                        helperText='product_ee'
                    name='product_ee'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
                <Autocomplete
                    freeSolo
                    required
                    className='field -product'
                    onChange={(event, val) => setFieldValueState('operation_ee', val || {})}
                    options={filterOp(product_ee, operations).filter(op => op.tipo_di_utenza === 'luce' && op.client_type === client_type)}
                    defaultValue={operation_ee}
                    helperText='operation_ee'
                    name='operation_ee'
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Operation EE"
                        helperText='operation_ee'
                    name='operation_ee'
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>}
        </div>
    )
}

const showPotentialInfoEnergia = (payload) => {
    const {
        type_contract,
        type_product,
        client_type
    } = payload;

    return (
        type_contract === 'contratto energia'
        && type_product
        && client_type
    ) ? true : false;
}

function PuntoDiFornituraEnergiaFields(props) {
    const { 
        data, 
        product_ee, 
        product_gas, 
        operation_ee, 
        operation_gas, 
        client_type, 
        setFieldValue,
        setFieldValueState
    } = props;

    const CV = /^CV/gmi;
    const EG = /^EG/gmi;
    const IREGCV = new RegExp(/^IR|^GN|^EG|^CV/, 'gmi');
    const IREGCVWK = new RegExp(/^IR|^GN|^EG|^CV/, 'gmi');
    const WK = new RegExp(/^WK/, 'gmi');
    const AllacPrima =  new RegExp(/(Allac.)|(Prima Att.)/, 'gmi');

    const [tipo_indirizzo_punto, setTipInd] = React.useState(data.tipo_indirizzo_punto || '');
    const [indirizzo_punto, setInd] = React.useState(data.indirizzo_punto || '');
    const [civico_punto, setCiv] = React.useState(data.civico_punto || '');
    const [cap_punto, setCap] = React.useState(data.cap_punto || '');
    const [comune_punto, setComune] = React.useState(data.comune_punto || '');
    const [provincia_punto, setProv] = React.useState(data.provincia_punto || '');
    const [property_status_punto] = React.useState(data.property_status_punto || '');
    const [foglio_punto] = React.useState(data.foglio_punto);
    const [particella_punto] = React.useState(data.particella_punto);
    const [sub_punto] = React.useState(data.sub_punto);
    const [comune_di_rilascio_punto] = React.useState(data.comune_di_rilascio_punto);
    const [concessione_edilizia_punto] = React.useState(data.concessione_edilizia_punto);
    const [address, setAddressObj] = React.useState('');
    const [data_di_rilascio_punto, setDataRef] = React.useState(data.data_di_rilascio_punto || null);

    const [permesso_di_costruitire] = React.useState(data.permesso_di_costruitire);
    const [ente_di_rilascio] = React.useState(data.ente_di_rilascio);

    const [showEgStatus, setShowEgStatus] = React.useState(data.showEgStatus || false);

    const [invalidAddress, setInvalidAddress] = React.useState('no checked');

    const setAddressAction = () => {
        const adr = address;

        let name = adr.street;
        let street = adr.street;
        let lastIndex = 0;
        let regexDoubleName = /(STRADA COMUNALE)|(STRADA PROVINCIALE)|(VIA PRIVATA)/g;

        if(regexDoubleName.test(name)) {
            lastIndex = name.indexOf(' ', name.indexOf(' ') +1);
            name = name.slice(0, lastIndex);

            if(street)
                street = street.slice(lastIndex);
        }else {
            lastIndex = name.indexOf(' ') +1;
            name = name.slice(0, lastIndex);

            if(street)
                street = street.slice(lastIndex);
        }

        setTipInd(name);
        setInd(street);
        setCiv(adr.streetNumber);
        setCap(adr.postCode);
        setComune(adr.city);
        setProv(adr.district);

        setFieldValue('tipo_indirizzo_punto', name)
        setFieldValue('indirizzo_punto', street)
        setFieldValue('civico_punto', adr.streetNumber)
        setFieldValue('cap_punto', adr.postCode)
        setFieldValue('comune_punto', adr.city)
        setFieldValue('provincia_punto', adr.district)

        setFieldValueState('theSameAddress', 'reset');
    }

    const setAddress = (result, result2) => {
        setAddressObj(result);

        const {
            city,
            district,
            state
        } = result;

        if(city && district && state) {
            const payload = {
                city,
                state: district,
                regione: state
            };

            service.verifyAddress(payload).then(resp => {
                const { ok, exist } = resp.data;

                exist ? setInvalidAddress('valid') : setInvalidAddress('invalid');
            })
        } else {
            setInvalidAddress('invalid')
        }
    }

    const productContainStr = (product, regex) => regex.test(product.product_name) ? true : false;
    const operationsContainStr = (operation, regex) => regex.test(operation.name) ? true : false;

    const showEg = (productContainStr(product_ee, EG) || productContainStr(product_gas, EG));
    const showEgCv = (productContainStr(product_ee, CV) || productContainStr(product_gas, CV));
    const showIrEgCv = (IREGCV.test(product_ee.product_name) || IREGCV.test(product_gas.product_name));
    const showIrEgCvWKProd = (IREGCVWK.test(product_ee.product_name) || IREGCVWK.test(product_gas.product_name));
    const showIrEgCvWKOpe= (AllacPrima.test(operation_ee.name) || AllacPrima.test(operation_gas.name));
    const showIrEgCvWk = false;
    const HideWk = (WK.test(product_ee.product_name) || WK.test(product_gas.product_name));

    const setTheSameRes = () => {
        const {
            tipo_indirizzo_residenza,
            indirizzo_residenza,
            civico_residenza,
            cap_residenza,
            comune_residenza,
            provincia_residenza,
        } = props.data;

        setTipInd(tipo_indirizzo_residenza);
        setInd(indirizzo_residenza);
        setCiv(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('tipo_indirizzo_punto', tipo_indirizzo_residenza);
        setFieldValue('indirizzo_punto', indirizzo_residenza);
        setFieldValue('civico_punto', civico_residenza);
        setFieldValue('cap_punto', cap_residenza);
        setFieldValue('comune_punto', comune_residenza);
        setFieldValue('provincia_punto', provincia_residenza);

        if(client_type === 'domestico')
            setFieldValueState('theSameAddress', 'selected');
    }

    return (
        <div className='section'>
            <div className='head_text' id='punto_di_fornitura_part'>Punto Di Fornitura</div>
            { invalidAddress === 'invalid' && <div className='top_notification -invalid'>Controlla che non sia una frazione o un errore di ortografia</div> }
            <div className='top_actions'>
                <div className='action' onClick={setTheSameRes}>Set the same as residenza</div> 
            </div>
            <div className='fields'>
                <LocationSearch name='Indirizzo Di Prevelio' nameField='google_punto' show={true} setAddress={setAddress}/>  
                <div className='action -address' onClick={setAddressAction}>Set Address</div>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={tipo_indirizzo_punto}
                    helperText='tipo_indirizzo_punto'
                    name='tipo_indirizzo_punto'
                    onChange={event => {
                        setFieldValue('tipo_indirizzo_punto', event.target.value);
                        setTipInd(event.target.value);  
                    }}
                    label="Tipo Indirizzo" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_punto}
                    helperText='indirizzo_punto'
                    name='indirizzo_punto'
                    onChange={event => {
                        setFieldValue('indirizzo_punto', event.target.value);
                        setInd(event.target.value); 
                    }}
                    label="Indirizzo" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_punto}
                    helperText='civico_punto'
                    name='civico_punto'
                    onChange={event => {
                        setFieldValue('civico_punto', event.target.value);
                        setCiv(event.target.value); 
                    }}
                    label="Civico" 
                        />
                <TextField 
                    required 
                    className='field' 
                    value={cap_punto}
                    helperText='cap_punto'
                    name='cap_punto'
                    onChange={event => {
                        setFieldValue('cap_punto', event.target.value);
                        setCap(event.target.value); 
                    }}
                    label="Cap" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={comune_punto}
                    helperText='comune_punto'
                    name='comune_punto'
                    onChange={event => {
                        setFieldValue('comune_punto', event.target.value);
                        setComune(event.target.value); 
                    }}
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_punto', val)}
                    options={cities}
                    value={provincia_punto}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_punto'
                        name='provincia_punto'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            { !HideWk && <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Property Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={property_status_punto}
                        helperText='property_status_punto'
                        name='property_status_punto'
                        required
                        onChange={(event, val) => { setFieldValue('property_status_punto', event.target.value)}}
                        label="Property Status"
                        >
                        <MenuItem value='proprietario'>Proprietario</MenuItem>
                        <MenuItem value='affittuario commerciale'>Affittuario Commerciale</MenuItem>
                        <MenuItem value='affittuario per altri usi'>Affittuario Per Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='affittuario_domestico'>Affittuario domestico</MenuItem>}
                        <MenuItem value='altro'>ALTRO</MenuItem>
                    </Select>
                    <FormHelperText>property_status_punto</FormHelperText>
                </FormControl>
            </div> }

            {showEg && <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Dati Catastali Disponibili?</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={showEgStatus}
                        helperText='showEgStatus'
                        required
                    name='showEgStatus'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('showEgStatus', value);
                            setShowEgStatus(value);
                        }}
                        label="Dati Catastali Disponibili"
                        >
                        <MenuItem value={true}>Si</MenuItem>
                        <MenuItem value={false}>No</MenuItem>
                    </Select>
                    <FormHelperText>showEgStatus</FormHelperText>
                </FormControl>
            </div>}



            {(showEgCv || (showEg && showEgStatus)) && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={foglio_punto}
                    helperText='foglio_punto'
                    name='foglio_punto'
                    onChange={event => setFieldValue('foglio_punto', event.target.value)} 
                    label="Foglio" 
                        />
            </div>}

            {(showEgCv || (showEg && showEgStatus)) && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={particella_punto}
                    helperText='particella_punto'
                    name='particella_punto'
                    onChange={event => setFieldValue('particella_punto', event.target.value)} 
                    label="Particella" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={sub_punto}
                    helperText='sub_punto'
                    name='sub_punto'
                    onChange={event => setFieldValue('sub_punto', event.target.value)} 
                    label="Sub" 
                        />
            </div>}
            {(showIrEgCv && showIrEgCvWKOpe) && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={comune_di_rilascio_punto}
                    helperText='comune_di_rilascio_punto'
                    name='comune_di_rilascio_punto'
                    onChange={event => setFieldValue('comune_di_rilascio_punto', event.target.value)} 
                    label="Comune Di Rilascio" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={concessione_edilizia_punto}
                    helperText='concessione_edilizia_punto'
                    name='concessione_edilizia_punto'
                    onChange={event => setFieldValue('concessione_edilizia_punto', event.target.value)} 
                    label="Concessione Edilizia" 
                        />
            </div>}
            {(showIrEgCvWKProd && showIrEgCvWKOpe) && <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Permesso di costruire</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={permesso_di_costruitire}
                        helperText='permesso_di_costruitire'
                        required
                        name='permesso_di_costruitire'
                        onChange={(event, val) => { setFieldValue('permesso_di_costruitire', event.target.value)}}
                        label="Permesso di costruire"
                        >
                        <MenuItem value='permesso di costruire'>Permesso di costruire</MenuItem>
                        <MenuItem value='dichiarazione di inizio attività (dia)'>Dichiarazione di inizio attività (DIA)</MenuItem>
                        <MenuItem value='concessione edilizia'>Concessione edilizia</MenuItem>
                        <MenuItem value='accertamento di conformità (sanatoria)'>Accertamento di conformità (sanatoria)</MenuItem>
                        <MenuItem value='delibera comunale'>Delibera Comunale</MenuItem>
                        <MenuItem value='segnalazione di inizio attività (scia)'>Segnalazione di inizio attività (SCIA)</MenuItem>
                        <MenuItem value='pre 77'>PRE 77</MenuItem>
                        <MenuItem value='non richiesto'>Non richiesto</MenuItem>
                    </Select>
                    <FormHelperText>permesso_di_costruitire</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={ente_di_rilascio}
                    helperText='ente_di_rilascio'
                    name='ente_di_rilascio'
                    onChange={event => setFieldValue('ente_di_rilascio', event.target.value)} 
                    label="Ente di rilascio" 
                        />
            </div>}

            {(showIrEgCvWKProd && showIrEgCvWKOpe) && <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"

                    required
                    id="date-picker-inline"
                    label="Data di rilascio"
                    value={data_di_rilascio_punto}
                    helperText='data_di_rilascio_punto'
                    name='data_di_rilascio_punto'
                    onChange={(date) => { setFieldValue('data_di_rilascio_punto', date); setDataRef(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>}
        </div>
    )
}

const showPuntoFornituraEnergia = (payload) => {
    const {
        type_contract,
        type_product,
        client_type,
        product_ee,
        product_gas,
        theSameAddress,
    } = payload;

    const eeNe = product_ee ? /^ne/gmi.test(product_ee.product_name) : false;
    const gasNe = product_gas ? /^ne/gmi.test(product_gas.product_name) : false;

    return (
        type_contract === 'contratto energia'
        && type_product
        && client_type
    ) ? true : false;
}

function LuceEiAllac(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const { setFieldValue } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        required
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        required
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="Fasce Orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceEiPrimaAtt(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const { setFieldValue } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={destinazione_duso}
                        helperText='destinazione_duso'
                    name='destinazione_duso'
                        onChange={(event, val) => { setFieldValue('destinazione_duso', event.target.value)}}
                        label="Destinazione D'uso"
                        >
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceEiSubentro(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const { setFieldValue } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    console.log(!destinazione_duso && theSameAddress, destinazione_duso, theSameAddress, 'theSameAddress')

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceEiCambio(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const { setFieldValue } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Disp." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        required
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        required
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                    freeSolo
                    required
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceEiVoltura(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={destinazione_duso}
                        helperText='destinazione_duso'
                    name='destinazione_duso'
                        onChange={(event, val) => { setFieldValue('destinazione_duso', event.target.value)}}
                        label="Destinazione D'uso" 
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                    freeSolo
                    required
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

const showLuce = (payload, starts, contains) => {
    const {
        type_contract,
        type_product,
        product_ee,
        operation_ee
    } = payload;

    const contain = contains !== 'Subentro' ? contains : 'Subentro|Sub'

    const productReg = new RegExp(`^${starts}`, 'gmi');
    const operationReg = new RegExp(`${contain}`, 'gmi');
    const voltura = (contains === 'Cambio') ? new RegExp(`voltura`, 'gmi') : null

    return (
        type_contract === 'contratto energia'
        && (type_product === 'luce' || type_product === 'dual')
        && productReg.test(product_ee.product_name)
        && operationReg.test(operation_ee.name)
        && (!voltura ? true : !voltura.test(operation_ee.name))
    ) ? true : false;
}




// EE





function LuceEEAllac(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceEEPrimaAtt(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceEESubentro(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceEECambio(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                    required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceEEVoltura(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}





/// FW





function LuceFWAllac(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceFWPrimaAtt(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceFWSubentro(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceFWCambio(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                    required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceFWVoltura(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}










/// END FW










function LuceIRAllac(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceIRPrimaAtt(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceIRSubentro(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const [mercato_rientro_luce] = React.useState(data.mercato_rientro_luce);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>

            { client_type === 'business' && <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Rientro Luce</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_rientro_luce}
                        helperText='mercato_rientro_luce'
                    name='mercato_rientro_luce'
                        onChange={(event, val) => { setFieldValue('mercato_rientro_luce', event.target.value)}}
                        label="Mercato Rientro Luce"
                        >
                        <MenuItem 
                            value='Maggior Tutela'>
                            Di essere titolare unicamente di punti di prelievo con potenza contrattualmente impegnata inferiore o pari a 15 kW e di avere meno di 10 dipendenti e un fatturato annuo o un totale di bilancio non superiore a 2 milioni di euro (requisiti maggior tutela)
                        </MenuItem>
                        <MenuItem 
                            value='Tutele graduali'>
                                Di essere titolare unicamente di punti di prelievo in bassa tensione con potenza contrattuale impegnata superiore a 15 kW e di avere meno di 50 dipendenti e un fatturato annuo o totale di bilancio non superiore a 10 milioni di euro (requisiti tutele graduali)
                        </MenuItem>
                        <MenuItem 
                            value='Salvaguardia'>
                                Di non soddisfare alcuna delle condizioni precedenti (requisiti salvaguardia)
                        </MenuItem>
                    </Select>
                </FormControl>
            </div> }
        </div>
    )
}

function LuceIRCambio(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceIRVoltura(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}












function LuceSGAllac(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceSGPrimaAtt(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceSGSubentro(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceSGCambio(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceSGVoltura(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}










function LuceWKAllac(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl> */}
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceWKPrimaAtt(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl> */}

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl> */}

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        /> */}
            </div>
        </div>
    )
}

function LuceWKSubentro(props) {
    const { 
        data, 
        client_type, 
        product_ee,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);
    const [ammontare_carica_ee] = React.useState(data.ammontare_carica_ee);
    const [periodicita_della_fatturazione_ee] = React.useState(data.periodicita_della_fatturazione_ee);

    const ewarg = /(WK EE Evergreen|WK Gas Evergreen)/gmi.test(product_ee.product_name);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl> */}

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl> */}

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        /> */}
            </div>

            <div className='fields'>
                {!ewarg && <TextField 
                    required 
                    className='field' 
                    defaultValue={ammontare_carica_ee}
                    helperText='ammontare_carica_ee'
                    name='ammontare_carica_ee'
                    onChange={event => setFieldValue('ammontare_carica_ee', event.target.value)} 
                    label="Ammontare Carica" 
                        />
                        }


                {!ewarg && <TextField 
                    required 
                    className='field' 
                    defaultValue={periodicita_della_fatturazione_ee}
                    helperText='periodicita_della_fatturazione_ee'
                    name='periodicita_della_fatturazione_ee'
                    onChange={event => setFieldValue('periodicita_della_fatturazione_ee', event.target.value)} 
                    label="Periodicita Della Fatturazione" 
                        />}

                
            </div>
        </div>
    )
}

function LuceWKCambio(props) {
    const { 
        data, 
        client_type, 
        product_ee,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);
    const [ammontare_carica_ee] = React.useState(data.ammontare_carica_ee);
    const [periodicita_della_fatturazione_ee] = React.useState(data.periodicita_della_fatturazione_ee);

    const ewarg = /(WK EE Evergreen|WK Gas Evergreen)/gmi.test(product_ee.product_name);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl> */}

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl> */}

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        /> */}
            </div>

            <div className='fields'>
                {!ewarg && <TextField 
                    required 
                    className='field' 
                    defaultValue={ammontare_carica_ee}
                    helperText='ammontare_carica_ee'
                    name='ammontare_carica_ee'
                    onChange={event => setFieldValue('ammontare_carica_ee', event.target.value)} 
                    label="Ammontare Carica" 
                        />
                        }

                {!ewarg && <TextField 
                    required 
                    className='field' 
                    defaultValue={periodicita_della_fatturazione_ee}
                    helperText='periodicita_della_fatturazione_ee'
                    name='periodicita_della_fatturazione_ee'
                    onChange={event => setFieldValue('periodicita_della_fatturazione_ee', event.target.value)} 
                    label="Periodicita Della Fatturazione" 
                        />}

                
            </div>
        </div>
    )
}

function LuceWKVoltura(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl> */}

                <TextField 
                    required
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                {/* <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl> */}

                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        /> */}
            </div>
        </div>
    )
}









function LuceESAllac(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceESPrimaAtt(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceESSubentro(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceESCambio(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceESVoltura(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}














function LuceCVAllac(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceCVPrimaAtt(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceCVSubentro(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceCVCambio(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceCVVoltura(props) {
    const { 
        data, 
        client_type, 
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}













function LuceEGAllac(props) {
    const { 
        data,
        client_type,
        operation_ee,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);
    const [data_fine_lavori, setDataFileLavori] = React.useState(data.data_fine_lavori);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />

                {operation_ee && operation_ee.name === 'EG Allacc. TEMPORANEO EE_PIVA' && <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Fine Lavori"
                    value={data_fine_lavori}
                    helperText='data_fine_lavori'
                    name='data_fine_lavori'
                    onChange={(date) => { setFieldValue('data_fine_lavori', date); setDataFileLavori(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider> }
            </div>
        </div>
    )
}


function LuceEGPrimaAtt(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceEGSubentro(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceEGCambio(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceEGVoltura(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}












function LuceILAllac(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


function LuceILPrimaAtt(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceILSubentro(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceILCambio(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState,
        setFieldValue
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    // if(!destinazione_duso && theSameAddress === 'selected') {
    //     setDestinazioneDuso('domestico_residente');
    //     setFieldValue('destinazione_duso', 'domestico_residente');
    // }

    // if(theSameAddress === 'reset') {
    //     setFieldValueState('theSameAddress', 'clean');
    //     setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    // }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_ee', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}

function LuceILVoltura(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState
    } = props;

    const [pot_imp, setImp] = React.useState(data.pot_imp || '');
    const [pot_dispo, setDispo] = React.useState(data.pot_dispo || '');
    const [tensione] = React.useState(data.tensione);
    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}
    const [destinazione_duso, setDestinazioneDuso] = React.useState(data.destinazione_duso || '');
    const [distributore_luce] = React.useState(data.distributore_luce);
    const [mercato_di_provenienza_ee] = React.useState(data.mercato_di_provenienza_ee);
    const [ex_fornitore] = React.useState(data.ex_fornitore);
    const [fasce_orarie] = React.useState(data.fasce_orarie);
    const [consumo_medio_annuo] = React.useState(data.consumo_medio_annuo);

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    const { setFieldValue } = props;

    if(!destinazione_duso && theSameAddress === 'selected') {
        setDestinazioneDuso('domestico_residente');
        setFieldValue('destinazione_duso', 'domestico_residente');
    }

    if(theSameAddress === 'reset') {
        setFieldValueState('theSameAddress', 'clean');
        setTimeout(() => {setDestinazioneDuso(''); setFieldValue('destinazione_duso', '');}, 10);
    }

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={pot_imp}
                    helperText='pot_imp'
                    name='pot_imp'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_imp', value)
                        setImp(value);

                        const calc = (Math.round((value * 1.1) * 100)/100);
                        
                        setDispo(calc);
                        setFieldValue('pot_dispo', calc);
                    }} 
                    label="Pot. Imp." 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={pot_dispo}
                    helperText='pot_dispo'
                    name='pot_dispo'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pot_dispo', value);
                        setDispo(value)
                    }} 
                    label="Pot. Dispo." 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tensione</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tensione}
                        helperText='tensione'
                    name='tensione'
                        onChange={(event, val) => { setFieldValue('tensione', event.target.value)}}
                        label="Tensione"
                        >
                        <MenuItem value='230'>230</MenuItem>
                        <MenuItem value='400'>400</MenuItem>
                    </Select>
                    <FormHelperText>tensione</FormHelperText>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Destinazione D'uso</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={destinazione_duso}
                        helperText='destinazione_duso'
                        name='destinazione_duso'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('destinazione_duso', value);
                            setDestinazioneDuso(value);
                        }}
                        label="Destinazione D'uso"
                        >
                        
                        <MenuItem value='domestico-non-residente'>Domestico Non Residente</MenuItem>
                        <MenuItem value='altri-usi'>Altri Usi</MenuItem>
                        { client_type === 'domestico' && <MenuItem value='domestico_residente'>Domestico residente</MenuItem>}
                    </Select>
                    <FormHelperText>destinazione_duso</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_luce}
                    helperText='distributore_luce'
                    name='distributore_luce'
                    onChange={event => setFieldValue('distributore_luce', event.target.value)} 
                    label="Distributore Luce" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={mercato_di_provenienza_ee}
                        helperText='mercato_di_provenienza_ee'
                    name='mercato_di_provenienza_ee'
                    onChange={event => setFieldValue('mercato_di_provenienza_ee', event.target.value)} 
                    label="Mercato Di Provenizeza" 
                        />

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_fornitore', val)}
                    options={ex_fornitore_fields}
                    value={ex_fornitore}
                    helperText='ex_fornitore'
                    name='ex_fornitore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Fornitore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_fornitore'
                        name='ex_fornitore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">fasce orarie</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={fasce_orarie}
                        helperText='fasce_orarie'
                    name='fasce_orarie'
                        onChange={(event, val) => { setFieldValue('fasce_orarie', event.target.value)}}
                        label="fasce orarie"
                        >
                        <MenuItem value='monoraria'>Monoraria</MenuItem>
                        <MenuItem value='bioraria'>Bioraria</MenuItem>
                        <MenuItem value='trioraria'>Trioraria</MenuItem>
                        <MenuItem value='peak/offpeak'>Peak/Offpeak</MenuItem>
                    </Select>
                    <FormHelperText>fasce_orarie</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_medio_annuo}
                    helperText='consumo_medio_annuo'
                    name='consumo_medio_annuo'
                    onChange={event => setFieldValue('consumo_medio_annuo', event.target.value)} 
                    label="Consumo Medio Annuo" 
                        />
            </div>
        </div>
    )
}


// GAS EI



function GasEIAllac(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}


function GasEIPrimaAtt(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasEISubentro(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasEICambio(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}

function GasEIVoltura(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}




// GAS EE





function GasEEAllac(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}


function GasEEPrimaAtt(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasEESubentro(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasEECambio(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}

function GasEEVoltura(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}





// GAS IR





function GasIRAllac(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);

    const [categoria_contatore, setCategoria] = React.useState(data.categoria_contatore || '');
    const [portata_termica_max, setPortata] = React.useState(data.portata_termica_max || '');

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Categoria Contatore</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={categoria_contatore}
                        helperText='categoria_contatore'
                    name='categoria_contatore'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setCategoria(value);
                            setFieldValue('categoria_contatore', value);

                            switch(value) {
                                case '4': 
                                    setPortata('37.8');
                                    setFieldValue('portata_termica_max', '37.8');
                                    break;
                                case '6': 
                                    setPortata('56.7');
                                    setFieldValue('portata_termica_max', '56.7');
                                    break;
                                case '10': 
                                    setPortata('94.5');
                                    setFieldValue('portata_termica_max', '94.5');
                                    break;
                                case '16': 
                                    setPortata('151.2');
                                    setFieldValue('portata_termica_max', '151.2');
                                    break;
                            }
                        }}
                        label="Categoria Contatore"
                        >
                        <MenuItem value='4'>4</MenuItem>
                        <MenuItem value='6'>6</MenuItem>
                        <MenuItem value='10'>10</MenuItem>
                        <MenuItem value='16'>16</MenuItem>
                    </Select>
                    <FormHelperText>categoria_contatore</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Portata Termica Max</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={portata_termica_max}
                        helperText='portata_termica_max'
                    name='portata_termica_max'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('portata_termica_max', value);
                            setPortata(value);
                        }}
                        label="Portata Termica Max"
                        >
                        <MenuItem value='37.8'>37.8</MenuItem>
                        <MenuItem value='56.7'>56.7</MenuItem>
                        <MenuItem value='94.5'>94.5</MenuItem>
                        <MenuItem value='151.2'>151.2</MenuItem>
                    </Select>
                    <FormHelperText>portata_termica_max</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}


function GasIRPrimaAtt(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const [matricola] = React.useState(data.matricola);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const [categoria_contatore, setCategoria] = React.useState(data.categoria_contatore || '');
    const [portata_termica_max, setPortata] = React.useState(data.portata_termica_max || '');

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Categoria Contatore</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={categoria_contatore}
                        helperText='categoria_contatore'
                    name='categoria_contatore'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setCategoria(value);
                            setFieldValue('categoria_contatore', value);

                            switch(value) {
                                case '4': 
                                    setPortata('37.8');
                                    setFieldValue('portata_termica_max', '37.8');
                                    break;
                                case '6': 
                                    setPortata('56.7');
                                    setFieldValue('portata_termica_max', '56.7');
                                    break;
                                case '10': 
                                    setPortata('94.5');
                                    setFieldValue('portata_termica_max', '94.5');
                                    break;
                                case '16': 
                                    setPortata('151.2');
                                    setFieldValue('portata_termica_max', '151.2');
                                    break;
                            }
                        }}
                        label="Categoria Contatore"
                        >
                        <MenuItem value='4'>4</MenuItem>
                        <MenuItem value='6'>6</MenuItem>
                        <MenuItem value='10'>10</MenuItem>
                        <MenuItem value='16'>16</MenuItem>
                    </Select>
                    <FormHelperText>categoria_contatore</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Portata Termica Max</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={portata_termica_max}
                        helperText='portata_termica_max'
                    name='portata_termica_max'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('portata_termica_max', value);
                            setPortata(value);
                        }}
                        label="Portata Termica Max"
                        >
                        <MenuItem value='37.8'>37.8</MenuItem>
                        <MenuItem value='56.7'>56.7</MenuItem>
                        <MenuItem value='94.5'>94.5</MenuItem>
                        <MenuItem value='151.2'>151.2</MenuItem>
                    </Select>
                    <FormHelperText>portata_termica_max</FormHelperText>
                </FormControl>
            </div>

            <div className='fields'>
                <TextField
                        required
                    className='field' 
                    defaultValue={matricola}
                    helperText='matricola'
                    name='matricola'
                    onChange={event => setFieldValue('matricola', event.target.value)} 
                    label="Matricola" 
                        />
            </div>
        </div>
    )
}

function GasIRSubentro(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const [matricola] = React.useState(data.matricola)
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const [categoria_contatore, setCategoria] = React.useState(data.categoria_contatore || '');
    const [portata_termica_max, setPortata] = React.useState(data.portata_termica_max || '');

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>

            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Categoria Contatore</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={categoria_contatore}
                        helperText='categoria_contatore'
                    name='categoria_contatore'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setCategoria(value);
                            setFieldValue('categoria_contatore', value);

                            switch(value) {
                                case '4': 
                                    setPortata('37.8');
                                    setFieldValue('portata_termica_max', '37.8');
                                    break;
                                case '6': 
                                    setPortata('56.7');
                                    setFieldValue('portata_termica_max', '56.7');
                                    break;
                                case '10': 
                                    setPortata('94.5');
                                    setFieldValue('portata_termica_max', '94.5');
                                    break;
                                case '16': 
                                    setPortata('151.2');
                                    setFieldValue('portata_termica_max', '151.2');
                                    break;
                            }
                        }}
                        label="Categoria Contatore"
                        >
                        <MenuItem value='4'>4</MenuItem>
                        <MenuItem value='6'>6</MenuItem>
                        <MenuItem value='10'>10</MenuItem>
                        <MenuItem value='16'>16</MenuItem>
                    </Select>
                    <FormHelperText>categoria_contatore</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Portata Termica Max</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={portata_termica_max}
                        helperText='portata_termica_max'
                    name='portata_termica_max'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('portata_termica_max', value);
                            setPortata(value);
                        }}
                        label="Portata Termica Max"
                        >
                        <MenuItem value='37.8'>37.8</MenuItem>
                        <MenuItem value='56.7'>56.7</MenuItem>
                        <MenuItem value='94.5'>94.5</MenuItem>
                        <MenuItem value='151.2'>151.2</MenuItem>
                    </Select>
                    <FormHelperText>portata_termica_max</FormHelperText>
                </FormControl>
            </div>

            <div className='fields'>
                <TextField 
                    className='field' 
                    defaultValue={matricola}
                    helperText='matricola'
                    name='matricola'
                    onChange={event => setFieldValue('matricola', event.target.value)} 
                    label="Matricola" 
                        />
            </div>
        </div>
    )
}

function GasIRCambio(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const [categoria_contatore, setCategoria] = React.useState(data.categoria_contatore || '');
    const [portata_termica_max, setPortata] = React.useState(data.portata_termica_max || '');

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>

            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Categoria Contatore</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={categoria_contatore}
                        helperText='categoria_contatore'
                    name='categoria_contatore'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setCategoria(value);
                            setFieldValue('categoria_contatore', value);

                            switch(value) {
                                case '4': 
                                    setPortata('37.8');
                                    setFieldValue('portata_termica_max', '37.8');
                                    break;
                                case '6': 
                                    setPortata('56.7');
                                    setFieldValue('portata_termica_max', '56.7');
                                    break;
                                case '10': 
                                    setPortata('94.5');
                                    setFieldValue('portata_termica_max', '94.5');
                                    break;
                                case '16': 
                                    setPortata('151.2');
                                    setFieldValue('portata_termica_max', '151.2');
                                    break;
                            }
                        }}
                        label="Categoria Contatore"
                        >
                        <MenuItem value='4'>4</MenuItem>
                        <MenuItem value='6'>6</MenuItem>
                        <MenuItem value='10'>10</MenuItem>
                        <MenuItem value='16'>16</MenuItem>
                    </Select>
                    <FormHelperText>categoria_contatore</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Portata Termica Max</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={portata_termica_max}
                        helperText='portata_termica_max'
                    name='portata_termica_max'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('portata_termica_max', value);
                            setPortata(value);
                        }}
                        label="Portata Termica Max"
                        >
                        <MenuItem value='37.8'>37.8</MenuItem>
                        <MenuItem value='56.7'>56.7</MenuItem>
                        <MenuItem value='94.5'>94.5</MenuItem>
                        <MenuItem value='151.2'>151.2</MenuItem>
                    </Select>
                    <FormHelperText>portata_termica_max</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}

function GasIRVoltura(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const [categoria_contatore, setCategoria] = React.useState(data.categoria_contatore || '');
    const [portata_termica_max, setPortata] = React.useState(data.portata_termica_max || '');

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Categoria Contatore</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={categoria_contatore}
                        helperText='categoria_contatore'
                    name='categoria_contatore'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setCategoria(value);
                            setFieldValue('categoria_contatore', value);

                            switch(value) {
                                case '4': 
                                    setPortata('37.8');
                                    setFieldValue('portata_termica_max', '37.8');
                                    break;
                                case '6': 
                                    setPortata('56.7');
                                    setFieldValue('portata_termica_max', '56.7');
                                    break;
                                case '10': 
                                    setPortata('94.5');
                                    setFieldValue('portata_termica_max', '94.5');
                                    break;
                                case '16': 
                                    setPortata('151.2');
                                    setFieldValue('portata_termica_max', '151.2');
                                    break;
                            }
                        }}
                        label="Categoria Contatore"
                        >
                        <MenuItem value='4'>4</MenuItem>
                        <MenuItem value='6'>6</MenuItem>
                        <MenuItem value='10'>10</MenuItem>
                        <MenuItem value='16'>16</MenuItem>
                    </Select>
                    <FormHelperText>categoria_contatore</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Portata Termica Max</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={portata_termica_max}
                        helperText='portata_termica_max'
                    name='portata_termica_max'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('portata_termica_max', value);
                            setPortata(value);
                        }}
                        label="Portata Termica Max"
                        >
                        <MenuItem value='37.8'>37.8</MenuItem>
                        <MenuItem value='56.7'>56.7</MenuItem>
                        <MenuItem value='94.5'>94.5</MenuItem>
                        <MenuItem value='151.2'>151.2</MenuItem>
                    </Select>
                    <FormHelperText>portata_termica_max</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}








// GAS SG





function GasSGAllac(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}


function GasSGPrimaAtt(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasSGSubentro(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasSGCambio(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}

function GasSGVoltura(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}





// GAS WK





function GasWKAllac(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}


function GasWKPrimaAtt(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasWKSubentro(props) {
    const { data, client_type, product_ee, product_gas } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [matricola] = React.useState(data.matricola);
    const [ammontare_carica_gas] = React.useState(data.ammontare_carica_gas);
    const [periodicita_della_fatturazione_gas] = React.useState(data.periodicita_della_fatturazione_gas);

    const ewarg = /(WK EE Evergreen|WK Gas Evergreen)/gmi.test(product_gas.product_name);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'Uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>

            <div className='fields'>
                {/* <TextField 
                    required 
                    className='field' 
                    defaultValue={matricola}
                    helperText='matricola'
                    name='matricola'
                    onChange={event => setFieldValue('matricola', event.target.value)} 
                    label="Matricola" 
                        /> */}

            {!ewarg && <TextField 
                    required 
                    className='field' 
                    defaultValue={ammontare_carica_gas}
                    helperText='ammontare_carica_gas'
                    name='ammontare_carica_gas'
                    onChange={event => setFieldValue('ammontare_carica_gas', event.target.value)} 
                    label="Ammontare Carica" 
                        />
                        }
            </div>

            <div className='fields'>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={periodicita_della_fatturazione_gas}
                    helperText='periodicita_della_fatturazione_gas'
                    name='periodicita_della_fatturazione_gas'
                    onChange={event => setFieldValue('periodicita_della_fatturazione_gas', event.target.value)} 
                    label="Periodicita Della Fatturazione" 
                        />
            </div>
        </div>
    )
}

function GasWKCambio(props) {
    const { data, client_type, product_ee, product_gas } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);
    const [ammontare_carica_gas] = React.useState(data.ammontare_carica_gas);
    const [periodicita_della_fatturazione_gas] = React.useState(data.periodicita_della_fatturazione_gas);

    const ewarg = /(WK EE Evergreen|WK Gas Evergreen)/gmi.test(product_gas.product_name);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>

            {!ewarg && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={ammontare_carica_gas}
                    helperText='ammontare_carica_gas'
                    name='ammontare_carica_gas'
                    onChange={event => setFieldValue('ammontare_carica_gas', event.target.value)} 
                    label="Ammontare Carica" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={periodicita_della_fatturazione_gas}
                    helperText='periodicita_della_fatturazione_gas'
                    name='periodicita_della_fatturazione_gas'
                    onChange={event => setFieldValue('periodicita_della_fatturazione_gas', event.target.value)} 
                    label="Periodicita Della Fatturazione" 
                        />
            </div> }
        </div>
    )
}

function GasWKVoltura(props) {
    const { data, client_type, product_ee, product_gas } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);
    const [ammontare_carica_gas] = React.useState(data.ammontare_carica_gas);
    const [periodicita_della_fatturazione_gas] = React.useState(data.periodicita_della_fatturazione_gas);

    const ewarg = /(WK EE Evergreen|WK Gas Evergreen)/gmi.test(product_gas.product_name);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
            {!ewarg && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={ammontare_carica_gas}
                    helperText='ammontare_carica_gas'
                    name='ammontare_carica_gas'
                    onChange={event => setFieldValue('ammontare_carica_gas', event.target.value)} 
                    label="Ammontare Carica" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={periodicita_della_fatturazione_gas}
                    helperText='periodicita_della_fatturazione_gas'
                    name='periodicita_della_fatturazione_gas'
                    onChange={event => setFieldValue('periodicita_della_fatturazione_gas', event.target.value)} 
                    label="Periodicita Della Fatturazione" 
                        />
            </div> }
        </div>
    )
}





// GAS ES





function GasESAllac(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}


function GasESPrimaAtt(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasESSubentro(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasESCambio(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}

function GasESVoltura(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}







// GAS CV





function GasCVAllac(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}


function GasCVPrimaAtt(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasCVSubentro(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasCVCambio(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}

function GasCVVoltura(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}






// GAS EG





function GasEGAllac(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                        name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>
            </div>
        </div>
    )
}


function GasEGPrimaAtt(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}

function GasEGSubentro(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [matricola] = React.useState(data.matricola);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>

            <div className='fields'>
                <TextField 
                        required
                    className='field' 
                    defaultValue={matricola}
                    helperText='matricola'
                    name='matricola'
                    onChange={event => setFieldValue('matricola', event.target.value)} 
                    label="Matricola" 
                        />
            </div>
        </div>
    )
}

function GasEGCambio(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}

function GasEGVoltura(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}


function GasILCambio(props) {
    const { data, client_type } = props;

    const [distributore_gas] = React.useState(data.distributore_gas);
    const [remi] = React.useState(data.remi);
    const [giorni_duso] = React.useState(data.giorni_duso || 7);
    const [consumo_annuo] = React.useState(data.consumo_annuo);
    const [uso_gas] = React.useState(data.uso_gas);
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };
    const [mercato_di_provenienza_gas] = React.useState(data.mercato_di_provenienza_gas);
    const [ex_venditore] = React.useState(data.ex_venditore);

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={distributore_gas}
                    helperText='distributore_gas'
                    name='distributore_gas'
                    onChange={event => setFieldValue('distributore_gas', event.target.value)} 
                    label="Distributore Gas" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={remi}
                    helperText='remi'
                    name='remi'
                    onChange={event => setFieldValue('remi', event.target.value)} 
                    label="REMI" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={giorni_duso}
                    helperText='giorni_duso'
                    name='giorni_duso'
                    onChange={event => setFieldValue('giorni_duso', event.target.value)} 
                    label="Giorni D'uso" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={consumo_annuo}
                    helperText='consumo_annuo'
                    name='consumo_annuo'
                    onChange={event => setFieldValue('consumo_annuo', event.target.value)} 
                    label="Consumo Annuo" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Uso Gas</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={uso_gas}
                        helperText='uso_gas'
                    name='uso_gas'
                        onChange={(event, val) => { 
                            const value = event.target.value;

                            setFieldValue('uso_gas', value);
                        }}
                        label="Uso Gas"
                        >
                        <MenuItem value='c1'>C1 Riscaldamento</MenuItem>
                        <MenuItem value='c2'>C2 Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                        <MenuItem value='c3'>C3 Riscaldamento + Uso Cottura Cibi E/O Acqua Calda</MenuItem>
                    </Select>
                    <FormHelperText>uso_gas</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Mercato Di Provenienza</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={mercato_di_provenienza_gas}
                        helperText='mercato_di_provenienza_gas'
                    name='mercato_di_provenienza_gas'
                        onChange={(event, val) => { setFieldValue('mercato_di_provenienza_gas', event.target.value)}}
                        label="Mercato Di Provenienza"
                        >
                        
                        <MenuItem value='libero'>Libero</MenuItem>
                        <MenuItem value='tutelato'>Tutelato</MenuItem>
                        <MenuItem value='di-salvaguardia'>Di salvaguardia</MenuItem>
                    </Select>
                    <FormHelperText>mercato_di_provenienza_gas</FormHelperText>
                </FormControl>

                <Autocomplete
                        required
                    freeSolo
                    className='field -select'
                    onChange={(event, val) => setFieldValue('ex_venditore', val)}
                    options={ex_fornitore_fields}
                    value={ex_venditore}
                    helperText='ex_venditore'
                    name='ex_venditore'
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Ex Venditore"
                        margin="normal"
                        variant="outlined"
                        helperText='ex_venditore'
                        name='ex_venditore'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>
        </div>
    )
}


// NE PRODUCT 

function GasNeProduct(props) {
    const { data, client_type } = props;
    const [pdr] = React.useState(data.pdr);
    const [pdrError, setErrorPdr] = React.useState(false);
    const checkPdr = (text) => { (/^[0-9]{14}$/.test(text) ? setErrorPdr(false) : setErrorPdr(true)) };

    const { setFieldValue } = props;

    return (
        <div className='section'>
            <div className='head_text' id='gas_part'>GAS</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pdr}
                    helperText='pdr'
                    name='pdr'
                    error={pdrError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pdr', value);
                        checkPdr(value);
                    }} 
                    label="PDR" 
                        />
            </div>
        </div>
    )
}



function LuceNeProduct(props) {
    const { 
        data, 
        client_type,
        theSameAddress,
        setFieldValueState,
        setFieldValue
    } = props;

    const [pod] = React.useState(data.pod);
    const [podError, setPodError] = React.useState(false);
    const checkPodError = (text) => {(/^IT[0-9]{3}E[0-9]{7}[a-zA-Z0-9]{1,2}$/.test(text)) ? setPodError(false) : setPodError(true)}

    return (
        <div className='section'>
            <div className='head_text' id='luce_part'>LUCE</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={pod}
                    helperText='pod'
                    name='pod'
                    error={podError}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('pod', value);
                        checkPodError(value);
                    }} 
                    label="POD" 
                        />
            </div>
        </div>
    )
}





const showNeProduct = (type_contract, product) => {
    const productReg = new RegExp(`^NE`, 'gmi');

    return (
        type_contract === 'contratto energia'
        && productReg.test(product.product_name)
    ) ? true : false;
}










// NE PRODUCT


const showGas = (payload, starts, contains) => {
    const {
        type_contract,
        type_product,
        product_gas,
        operation_gas
    } = payload;

    const contain = contains !== 'Subentro' ? contains : 'Subentro|Sub'

    const productReg = new RegExp(`^${starts}`, 'gmi');
    const operationReg = new RegExp(`${contain}`, 'gmi');
    const voltura = (contains === 'Cambio') ? new RegExp(`voltura`, 'gmi') : null

    return (
        type_contract === 'contratto energia'
        && (type_product === 'gas' || type_product === 'dual')
        && productReg.test(product_gas.product_name)
        && operationReg.test(operation_gas.name)
        && (!voltura ? true : !voltura.test(operation_gas.name))
    ) ? true : false;
}


function EnergiaVoltura(props) {
    const { 
        data, 
        product_ee,
        product_gas,
        setFieldValue,
        setFieldValueState,
        operation_gas,
        operation_ee,
        client_type
    } = props;

    const [cognome_prec_intestatario] = React.useState(data.cognome_prec_intestatario);
    const [nome_prec_intestatario] = React.useState(data.nome_prec_intestatario);
    const [cf_prec_intestatario] = React.useState(data.cf_prec_intestatario);

    const [data_di_nascita_intestatario, setDataDiNascita] = React.useState(data.data_di_nascita_intestatario);
    const [luogo_de_nascita_prec_intestatario] = React.useState(data.luogo_de_nascita_prec_intestatario);
    const [provincia_di_nascita_prec_intestatario] = React.useState(data.provincia_di_nascita_prec_intestatario);
    const [comune_resid_prec_intestatario] = React.useState(data.comune_resid_prec_intestatario);
    const [provincia_prec_intestatario] = React.useState(data.provincia_prec_intestatario);
    const [num_doc_inditita_prec_intestatario] = React.useState(data.num_doc_inditita_prec_intestatario);
    const [codice_cliente_prec_intestatario] = React.useState(data.codice_cliente_prec_intestatario);
    const [codice_cliente_prec_intestatario_ee] = React.useState(data.codice_cliente_prec_intestatario_ee);
    const [codice_cliente_prec_intestatario_gas] = React.useState(data.codice_cliente_prec_intestatario_gas);

    const [tipologia_voltura, setTipologia] = React.useState(data.tipologia_voltura || '');
    const [data_decorrenza_del_titolo_di_occupazione_dellimmobile, setDataDeCorrenza] = React.useState(data.data_decorrenza_del_titolo_di_occupazione_dellimmobile || null);
    const [data_decesso_prec_intestatario, setDataDecesso] = React.useState(data.data_decesso_prec_intestatario || null);
    const [grado_parentela] = React.useState(data.grado_parentela || '');

    const operationReg = new RegExp(/cambio\+voltura/, 'gmi');

    const showGasAndLuce = (/cambio\+voltura/gmi.test(operation_gas.name) && /cambio\+voltura/gmi.test(operation_ee.name))
    const showLuceAndIR = (/cambio\+voltura/gmi.test(operation_ee.name) && /^IR|^GN/gmi.test(product_ee.product_name));
    const showLuceGASAndWK = (/cambio\+voltura/gmi.test(operation_ee.name) || /cambio\+voltura/gmi.test(operation_gas.name) && /^WK/gmi.test(product_ee.product_name) || /^WK/gmi.test(product_gas.product_name));

    // console.log('showGasAndLuce', showGasAndLuce, /cambio\+voltura/gmi.test(operation_gas.name), /cambio\+voltura/gmi.test(operation_ee.name), operation_ee.name)
    
    return (
        showLuceAndIR ? (<div className='section'>
            <div className='head_text'>Voltura</div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipologia Voltura</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipologia_voltura}
                        helperText='tipologia_voltura'
                        name='tipologia_voltura'
                        onChange={(event, val) => {
                            const value = event.target.value;
                            setFieldValue('tipologia_voltura', value);
                            setTipologia(value);
                        }}
                        label="Tipologia Voltura"
                        >
                            <MenuItem value='Ordinaria'>Ordinaria</MenuItem>
                            {client_type !== 'business' && (!/(^GN)/gi.test(product_ee.product_name || '') || !/(^GN)/gi.test(product_gas.product_name || '')) && <MenuItem value='Mortis Causa'>Mortis Causa</MenuItem> }
                        </Select>
                    <FormHelperText>tipologia_voltura</FormHelperText>
                </FormControl>
            </div>

            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cognome_prec_intestatario}
                    helperText='cognome_prec_intestatario'
                    name='cognome_prec_intestatario'
                    onChange={event => setFieldValue('cognome_prec_intestatario', event.target.value)} 
                    label="Cognome Prec. Intestatario" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={nome_prec_intestatario}
                    helperText='nome_prec_intestatario'
                    name='nome_prec_intestatario'
                    onChange={event => setFieldValue('nome_prec_intestatario', event.target.value)} 
                    label="Nome Prec. Intestatario" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cf_prec_intestatario}
                    helperText='cf_prec_intestatario'
                    name='cf_prec_intestatario'
                    onChange={event => setFieldValue('cf_prec_intestatario', event.target.value)} 
                    label="CF Prec. Intestatario" 
                        />
            </div>

            { tipologia_voltura == 'Ordinaria' && <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data decorrenza del titolo di occupazione dell’immobile"
                    value={data_decorrenza_del_titolo_di_occupazione_dellimmobile}
                    helperText='data_decorrenza_del_titolo_di_occupazione_dellimmobile'
                    name='data_decorrenza_del_titolo_di_occupazione_dellimmobile'
                    onChange={(date) => { setFieldValue('data_decorrenza_del_titolo_di_occupazione_dellimmobile', date); setDataDeCorrenza(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </MuiPickersUtilsProvider>
            </div> }


            {/* { tipologia_voltura == 'Mortis Causa' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cognome_prec_intestatario}
                    helperText='cognome_prec_intestatario'
                    name='cognome_prec_intestatario'
                    onChange={event => setFieldValue('cognome_prec_intestatario', event.target.value)} 
                    label="Cognome Prec. Intestatario" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={nome_prec_intestatario}
                    helperText='nome_prec_intestatario'
                    name='nome_prec_intestatario'
                    onChange={event => setFieldValue('nome_prec_intestatario', event.target.value)} 
                    label="Nome Prec. Intestatario" 
                        />
            </div> } */}

            { tipologia_voltura == 'Mortis Causa' && <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data di nascita Prec. Intestatario"
                    value={data_di_nascita_intestatario}
                    helperText='data_di_nascita_intestatario'
                    name='data_di_nascita_intestatario'
                    onChange={(date) => { setFieldValue('data_di_nascita_intestatario', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </MuiPickersUtilsProvider>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={luogo_de_nascita_prec_intestatario}
                    helperText='luogo_de_nascita_prec_intestatario'
                    name='luogo_de_nascita_prec_intestatario'
                    onChange={event => setFieldValue('luogo_de_nascita_prec_intestatario', event.target.value)} 
                    label="Luogo di nascita Prec. Intestatario" 
                        />
            </div> }

            { tipologia_voltura == 'Mortis Causa' && <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data decesso Prec. Intestatario"
                    value={data_decesso_prec_intestatario}
                    helperText='data_decesso_prec_intestatario'
                    name='data_decesso_prec_intestatario'
                    onChange={(date) => { setFieldValue('data_decesso_prec_intestatario', date); setDataDecesso(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </MuiPickersUtilsProvider>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Grado parentela</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={grado_parentela}
                        helperText='grado_parentela'
                        name='grado_parentela'
                        onChange={(event, val) => {
                            const value = event.target.value

                            setFieldValue('grado_parentela', value);
                        }}
                        label="Grado parentela"
                        >
                            <MenuItem value='Coniuge'>Coniuge</MenuItem>
                            <MenuItem value='Familiare'>Familiare</MenuItem>
                            <MenuItem value='Convivente'>Convivente</MenuItem>
                        </Select>
                    <FormHelperText>grado_parentela</FormHelperText>
                </FormControl>
            </div>}

            
        </div>) : (<div className='section'>
            <div className='head_text'>Voltura</div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cognome_prec_intestatario}
                    helperText='cognome_prec_intestatario'
                    name='cognome_prec_intestatario'
                    onChange={event => setFieldValue('cognome_prec_intestatario', event.target.value)} 
                    label="Cognome Prec. Intestatario" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={nome_prec_intestatario}
                    helperText='nome_prec_intestatario'
                    name='nome_prec_intestatario'
                    onChange={event => setFieldValue('nome_prec_intestatario', event.target.value)} 
                    label="Nome Prec. Intestatario" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={cf_prec_intestatario}
                    helperText='cf_prec_intestatario'
                    name='cf_prec_intestatario'
                    onChange={event => setFieldValue('cf_prec_intestatario', event.target.value)} 
                    label="CF Prec. Intestatario" 
                        />
            </div>

            { !showLuceGASAndWK && <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data di nascita Prec. Intestatario"
                    value={data_di_nascita_intestatario}
                    helperText='data_di_nascita_intestatario'
                    name='data_di_nascita_intestatario'
                    onChange={(date) => { setFieldValue('data_di_nascita_intestatario', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    />
                </MuiPickersUtilsProvider>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={luogo_de_nascita_prec_intestatario}
                    helperText='luogo_de_nascita_prec_intestatario'
                    name='luogo_de_nascita_prec_intestatario'
                    onChange={event => setFieldValue('luogo_de_nascita_prec_intestatario', event.target.value)} 
                    label="Luogo di nascita Prec. Intestatario" 
                        />
            </div>}

            { !showLuceGASAndWK && <div className='fields'>
                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_di_nascita_prec_intestatario', val)}
                    options={cities}
                    value={provincia_di_nascita_prec_intestatario}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_di_nascita_prec_intestatario'
                        name='provincia_di_nascita_prec_intestatario'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={comune_resid_prec_intestatario}
                    helperText='comune_resid_prec_intestatario'
                    name='comune_resid_prec_intestatario'
                    onChange={event => setFieldValue('comune_resid_prec_intestatario', event.target.value)} 
                    label="Comune resid Prec. Intestatario" 
                        />
            </div>}

            { !showLuceGASAndWK && <div className='fields'>

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_prec_intestatario', val)}
                    options={cities}
                    value={provincia_prec_intestatario}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_prec_intestatario'
                        name='provincia_prec_intestatario'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={num_doc_inditita_prec_intestatario}
                    helperText='num_doc_inditita_prec_intestatario'
                    name='num_doc_inditita_prec_intestatario'
                    onChange={event => setFieldValue('num_doc_inditita_prec_intestatario', event.target.value)} 
                    label="Num Doc identità Prec. Intestatario" 
                        />
            </div>}

            {!showGasAndLuce && !showLuceGASAndWK && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={codice_cliente_prec_intestatario}
                    helperText='codice_cliente_prec_intestatario'
                    name='codice_cliente_prec_intestatario'
                    onChange={event => setFieldValue('codice_cliente_prec_intestatario', event.target.value)} 
                    label="Codice cliente Prec. Intestatario" 
                        />
            </div>}

            {showGasAndLuce && !showLuceGASAndWK && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={codice_cliente_prec_intestatario_ee}
                    helperText='codice_cliente_prec_intestatario_ee'
                    name='codice_cliente_prec_intestatario_ee'
                    onChange={event => setFieldValue('codice_cliente_prec_intestatario_ee', event.target.value)} 
                    label="Codice cliente Prec. Intestatario EE" 
                        />

                <TextField 
                    required 
                    className='field' 
                    defaultValue={codice_cliente_prec_intestatario_gas}
                    helperText='codice_cliente_prec_intestatario_gas'
                    name='codice_cliente_prec_intestatario_gas'
                    onChange={event => setFieldValue('codice_cliente_prec_intestatario_gas', event.target.value)} 
                    label="Codice cliente Prec. Intestatario GAS" 
                        />
            </div>}
        </div>)
    )
}

const showEnergiaVoltura = (payload) => {
    const {
        type_contract,
        type_product,
        operation_gas,
        operation_ee
    } = payload;

    const operationReg = new RegExp(/cambio\+voltura/, 'gmi');

    return (
        type_contract === 'contratto energia'
        && type_product
        && (operationReg.test(operation_gas.name) || operationReg.test(operation_ee.name))
    ) ? true : false;
}


function EnergiaPagamento(props) {
    const { 
        data, 
        setFieldValue,
        setFieldValueState,
        product_ee,
        product_gas,
        operation_ee,
        operation_gas,
        client_type
    } = props;

    const show = {
        bolletino: false,
        bonifico: false,
        solo_email: false,
        altro_indirizzo: false,
        online: false,
        firma_elettronica: false,
        email: false,
        cartacea: false,
        address: false
    };

    const regExpEi = new RegExp(/^EI|^EE|^ES|^AA|^SG|^IL|^EG|^GN|^OP/gmi);
    const regExpCV = new RegExp(/^CV/gmi);
    const regExpSGWK = new RegExp(/^SG|^WK/gmi);

    const regExpOnline = new RegExp(/^NE|^EI|^EE|^SG|^ES|^AA|^WK|^EG|^GN/gmi);
    const regExpOnlineIR = new RegExp(/^IR|^GN/gmi);
    const regExpOnlineCambio = new RegExp(/cambio/gmi);
    const regExpOnlineCambioVoltura = new RegExp(/cambio\+voltura/gmi);

    const regExpFirma = new RegExp(/^IR|^EG|^CV|^WK|^OP|^GN|^FW/gmi);
    const regExpEmail = new RegExp(/^IR|^EG|^IL|^CV|^WK|^FW/gmi);
    const regExpCartecea = new RegExp(/^IL|^WK/gmi);

    if(regExpEi.test(product_ee.product_name) || regExpEi.test(product_gas.product_name))
        show.bolletino = true;

    if(regExpCV.test(product_ee.product_name) || regExpCV.test(product_gas.product_name))
        show.bonifico = true;

    if(regExpSGWK.test(product_ee.product_name) || regExpSGWK.test(product_gas.product_name))
        show.solo_email = true;

    if((regExpOnline.test(product_ee.product_name) || regExpOnline.test(product_gas.product_name)) || ((regExpOnlineIR.test(product_ee.product_name) || regExpOnlineIR.test(product_gas.product_name)) && ((regExpOnlineCambio.test(operation_ee.name) && !regExpOnlineCambioVoltura.test(operation_ee.name)) || (regExpOnlineCambio.test(operation_gas.name) && !regExpOnlineCambioVoltura.test(operation_gas.name)))))
        show.online = true;

    if(regExpFirma.test(product_ee.product_name) || regExpFirma.test(product_gas.product_name))
        show.firma_elettronica = true;

    if(regExpEmail.test(product_ee.product_name) || regExpEmail.test(product_gas.product_name))
        show.email = true;

    if(regExpCartecea.test(product_ee.product_name) || regExpCartecea.test(product_gas.product_name)) {
        show.cartacea = true;
    }

    const hideNeEE = (product_ee && product_ee.product_name) ? /^NE|^GN/gmi.test(product_ee.product_name) : false;
    const hideNeGAS = (product_gas && product_gas.product_name) ? /^NE|^GN/gmi.test(product_gas.product_name) : false;


    const [tipo_di_pagamento, setPagamento] = React.useState(data.tipo_di_pagamento);
    const [tipo_di_fatturazione, setFatturazione] = React.useState(data.tipo_di_fatturazione);
    const [ricezione_del_plico_contrattuale, setContrattuale] = React.useState(data.ricezione_del_plico_contrattuale);

    const [iban, setIban] = React.useState(data.iban || '');
    const [bic_swift, setBic] = React.useState(data.bic_swift || '');
    const [iban_cognome, setLast] = React.useState(data.iban_cognome || '');
    const [iban_nome, setName] = React.useState(data.iban_nome || '');
    const [iban_codice_fiscale, setFisc] = React.useState(data.iban_codice_fiscale || '');
    const [errorCodice, setErrorCodice] = React.useState(false);

    const [email_di_fatturazione, setEmailFat] = React.useState(data.email_di_fatturazione || '');

    const [tipo_indirizzo_pagamento, setTipoIndi] = React.useState(data.tipo_indirizzo_pagamento || '');
    const [indirizzo_pagamento, setIndi] = React.useState(data.indirizzo_pagamento || '');
    const [civico_pagamento, setCivico] = React.useState(data.civico_pagamento || '');
    const [cap_pagamento, setCap] = React.useState(data.cap_pagamento || '');
    const [comune_pagamento, setComune] = React.useState(data.comune_pagamento || '');
    const [provincia_pagamento, setProv] = React.useState(data.provincia_pagamento || '');

    const [intestatario_iban, setIntesIban] = React.useState(data.intestatario_iban || 'azienda');
    const [iban_ragione_sociale, setIbanRagSoc] = React.useState(data.iban_ragione_sociale || '');

    const [addressFields, showAddressFields] = React.useState(false);

    const [errorIban, setError] = React.useState(false);
    const [errorSwift, setErrorSwift] = React.useState(false);
    const [ibanStatus, setIbanStatus] = React.useState('not checked');
    const [ibanBankName, setIbanBankName] = React.useState('');

    const checkSwift = (swift) => {
        if(swift.length < 4)
            return;

        // setErrorSwift(bic.isValid(swift));
    }

    const verifyIbanCode = (iban) => {

        if(!/^([0-9a-zA-Z])*$/.test(iban)) {
            setIbanStatus('not checked');
            return setError(true)
        }
       
        const payload = {
            code: iban
        }

        setIbanStatus('not checked');

        service.verifyIban(payload).then(resp => {
            const { ok, bank } = resp.data;

            if(ok) {
                setIbanStatus('iban correto');
                setIbanBankName(bank);
            } else {
                setIbanStatus('errore iban');
            }
        }).catch(err => {
            props.openNotification({ notificationText: 'Cannot check the IBAN!', notificationType: 'success' });
        })
    }

    const setIbanInfo = () => {
        const {
            name,
            nome_referente,
            cognome_referente,
            last_name,
            codice_fiscale,
            codice_fiscale_referente,
            email,
            ragione_sociale
        } = data;

        setLast(last_name || cognome_referente || '');
        setName(name || nome_referente || '');
        setFisc(codice_fiscale || codice_fiscale_referente || '');
        setEmailFat(email || '');
        setIbanRagSoc(ragione_sociale || '');

        console.log(codice_fiscale_referente, codice_fiscale, 'CODICE');

        setFieldValue('iban_cognome', last_name || cognome_referente || '');
        setFieldValue('iban_nome', name || nome_referente || '');
        setFieldValue('iban_codice_fiscale', codice_fiscale || codice_fiscale_referente);
        setFieldValue('email_di_fatturazione', email || '');
        setFieldValue('iban_ragione_sociale', ragione_sociale || '');
    }


    const setTheSameResidenza = () => {
        const {
            tipo_indirizzo_residenza,
            indirizzo_residenza,
            civico_residenza,
            cap_residenza,
            comune_residenza,
            provincia_residenza
        } = props.data;

        setTipoIndi(tipo_indirizzo_residenza);
        setIndi(indirizzo_residenza);
        setCivico(civico_residenza);
        setCap(cap_residenza);
        setComune(comune_residenza);
        setProv(provincia_residenza);

        setFieldValue('tipo_indirizzo_pagamento', tipo_indirizzo_residenza);
        setFieldValue('indirizzo_pagamento', indirizzo_residenza);
        setFieldValue('civico_pagamento', civico_residenza);
        setFieldValue('cap_pagamento', cap_residenza);
        setFieldValue('comune_pagamento', comune_residenza);
        setFieldValue('provincia_pagamento', provincia_residenza);
    }

    const setTheSamePunto = () => {
        const {
            tipo_indirizzo_punto = '',
            indirizzo_punto = '',
            civico_punto = '',
            cap_punto = '',
            comune_punto = '',
            provincia_punto = '',
            localita_punto = ''
        } = props.data;

        setTipoIndi(tipo_indirizzo_punto);
        setIndi(indirizzo_punto);
        setCivico(civico_punto);
        setCap(cap_punto);
        setComune(comune_punto);
        setProv(provincia_punto);

        setFieldValue('tipo_indirizzo_pagamento', tipo_indirizzo_punto);
        setFieldValue('indirizzo_pagamento', indirizzo_punto);
        setFieldValue('civico_pagamento', civico_punto);
        setFieldValue('cap_pagamento', cap_punto);
        setFieldValue('comune_pagamento', comune_punto);
        setFieldValue('provincia_pagamento', provincia_punto);
    }
    
    
    return (
        <div className='section'>
            <div className='head_text' id='product_type_part'>Pagamento E Fatturazione</div>
            {/* <div className='top_actions'>
                <div className='action' onClick={setIbanInfo}>Set the same as Residenza</div> 
            </div> */}
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Di Pagamento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipo_di_pagamento}
                        helperText='tipo_di_pagamento'
                    name='tipo_di_pagamento'
                        onChange={(event, val) => {
                            setFieldValue('tipo_di_pagamento', event.target.value);
                            setFieldValueState('tipo_di_pagamento', event.target.value);
                            setPagamento(event.target.value);
                        }}
                        label="Tipo Di Pagamento"
                        >
                        <MenuItem value='rid'>Rid</MenuItem>
                        {show.bolletino && <MenuItem value='bollettino'>Bollettino</MenuItem>}
                        {show.bonifico && <MenuItem value='bonifico'>Bonifico</MenuItem>}
                    </Select>
                    <FormHelperText>tipo_di_pagamento</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Di Fatturazione</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={tipo_di_fatturazione}
                        helperText='tipo_di_fatturazione'
                        name='tipo_di_fatturazione'
                        onChange={(event, val) => {
                            setFieldValue('tipo_di_fatturazione', event.target.value);
                            setFieldValueState('tipo_di_fatturazione', event.target.value);
                            setFatturazione(event.target.value);
                        }}
                        label="Tipo Di Fatturazione"
                        >
                            {/* show.solo_email &&  */}
                        <MenuItem value='solo e-email'>Solo e-mail</MenuItem>
                        { !(hideNeEE || hideNeGAS) && <MenuItem value='cartacea'>Cartacea</MenuItem> }
                    </Select>
                    <FormHelperText>tipo_di_fatturazione</FormHelperText>
                </FormControl>

                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Ricezione Del Plico Contrattuale</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={ricezione_del_plico_contrattuale}
                        helperText='ricezione_del_plico_contrattuale'
                        name='ricezione_del_plico_contrattuale'
                        onChange={(event, val) => {
                            setFieldValue('ricezione_del_plico_contrattuale', event.target.value)
                            setFieldValueState('ricezione_del_plico_contrattuale', event.target.value);
                            setContrattuale(event.target.value);
                        }}
                        label="Ricezione Del Plico Contrattuale"
                        >
                        {show.online &&  <MenuItem value='online'>Online</MenuItem>}
                        {show.firma_elettronica && <MenuItem value='firma elettonica'>Firma Elettronica</MenuItem>}
                        {show.email && <MenuItem value='e-mail'>E-MAIL</MenuItem>}
                        {show.cartacea && <MenuItem value='cartacea residenza'>Cartacea Residenza</MenuItem>}
                        {show.cartacea && <MenuItem value='cartacea fornitura'>Cartacea Fornitura</MenuItem>}
                    </Select>
                    <FormHelperText>ricezione_del_plico_contrattuale</FormHelperText>
                </FormControl>
            </div>

            

            <div className='actions'>
                <div className='action' onClick={setIbanInfo}>Set Nome, Cognome, Codice Fiscale As Above</div>
            </div>

            {client_type === 'business' && tipo_di_pagamento === 'rid' && <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Intestatario IBAN</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={intestatario_iban}
                        helperText='intestatario_iban'
                        name='intestatario_iban'
                        onChange={(event, val) => {
                            const value = event.target.value;

                            setFieldValue('intestatario_iban', value)
                            setIntesIban(value);
                        }}
                        label="Intestatario IBAN"
                        >
                        <MenuItem value='azienda'>Azienda</MenuItem>
                        <MenuItem value='rappresentante'>Rappresentante</MenuItem>
                    </Select>
                    <FormHelperText>intestatario_iban</FormHelperText>
                </FormControl>
            </div> }

            { tipo_di_pagamento === 'rid' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban}
                    helperText='iban'
                    name='iban'
                    error={errorIban}
                    inputProps={{ pattern: "^.{1,34}$", title: "MAX: 34 digits" }}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban', value);
                        setIban(value);

                        setError(!IBAN.isValid(value));
                        IBAN.isValid(value) ? verifyIbanCode(value) : setIbanStatus('not checked');
                    }} 
                    label="IBAN" 
                        />
                { iban.length > 2 && (iban[0] + iban[1]) != 'IT' ? <TextField 
                    className='field'
                    required
                    error={errorSwift}
                    value={bic_swift}
                    helperText='bic_swift'
                    name='bic_swift'
                    inputProps={{ pattern: "^.{1,11}$", title: "MAX: 11 digits" }}
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('bic_swift', value);
                        setBic(value);

                        checkSwift(value);
                    }} 
                    label="BIC-Swift (banche estere)" 
                        />
                    : 

                    <TextField 
                        className='field'
                        error={errorSwift}
                        value={bic_swift}
                        helperText='bic_swift'
                        name='bic_swift'
                        inputProps={{ pattern: "^.{1,11}$", title: "MAX: 11 digits" }}
                        onChange={event => {
                            const value = event.target.value;

                            setFieldValue('bic_swift', value);
                            setBic(value);

                            checkSwift(value);
                        }} 
                        label="BIC-Swift (banche estere)" 
                            />
                    }
            </div>}

            {(iban[0] == 'I'|| iban[0] == 'i' && iban[1] == 'T' || iban[1] == 't') && iban.length != 27 && ibanStatus !== 'iban correto' && <div className='add_info'>
                <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN non corretto</div>
                    </div>
                </div>
            </div>}

            {ibanStatus !== 'not checked' && <div className='add_info'>
                {ibanStatus === 'iban correto' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>IBAN Correto</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{iban.slice(0, 2)}</div>
                    </div>
                    <div className='iban_item'>
                        <div className='icon'>✓</div>
                        <div className='text'>{ibanBankName}</div>
                    </div>
                </div>}

                {ibanStatus === 'errore iban' && <div className='list_ibans'>
                    <div className='iban_item'>
                        <div className='icon -error'>Х</div>
                        <div className='text'>IBAN straniero, inserire BIC</div>
                    </div>
                </div>}
            </div>}

            { tipo_di_pagamento === 'rid' && client_type === 'domestico' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_nome}
                    helperText='iban_nome'
                    name='iban_nome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_nome', value);
                        setName(value);
                    }} 
                    label="IBAN Nome" 
                        />
                <TextField 
                    className='field' 
                    value={iban_cognome}
                    helperText='iban_cognome'
                    name='iban_cognome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_cognome', value);
                        setLast(value)
                    }} 
                    label="IBAN Cognome" 
                        />
            </div>}

            { tipo_di_pagamento === 'rid' && client_type === 'business' && intestatario_iban === 'rappresentante' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_nome}
                    helperText='iban_nome'
                    name='iban_nome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_nome', value);
                        setName(value);
                    }} 
                    label="IBAN Nome" 
                        />
                <TextField 
                    className='field' 
                    value={iban_cognome}
                    helperText='iban_cognome'
                    name='iban_cognome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_cognome', value);
                        setLast(value)
                    }} 
                    label="IBAN Cognome" 
                        />
            </div>}

            { tipo_di_pagamento === 'rid' && client_type === 'business' && intestatario_iban === 'azienda' && intestatario_iban === 'azienda' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_ragione_sociale} 
                    helperText='iban_ragione_sociale'
                    name='iban_ragione_sociale'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_ragione_sociale', value);
                        setIbanRagSoc(value);
                    }} 
                    label="IBAN Ragione Sociale"
                        />
            </div>}

            { tipo_di_pagamento === 'rid' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={iban_codice_fiscale}
                    helperText='iban_codice_fiscale'
                    name='iban_codice_fiscale'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('iban_codice_fiscale', value);
                        setFisc(value);
                    }} 
                    label="IBAN Codice Fiscale"
                        />
            </div>}

            { tipo_di_fatturazione === 'solo e-email' && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={email_di_fatturazione}
                    helperText='email_di_fatturazione'
                    name='email_di_fatturazione'
                    type='email'
                    onChange={event => { setFieldValue('email_di_fatturazione', event.target.value); setEmailFat(event.target.value)}} 
                    label="E-MAIL DI FATTURAZIONE" 
                        />
            </div>}
            {/* { tipo_di_fatturazione === 'cortacea' && <div className='actions'>
                <div class='action' onClick={setAsResidenza}>Set some as residenza</div> 
                <div class='action' onClick={setAsPunto}>Set some as punto di fornitura</div> 
            </div>} */}

            <div className='actions'>
                { tipo_di_fatturazione === 'cartacea' && <div className='action' onClick={setTheSameResidenza}>Set same as residenza</div>}
                { tipo_di_fatturazione === 'cartacea' && <div className='action' onClick={() => showAddressFields(!addressFields)}>Altro Indirizzo</div>}
                { tipo_di_fatturazione === 'cartacea' && <div className='action' onClick={setTheSamePunto}>Set same as punto di fornitura</div>}
            </div>

            {(tipo_di_fatturazione === 'cartacea' && addressFields) && <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    value={tipo_indirizzo_pagamento}
                    helperText='tipo_indirizzo_pagamento'
                    name='tipo_indirizzo_pagamento'
                    onChange={event => {
                        setFieldValue('tipo_indirizzo_pagamento', event.target.value);
                        setTipoIndi(event.target.value);
                    }} 
                    label="Tipo Di Indirizzo" 
                        />

                <TextField 
                    required 
                    className='field' 
                    value={indirizzo_pagamento}
                    helperText='indirizzo_pagamento'
                    name='indirizzo_pagamento'
                    onChange={event => {
                        setFieldValue('indirizzo_pagamento', event.target.value);
                        setIndi(event.target.value);
                    }} 
                    label="Indirizzo" 
                        />
            </div>}
            {(tipo_di_fatturazione === 'cartacea' && addressFields) && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={civico_pagamento}
                    helperText='civico_pagamento'
                    name='civico_pagamento'
                    onChange={event => {
                        setFieldValue('civico_pagamento', event.target.value);
                        setCivico(event.target.value);
                    }} 
                    label="Civico" 
                        />
                <TextField 
                    required 
                    className='field' 
                    value={cap_pagamento}
                    helperText='cap_pagamento'
                    name='cap_pagamento'
                    onChange={event => {
                        setFieldValue('cap_pagamento', event.target.value);
                        setCap(event.target.value);
                    }} 
                    label="Cap" 
                        />
            </div>}
            {(tipo_di_fatturazione === 'cartacea' && addressFields) && <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    value={comune_pagamento}
                    helperText='comune_pagamento'
                    name='comune_pagamento'
                    onChange={event => {
                        setFieldValue('comune_pagamento', event.target.value);
                        setComune(event.target.value);
                    }} 
                    label="Comune" 
                        />

                <Autocomplete
                    freeSolo
                    required
                    className='field -crm'
                    id="free-solo-2-demo"
                    onChange={(event, val) => setFieldValue('provincia_pagamento', val)}
                    options={cities}
                    value={provincia_pagamento}
                    getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} (${option.code})`)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Provincia Residenza"
                        margin="normal"
                        required
                        // variant="outlined"
                        helperText='provincia_pagamento'
                        name='provincia_pagamento'
                        InputProps={{ ...params.InputProps }}
                    />
                    )}
                />
            </div>}
        </div>
    )
}

const showEnergiaPagamento = (payload) => {
    const {
        type_contract,
        type_product,
        operation_gas,
        operation_ee,
        product_ee,
        product_gas
    } = payload;

    return (
        type_contract === 'contratto energia'
        && type_product
        && (( operation_gas && operation_gas.name) || (operation_ee && operation_ee.name))
        && (product_ee.product_name || product_gas.product_name)
    ) ? true : false;
}


function EnergiaDelegato(props) {
    const { setFieldValue, data } = props;

    const [qualita_delegato] = React.useState(data.qualita_delegato);
    const [cognome_delegato] = React.useState(data.cognome_delegato);
    const [nome_delegato] = React.useState(data.nome_delegato);
    const [cf_delegato] = React.useState(data.cf_delegato);
    const [tipo_di_documento_delegato] = React.useState(data.tipo_di_documento_delegato);
    const [numero_del_documento_delegato] = React.useState(data.numero_del_documento_delegato);
    const [data_di_scadenza_delegato, setDataDiNascita] = React.useState(data.data_di_scadenza_delegato);

    return (
        <div className='section'>
            <div className='head_text'>Delegato</div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Qualità Delegato</InputLabel>
                    <Select
                        labelId="Qualità Delegato"
                        id="demo-simple-select-outlined"
                        defaultValue={qualita_delegato}
                        helperText='qualita_delegato'
                    name='qualita_delegato'
                        onChange={(event) => {

                            setFieldValue('qualita_delegato', event.target.value);
                        }}
                        label="Qualità Delegato"
                        >
                        <MenuItem value='referente-aziendale'>Referente Aziendale</MenuItem>
                        <MenuItem value='moglie'>Moglie</MenuItem>
                        <MenuItem value='marito'>Marito</MenuItem>
                        <MenuItem value='figlio'>Figlio</MenuItem>
                        <MenuItem value='figlia'>Figlia</MenuItem>
                        <MenuItem value='genitore'>Genitore</MenuItem>
                    </Select>
                    <FormHelperText>qualita_delegato</FormHelperText>
                </FormControl>

                <TextField 
                    required 
                    className='field'
                    defaultValue={cognome_delegato}
                    helperText='cognome_delegato' 
                    name='cognome_delegato' 
                    onChange={event => setFieldValue('cognome_delegato', event.target.value)} 
                    label="Cognome Delegato" 
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field'
                    defaultValue={nome_delegato}
                    helperText='nome_delegato' 
                    name='nome_delegato' 
                    onChange={event => setFieldValue('nome_delegato', event.target.value)} 
                    label="Nome Delegato" 
                        />

                <TextField 
                    required 
                    className='field'
                    defaultValue={cf_delegato}
                    helperText='cf_delegato'
                    name='cf_delegato'
                    onChange={event => setFieldValue('cf_delegato', event.target.value)} 
                    label="CF Delegato" 
                        />
            </div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Di Documento</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_di_documento_delegato}
                        helperText='tipo_di_documento_delegato'
                    name='tipo_di_documento_delegato'
                        onChange={(event, val) => { setFieldValue('tipo_di_documento_delegato', event.target.value) }}
                        label="Tipo Di Documento"
                        >
                        <MenuItem value='c. identita'>C. Identità</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                    </Select>
                </FormControl>

                <TextField 
                    required 
                    className='field'
                    defaultValue={numero_del_documento_delegato}
                    helperText='numero_del_documento_delegato'
                    name='numero_del_documento_delegato'
                    onChange={event => setFieldValue('numero_del_documento_delegato', event.target.value)} 
                    label="Numero Del Documento" 
                        />
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di scadenza"
                    value={data_di_scadenza_delegato}
                    helperText='data_di_scadenza_delegato'
                    name='data_di_scadenza_delegato'
                    onChange={(date) => { setFieldValue('data_di_scadenza_delegato', date); setDataDiNascita(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
        </div>
    )
}

const showEnergiaDelegato = (payload) => {
    const {
        type_contract,
        type_product,
        operation_gas,
        operation_ee,
        product_ee,
        product_gas,
    } = payload;

    return (
        type_contract === 'contratto energia'
        && type_product
        && (( operation_gas && operation_gas.name) || (operation_ee && operation_ee.name))
        && (product_ee.product_name || product_gas.product_name)
        && (!/(^GN)/gi.test(product_ee.product_name) || !/(^GN)/gi.test(product_gas.product_name))
    ) ? true : false;
}

function EnergiaDocumento(props) {
    const { 
        data, 
        setFieldValue,
        setFieldValueState,
        operation
    } = props;

    const [tipo_di_documento] = React.useState(data.tipo_di_documento);
    const [numero_del_documento] = React.useState(data.numero_del_documento);
    const [data_di_rilascio_del_documento, setDataDiRil] = React.useState(data.data_di_rilascio_del_documento);
    const [data_di_scandenza, setDataDiScan] = React.useState(data.data_di_scandenza);
    const [ente_di_rilascio] = React.useState(data.ente_di_rilascio);
    const [comune_di_rilascio] = React.useState(data.comune_di_rilascio);
    
    return (
        <div className='section'>
            <div className='head_text' id='documento'>Documento</div>
            <div className='fields'>
                <FormControl variant="outlined" className='field -select'>
                    <InputLabel id="demo-simple-select-outlined-label">Tipo Di Documento</InputLabel>
                    <Select
                        labelId="Tipo Di Documento"
                        id="demo-simple-select-outlined"
                        defaultValue={tipo_di_documento}
                        helperText='tipo_di_documento'
                    name='tipo_di_documento'
                        onChange={(event) => {

                            setFieldValue('tipo_di_documento', event.target.value);
                        }}
                        label="Tipo Di Documento"
                        >
                        <MenuItem value='carta-didentita'>Carta d’identità</MenuItem>
                        <MenuItem value='passaporto'>Passaporto</MenuItem>
                        <MenuItem value='patente'>Patente</MenuItem>
                    </Select>
                </FormControl>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={numero_del_documento}
                    helperText='numero_del_documento'
                    name='numero_del_documento'
                    onChange={event => setFieldValue('numero_del_documento', event.target.value)} 
                    label="Numero Del Documento" 
                        />
            </div>
            <div className='fields'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Rilascio Del Documento"
                    value={data_di_rilascio_del_documento}
                    helperText='data_di_rilascio_del_documento'
                    name='data_di_rilascio_del_documento'
                    onChange={(date) => { setFieldValue('data_di_rilascio_del_documento', date); setDataDiRil(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDatePicker
                    disableToolbar
                    className='field'
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    required
                    id="date-picker-inline"
                    label="Data Di Scandenza"
                    value={data_di_scandenza}
                    helperText='data_di_scandenza'
                    name='data_di_scandenza'
                    onChange={(date) => { setFieldValue('data_di_scandenza', date); setDataDiScan(date) }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
                </MuiPickersUtilsProvider>
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    className='field' 
                    defaultValue={ente_di_rilascio}
                    helperText='ente_di_rilascio'
                    name='ente_di_rilascio'
                    onChange={event => setFieldValue('ente_di_rilascio', event.target.value)} 
                    label="Ente Di Rilascio" 
                        />
                <TextField 
                    required 
                    className='field' 
                    defaultValue={comune_di_rilascio}
                    helperText='comune_di_rilascio'
                    name='comune_di_rilascio'
                    onChange={event => setFieldValue('comune_di_rilascio', event.target.value)} 
                    label="Comune Di Rilascio" 
                        />
            </div>
        </div>
    )
}

const showEnergiaDocumento = (payload) => {
    const {
        type_contract,
        type_product,
        operation_gas,
        operation_ee,
        product_ee,
        product_gas
    } = payload;

    const regExp = new RegExp(/^IR|^IL|^GN|^FW/);
    const regExpEi = new RegExp(/^Ei sub/gmi);

    return (
        type_contract === 'contratto energia'
        && type_product
        && (operation_gas || operation_ee)
        && ((regExp.test(product_ee.product_name) || regExp.test(product_gas.product_name)) || (regExpEi.test(operation_ee.name) || regExpEi.test(operation_gas.name)))
    ) ? true : false;
}

function EnergiaDescription(props) {
    const { 
        data,
        setFieldValue,
        product_ee,
        product_gas,
        operation_ee,
        operation_gas,
        client_type
    } = props;

    const showAteco = ((/^IR|^GN/gmi.test(product_ee.product_name) || /^IR|^GN/gmi.test(product_gas.product_name) || (/EG Allacc. TEMPORANEO/gmi.test(operation_ee.name) || /EG Allacc. TEMPORANEO/gmi.test(operation_gas.name))) && client_type === 'business') ? true : false;

    const [description] = React.useState(data.description);
    const [ateco] = React.useState(data.ateco);

    return (
        <div className='section'>
            <div className='head_text'>Description</div>
            <div className='fields'>
                <TextField 
                    className='field' 
                    multiline
                    rows={5}
                    defaultValue={description}
                    helperText='description'
                    name='description'
                    onChange={event => setFieldValue('description', event.target.value)} 
                    label="Description" 
                        />
                {showAteco && <TextField 
                    required 
                    className='field' 
                    defaultValue={ateco}
                    helperText='ateco'
                    name='ateco'
                    onChange={event => setFieldValue('ateco', event.target.value)} 
                    label="Ateco" 
                        />}
            </div>
        </div>
    )
}

function EnergiaOtherExtra (props) {
    const { 
        setFieldValue,
        data,
        sub_contract,
        createLead
    } = props;

    const [callback_time, setCallbackTime] = React.useState(data.callback_time || null);
    const [passaggio_diretto, setPassaggio] = React.useState(data.passaggio_diretto);

    return (
        <div className='section'>
            <div className='head_text'>Other</div>
            {sub_contract === 'lead crosseling' && <div className='fields -other'>
                <MuiPickersUtilsProvider  utils={MomentUtils}>
                    <KeyboardDateTimePicker
                        // clearable
                        className='date' 
                        value={callback_time}
                        helperText='callback_time'
                        name='callback_time'
                        onChange={(date) => {setFieldValue('callback_time', date); setCallbackTime(date) }}
                        helperText="Callback time"
                        disablePast
                        format="DD/MM/YYYY HH:mm"
                        ampm={false}
                    />
                </MuiPickersUtilsProvider>
                <div className='action'
                    onClick={createLead}
                >Create Lead</div>
            </div>}

            <div className='section -checkbox'>
                <FormControlLabel
                    control={<Checkbox checked={passaggio_diretto} name="passaggio_diretto" />}
                    onChange={(event, value) => {setFieldValue('passaggio_diretto', value); setPassaggio(value)}}
                    label="Passaggio diretto"
                />
            </div>
        </div>
    )
}

const showEnergiaOther = (payload) => {
    const {
        type_contract,
        type_product,
        ricezione_del_plico_contrattuale,
        tipo_di_fatturazione,
        tipo_di_pagamento,
        product_ee,
        product_gas,
        sub_contract,
        client_type,
        product,
        operation,
        payment_type
    } = payload;

    return (
        (type_contract === 'contratto energia'
        && type_product
        && ricezione_del_plico_contrattuale
        && tipo_di_fatturazione
        && tipo_di_pagamento
        && sub_contract === 'lead crosseling'
        && (product_ee.product_name || product_gas.product_name))
        ||
        (type_contract === 'telco' 
        && client_type 
        && product 
        && product.product_name 
        && operation
        && sub_contract === 'lead crosseling'
        && payment_type)
    ) ? true : false;
}

function EnergiaCodice(props) {
    const { 
        data,
        setFieldValue,
        product_ee,
        product_gas
    } = props;

    const [codice_contratto_luce] = React.useState(data.codice_contratto_luce);
    const [codice_contratto_gas] = React.useState(data.codice_contratto_gas);

    return (
        <div className='section'>
            <div className='head_text'>Codice Contratto</div>
            <div className='fields'>
                {product_ee.product_name && <TextField 
                    className='field' 
                    defaultValue={codice_contratto_luce}
                    helperText='codice_contratto_luce'
                    name='codice_contratto_luce'
                    onChange={event => setFieldValue('codice_contratto_luce', event.target.value)} 
                    label="Codice Contratto Luce" 
                        />}
                {product_gas.product_name && <TextField 
                    className='field' 
                    defaultValue={codice_contratto_gas}
                    helperText='codice_contratto_gas'
                    name='codice_contratto_gas'
                    onChange={event => setFieldValue('codice_contratto_gas', event.target.value)} 
                    label="Codice Contratto Gas" 
                        />}
            </div>
        </div>
    )
}


function ExtraCodice(props) {
    const { 
        data,
        setFieldValue
    } = props;

    const [codice_contratto_extra] = React.useState(data.codice_contratto_luce);

    return (
        <div className='section'>
            <div className='head_text'>Codice Contratto</div>
            <div className='fields'>
                <TextField 
                    className='field' 
                    defaultValue={codice_contratto_extra}
                    helperText='codice_contratto_extra'
                    name='codice_contratto_extra'
                    onChange={event => setFieldValue('codice_contratto_extra', event.target.value)} 
                    label="Codice Contratto Extra" 
                        />
            </div>
        </div>
    )
}

// subs

function CodiceScontoManaged(props) {
    const {
        data,
        setFieldValue,
        statusManagePopup,
        managedFields,
        role,
        type_product,
        type_contract
    } = props;

    const [codice_sconto, setCodiceSconto] = React.useState(data.codice_sconto || '');
    const [codice_sconto_ee, setCodiceScontoEE] = React.useState(data.codice_sconto_ee || '');
    const [codice_sconto_gas, setCodiceScontoGas] = React.useState(data.codice_sconto_gas || '');


    let fieldsToShow = managedFields.find(field => field.code === 'codice_sconto');

    if(!fieldsToShow)
        fieldsToShow = { code: 'codice_sconto', properties: [] };

    return (
        <div className='section -actions -left'>
            {type_contract !== 'contratto energia' &&
                <FormControl variant="outlined" className='field -select -manage_section'>
                    {role === 'admin' && <div className='actions-managed'>
                        <div 
                            className='action -edit'
                            onClick={() => statusManagePopup(true, fieldsToShow)}
                            >
                        </div>
                    </div>}
                    <InputLabel id="demo-simple-select-outlined-label">Codice Sconto</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={codice_sconto}
                        helperText='codice_sconto'
                    name='codice_sconto'
                        onChange={(event, val) => { setFieldValue('codice_sconto', event.target.value); setCodiceSconto(event.target.value)}}
                        label="Codice Sconto"
                        >
                        {fieldsToShow.properties.map(field => <MenuItem value={field}>{field}</MenuItem>)}
                    </Select>
                </FormControl>    
            }

            {type_contract === 'contratto energia' && type_product === 'luce' &&
                <FormControl variant="outlined" className='field -select -manage_section'>
                    {role === 'admin' && <div className='actions-managed'>
                        <div 
                            className='action -edit'
                            onClick={() => statusManagePopup(true, fieldsToShow)}
                            >
                        </div>
                    </div>}
                    <InputLabel id="demo-simple-select-outlined-label">Codice Sconto EE</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={codice_sconto_ee}
                        helperText='codice_sconto_ee'
                    name='codice_sconto_ee'
                        onChange={(event, val) => { setFieldValue('codice_sconto_ee', event.target.value); setCodiceScontoEE(event.target.value)}}
                        label="Codice Sconto EE"
                        >
                        {fieldsToShow.properties.map(field => <MenuItem value={field}>{field}</MenuItem>)}
                    </Select>
                </FormControl>  
            }

            {type_contract === 'contratto energia' && type_product === 'gas' &&
                <FormControl variant="outlined" className='field -select -manage_section'>
                    {role === 'admin' && <div className='actions-managed'>
                        <div 
                            className='action -edit'
                            onClick={() => statusManagePopup(true, fieldsToShow)}
                            >
                        </div>
                    </div>}
                    <InputLabel id="demo-simple-select-outlined-label">Codice Sconto GAS</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={codice_sconto_gas}
                        helperText='codice_sconto_gas'
                    name='codice_sconto_gas'
                        onChange={(event, val) => { setFieldValue('codice_sconto_gas', event.target.value); setCodiceScontoGas(event.target.value)}}
                        label="Codice Sconto GAS"
                        >
                        {fieldsToShow.properties.map(field => <MenuItem value={field}>{field}</MenuItem>)}
                    </Select>
                </FormControl>  
            }

            {type_contract === 'contratto energia' && type_product === 'dual' && <>
                <FormControl variant="outlined" className='field -select -manage_section'>
                    <InputLabel id="demo-simple-select-outlined-label">Codice Sconto EE</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={codice_sconto_ee}
                        helperText='codice_sconto_ee'
                    name='codice_sconto_ee'
                        onChange={(event, val) => { setFieldValue('codice_sconto_ee', event.target.value); setCodiceScontoEE(event.target.value)}}
                        label="Codice Sconto EE"
                        >
                        {fieldsToShow.properties.map(field => <MenuItem value={field}>{field}</MenuItem>)}
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className='field -select -manage_section'>
                    {role === 'admin' && <div className='actions-managed'>
                        <div 
                            className='action -edit'
                            onClick={() => statusManagePopup(true, fieldsToShow)}
                            >
                        </div>
                    </div>}
                    <InputLabel id="demo-simple-select-outlined-label">Codice Sconto GAS</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={codice_sconto_gas}
                        helperText='codice_sconto_gas'
                    name='codice_sconto_gas'
                        onChange={(event, val) => { setFieldValue('codice_sconto_gas', event.target.value); setCodiceScontoGas(event.target.value)}}
                        label="Codice Sconto GAS"
                        >
                        {fieldsToShow.properties.map(field => <MenuItem value={field}>{field}</MenuItem>)}
                    </Select>
                </FormControl>
                </>
            }
        </div>
    )
}


function SubscriptionFlow(props) {
    const {
        data,
        setFieldValue,
        openNotification,
        subscribeUser,
        setFieldValueState
    } = props;

    const [name_subs, setName] = React.useState(data.name_subs || data.name || data.name_extra || '');
    const [last_name_subs, setLastName] = React.useState(data.last_name_subs || data.last_name || data.last_name_extra || '');
    const [email_subs, setEmail] = React.useState(data.email_subs || data.email || data.email_extra || '');
    const [verificationStatus, setVerification] = React.useState('not cheked');

    useEffect(() => {
        setFieldValue('name_subs', name_subs);
        setFieldValue('last_name_subs', last_name_subs);
        setFieldValue('email_subs', email_subs);
    })

    console.log(data.email_subs, data.email, data.email_extra, data.email_subs || data.email || data.email_extra)

    return (
        <div className='section' id='news_letter_section_two'>
            <div className='head_text'>Subscription Info</div>

            <div className='fields'>
                <TextField 
                    required 
                    error={!name_subs}
                    className='field' 
                    defaultValue={name_subs}
                    helperText='name_subs'
                    name='name_subs'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('name_subs', value);
                        setName(value)
                    }} 
                    label="Nome" 
                        />

                <TextField 
                    required 
                    error={!last_name_subs}
                    className='field' 
                    defaultValue={last_name_subs}
                    helperText='last_name_subs'
                    name='last_name_subs'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('last_name_subs', value);
                        setLastName(value)
                    }} 
                    label="Cognome"  
                        />
            </div>
            <div className='fields'>
                <TextField 
                    required 
                    error={!email_subs}
                    error={verificationStatus === 'error'}
                    className='field' 
                    defaultValue={email_subs}
                    helperText='email_subs'
                    name='email_subs'
                    onChange={event => { setEmail(event.target.value); setFieldValue('email_subs', event.target.value)} } 
                    label="Email" 
                        />
            </div>

            <div className='actions'>
                    <Button 
                        className={verificationStatus === 'checking' ? 'action -disabled' : 'action'}
                        onClick={() => {
                            setVerification('checking');
                            setFieldValueState('subscribeStatus', true);

                            service.verifyEmail({ email: email_subs }).then(resp => {
                                const { code, ok } = resp.data;

                                console.log(resp.data, 'resp.data');

                                if(!ok)
                                    return;

                                if(code == 5 || code == 4 || code == 7) {

                                    if(code == 7) {
                                        setVerification('verified');
                                        openNotification({ notificationText: "l’indirizzo email non è stato verificato, assicurati che sia corretto", notificationType: 'success' });
                                    } else {
                                        setVerification('verified');
                                        openNotification({ notificationText: "Email valid!", notificationType: 'success' });
                                    }
                                } else {
                                    setVerification('error');
                                    openNotification({ notificationText: "Email invalid!", notificationType: 'success' });
                                }

                                setFieldValueState('subscribeStatus', false);
                            }).catch(err => {
                                setVerification('not checked');

                                setFieldValueState('subscribeStatus', false);

                                openNotification({ notificationText: "Can't check the email, problem on backend", notificationType: 'success' });
                            })
                        }}
                        >
                        Verifica
                    </Button>
                    <Button 
                        disabled={ verificationStatus !== 'verified'}
                        className={ (verificationStatus !== 'verified') ? 'action -disabled' : 'action'}
                        onClick={subscribeUser}>
                        Invio
                    </Button>
                </div>
        </div>
    )
}






const showEnergiaSubAcctions = (payload) => {
    const {
        type_contract,
        type_product,
        ricezione_del_plico_contrattuale,
        tipo_di_fatturazione,
        tipo_di_pagamento,
        product_ee,
        product_gas
    } = payload;

    return (
        type_contract === 'contratto energia'
        && type_product
        && ricezione_del_plico_contrattuale
        && tipo_di_fatturazione
        && tipo_di_pagamento
        && (product_ee.product_name || product_gas.product_name)
    ) ? true : false;
}

const showEnergiaCodice = (payload) => {
    const {
        type_contract,
        type_product,
        ricezione_del_plico_contrattuale,
        tipo_di_fatturazione,
        tipo_di_pagamento,
        product_ee,
        product_gas,
        sub_contract
    } = payload;

    return (
        type_contract === 'contratto energia'
        && type_product
        && ricezione_del_plico_contrattuale
        && tipo_di_fatturazione
        && tipo_di_pagamento
        && (product_ee.product_name || product_gas.product_name)
    ) ? true : false;
}


function EnergiaCheckBox(props) {
    const {
        data,
        setFieldValue
    } = props;

    const [newsletter, setNewsletter] = React.useState(data.newsletter);
    const [marketing_terzi, setMarketingTerzi] = React.useState(data.marketing_terzi);

    return (
        <div className='section -checkbox'>
            <FormControlLabel
                control={<Checkbox checked={newsletter} name="newsletter" />}
                onChange={(event, value) => {setFieldValue('newsletter', value); setNewsletter(value)}}
                label="Newsletter IT"
            />
            <FormControlLabel
                control={<Checkbox checked={marketing_terzi} name="marketing_terzi" />}
                onChange={(event, value) => {setFieldValue('marketing_terzi', value); setMarketingTerzi(value)}}
                label="Marketing Terzi"
            />
        </div>
    )
}

const showEnergiaCheckbox = (payload) => {
    const {
        type_contract,
        type_product,
        ricezione_del_plico_contrattuale,
        tipo_di_fatturazione,
        tipo_di_pagamento,
        product_ee,
        product_gas,
        sub_contract,
        operation,
        product,
        client_type,
        prodotto,
        payment_type,

        // ALTRA
        client_type_altra,
        product_altra,
        payment_type_altra,
        operation_altra,

        product_insurance
    } = payload;

    return (
        (
            type_contract === 'contratto energia'
            && type_product
            && ricezione_del_plico_contrattuale
            && tipo_di_fatturazione
            && tipo_di_pagamento
            && (product_ee.product_name || product_gas.product_name)
        )
        || (
            (   
                type_contract === 'vendite extra' 
                || (type_contract === 'contratto energia')
                || (type_contract === 'telco')
            )
            && prodotto 
        )
        || (
            type_contract === 'telco'
            && sub_contract !== 'altra vendita'
            && client_type 
            && payment_type
            && product 
            && product.product_name 
            && operation
        )
        || (
            type_contract === 'telco'
            && sub_contract === 'altra vendita'
            && client_type 
            && client_type_altra
            && payment_type
            && payment_type_altra
            && product 
            && product_altra
            && product.product_name 
            && product_altra.product_name
            && operation
            && operation_altra
        )
        || (
            type_contract === 'Assicurazione' 
            && product_insurance
        )

    ) ? true : false;
}

const transferInfoToVendite = (formData, setFieldValue, typeContract) => {
    const {
        name,
        last_name,
        nome_referente,
        cognome_referente,
        codice_fiscale,
        codice_fiscale_referente_extra,
        data_di_nascita,
        luogo_di_nascita,
        codice_fiscale_referente,
        data_di_nascita_referente,
        data_nascita_referente,
        luogo_di_nascita_referente,
        luogo_nascita_referente,
        phone,
        email,
        sesso,
        sesso_referente,
        ragione_sociale_extra,

        // address
        indirizzo_residenza,
        civico_residenza,
        cap_residenza,
        comune_residenza,
        provincia_residenza,

        // address
        tipo_indirizzo,
        civico,
        cap,
        localita,
        provincia
    } = formData;

    setFieldValue("name_extra", name || nome_referente);
    setFieldValue("last_name_extra", last_name || cognome_referente);
    setFieldValue("ragione_sociale_extra", ragione_sociale_extra);
    setFieldValue("nome_referente_extra", nome_referente);
    setFieldValue("cognome_referente_extra", cognome_referente);
    setFieldValue("codice_fiscale_referente_extra", codice_fiscale_referente_extra);
    setFieldValue("codice_fiscale_extra", codice_fiscale || codice_fiscale_referente);
    setFieldValue("data_di_rilascio_extra", data_di_nascita || data_di_nascita_referente || data_nascita_referente);
    setFieldValue("luogo_di_nascita_extra", luogo_di_nascita || luogo_di_nascita_referente || luogo_nascita_referente);
    setFieldValue("phone_extra", phone);
    setFieldValue("email_extra", email);
    setFieldValue("sesso_extra", sesso || sesso_referente);

    if(typeContract === 'telco') {
        setFieldValue("indirizzo_extra", tipo_indirizzo);
        setFieldValue("civico_extra", civico);
        setFieldValue("cap_extra", cap);
        setFieldValue("comune_extra", localita);
        setFieldValue("provincia_extra", provincia);

    }else if(typeContract === 'contratto energia') {
        setFieldValue("indirizzo_extra", indirizzo_residenza);
        setFieldValue("civico_extra", civico_residenza);
        setFieldValue("cap_extra", cap_residenza);
        setFieldValue("comune_extra", comune_residenza);
        setFieldValue("provincia_extra", provincia_residenza);
    }
};

const transferVenditeToEnergia = (formData, setFieldValue, typeContract) => {
    const {
        name_extra,
        last_name_extra,
        ragione_sociale_extra,
        nome_referente_extra,
        cognome_referente_extra,
        codice_fiscale_extra,
        codice_fiscale_referente_extra,
        data_di_rilascio_extra,
        luogo_di_nascita_extra,
        phone_extra,
        email_extra,
        sesso_extra,

        // address fields
        indirizzo_extra,
        civico_extra,
        cap_extra,
        comune_extra,
        provincia_extra
    } = formData;

    setFieldValue("name", name_extra);
    setFieldValue("last_name", last_name_extra);
    setFieldValue("ragione_sociale", ragione_sociale_extra);
    setFieldValue("nome_referente", nome_referente_extra);
    setFieldValue("cognome_referente", cognome_referente_extra);
    setFieldValue("codice_fiscale", codice_fiscale_extra);
    setFieldValue("codice_fiscale_referente", codice_fiscale_referente_extra);
    setFieldValue("data_di_nascita", data_di_rilascio_extra);
    setFieldValue("luogo_di_nascita", luogo_di_nascita_extra);
    setFieldValue("phone", phone_extra);
    setFieldValue("email", email_extra);
    setFieldValue("sesso", sesso_extra);

    // Set address fields based on contract type
    if (typeContract === 'telco') {
        setFieldValue("tipo_indirizzo", indirizzo_extra);
        setFieldValue("civico", civico_extra);
        setFieldValue("cap", cap_extra);
        setFieldValue("localita", comune_extra);
        setFieldValue("provincia", provincia_extra);
    } else if (typeContract === 'contratto energia') {
        setFieldValue("indirizzo_residenza", indirizzo_extra);
        setFieldValue("civico_residenza", civico_extra);
        setFieldValue("cap_residenza", cap_extra);
        setFieldValue("comune_residenza", comune_extra);
        setFieldValue("provincia_residenza", provincia_extra);
    }
};

const transferEnergiaToInsurance = (formData, setFieldValue, typeContract) => {
    const {
        name,
        last_name,
        codice_fiscale,
        phone,
        email
    } = formData;

    setFieldValue("name_insurance", name);
    setFieldValue("last_name_insurance", last_name);
    setFieldValue("codice_fiscale_insurance", codice_fiscale);
    setFieldValue("phone_insurance", phone);
    setFieldValue("email_insurance", email);
};



const transferDataToAltra = (form, state, setFieldValue, setFieldValueState) => {
    const {
        product,
        client_type,
        operation,
        payment_type,
        delegato,
    } = state;

    setFieldValueState('product_altra', product);
    setFieldValueState('client_type_altra', client_type);
    setFieldValueState('operation_altra', operation);
    setFieldValueState('payment_type_altra', payment_type);
    setFieldValueState('delegato_altra', delegato);

    for(let key in form) {
        setFieldValue(key + '_altra', form[key]);
    }
}



const showDraftCreate = (payload) => {
    const {
        type_contract,
    } = payload;

    return (
        type_contract
    ) ? true : false;
}

const showEnergiaAction = (payload) => {
    const {
        type_contract,
        type_product,
        ricezione_del_plico_contrattuale,
        tipo_di_fatturazione,
        tipo_di_pagamento,
        product_ee,
        product_gas,
        sub_contract,
        operation,
        product,
        client_type,
        prodotto,
        payment_type,

        // ALTRA
        client_type_altra,
        product_altra,
        payment_type_altra,
        operation_altra,

        // INSURANCE
        product_insurance,
        compagnia_scelta,
    } = payload;

    return (
        (type_contract === 'contratto energia'
        && type_product
        && ricezione_del_plico_contrattuale
        && tipo_di_fatturazione
        && tipo_di_pagamento
        && (product_ee.product_name || product_gas.product_name)
        && sub_contract !== 'vendite extra'
        )
        || (
            (   
                type_contract === 'vendite extra' 
                || (type_contract === 'contratto energia' && sub_contract === 'vendite extra')
                || (type_contract === 'telco'  && sub_contract === 'vendite extra')
            )
            && prodotto 
        )
        || (
            (
            type_contract === 'telco' 
            && sub_contract !== 'altra vendita'
            && client_type 
            && product 
            && product.product_name 
            && operation
            && payment_type
            && sub_contract !== 'vendite extra'
            )
        )

        || (
            (
            type_contract === 'telco' 
            && sub_contract === 'altra vendita'
            && client_type 
            && product 
            && product.product_name 
            && operation
            && payment_type
            && sub_contract !== 'vendite extra'

            // altra
            && operation_altra
            && product_altra
            && product_altra.product_name
            && client_type_altra
            && payment_type_altra
            )
        )

        || (
            type_contract === 'Assicurazione' 
            && ((product_insurance && product_insurance !== 'yolo_home' && compagnia_scelta) || (product_insurance === 'yolo_home'))
        )
    ) ? true : false;
}

const months = {
    'January': 'A',
    'February': 'B',
    'March': 'C',
    'April': "D",
    'May': 'E',
    'June': 'F',
    'July': "G",
    'August': "H",
    'September': "I",
    'October': "J",
    'November': "K",
    'December': "L"
}

// ENERGIA END


// const verificationStatuses = [
//     ''
// ]

const renderExFornitoreFields = () => ex_fornitore_fields.map(value => <MenuItem value={value}>{value}</MenuItem>);

const ex_fornitore_fields = [
    "TRADER A",
    "A.G.A.M. SPA",
    "ACAM CLIENTI SPA",
    "ACEA ENERGIA SPA",
    "ADRIATICA ENERGIA SERVIZI SRL",
    "AEB TRADING SPA",
    "AEM ACQUISTO E VENDITA ENERGIA",
    "AGAM VENDITE SRL",
    "AGSM ENERGIA SRL",
    "ALPENERGIE ITALIA SPA",
    "AMPS ENERGIE",
    "ASM ENERGY SRL",
    "ASSOUTILITY SRL",
    "ATEL ENERGIA SRL",
    "AZIENDA ENERGETICA SPA",
    "AZIENDA ENERGETICA TRADING SRL",
    "BURGO ENERGIA SRL",
    "C.U.RA.",
    "CARTIERE ERMOLLI SPA",
    "CENTOMILACANDELE SCPA",
    "CLEANPOWER SCARL",
    "CONSORZIO APUANIA ENERGIA",
    "CONSORZIO ROMAGNA ENERGIA",
    "CONSORZIO TOSCANA ENERGIA SPA",
    "CVA TRADING SRL",
    "DALMINE ENERGIE",
    "DUFERCO ENERGIA SRL",
    "DYNAMEETING SPA",
    "E.ON ITALIA SPA",
    "EDISON ENERGIA SPA",
    "EGEA COMMERCIALE SRL",
    "EGL ITALIA SPA",
    "ELECTRA ITALIA SPA",
    "ELECTRABEL ITALIA",
    "ENEDESA ITALIA",
    "ENEL ENERGIA SPA",
    "ENERG.IT SPA",
    "ENERGETIC SOURCE SPA",
    "ENERGIA E TERRITORIO SPA",
    "ENI SPA",
    "ERGON ENERGIA",
    "ESPERIA SRL",
    "ESTENERGY SPA",
    "EXERGIA SPA",
    "FINARVEDI SPA",
    "FLYENERGIA SPA",
    "FONTEL SRL",
    "GALA SPA",
    "GREEN NETWORK SPA",
    "HERA COMM SRL",
    "IDROELETTRICA SCARL",
    "IDROENERGIA SRL",
    "IRIDE MERCATO",
    "ITALCOGIM VENDITE SPA",
    "ITALGEN SPA",
    "LA 220 SPA",
    "LINEA GROUP SPA",
    "LUCAS ENGINE SPA",
    "MODULA SPA",
    "MPE ENERGIA SRL",
    "MULTIUTILITY SPA",
    "NIGGELER ENERGIA SRL",
    "NUOVE CARTIERE DI TIVOLI",
    "OTTANA ENERGIA SRL",
    "RADICI ENERGIE SRL",
    "SERVIZI INTEGRATI ENERGIA S.I.",
    "SERVIZI UNINDUSTRIA MULTIUTILI",
    "SIET",
    "SORGENIA SPA",
    "TELENERGIA SRL",
    "TIWAG ITALIA SRL",
    "TRENTA SPA",
    "WORLDENERGY ITALIA SRL",
    "Sinergas S.r.L",
    "ENEL DISTRIBUZIONE S.P.A.",
    "VIVIGAS SPA",
    "Espansione SRL",
    "VALLE CAMONICA SERVIZI VENDITE",
    "PANDORA S.P.A.",
    "[Vincolato]",
    "[Libero]",
    "Tradecom",
    "BLUENERGY GROUP",
    "ALTO GARDA SERVIZI",
    "ENERGRID SPA",
    "ENIA ENERGIA",
    "AMGA ENERGIA E SERVIZI",
    "A2A",
    "OMNIA ENERGIA SPA",
    "LA LUCE SPA",
    "Trentino Energia srl",
    "A.I.M. Vicenza ENERGIA S.p.a.",
    "A.S.S.M. S.p.a Tolentino",
    "ACEGAS - APS S.p.A.",
    "AE EW Azienda Energetica Spa",
    "AEM Gestioni S.r.l. Cremona",
    "AMAIE s.p.a Sanremo",
    "AMEA S.p.a.",
    "AMET SPA Trani",
    "Asec Trade S.r.l.",
    "ASM S.p.a. Sondrio",
    "ASM TERNI S.P.A.",
    "ASTEA",
    "ATENA S.p.a Vercelli",
    "ATENA Trading S.r.l.",
    "Blumet S.p.A.",
    "Comune di Magliano di Tenna",
    "CONSIAGAS SPA",
    "DEVAL SPA",
    "DSE SRL",
    "EGI SPA",
    "ENECO ENERGIA S.r.l.",
    "ENEIDE Srl",
    "ENEL GAS",
    "Enel Servizio Elettrico",
    "Energ S.p.A.",
    "ENERGIA CORRENTE SRL",
    "Energia S.p.A.",
    "ENERGIE OFFIDA SRL",
    "ERG Power Gas S.r.l.",
    "ESTRA SPA",
    "Fintel Multiservizi S.r.l.",
    "GASCOM S.p.A.",
    "GEA Commerciale S.p.A.",
    "GELSIA ENERGIA",
    "Global Power S.p.A.",
    "Hera S.p.a. Bologna",
    "INTENERGY SRL",
    "IRIS-Isontina Reti Integrate",
    "LIFEGATE ENERGY PEOPLE SRL",
    "LUCE S.r.l.",
    "LumEnergia S.c.p.a.",
    "METAENERGIA S.p.A.",
    "Multienergy S.r.l.",
    "Multiservizio S.p.A.",
    "Obiettivo Energia S.r.l.",
    "Odoardo Zecca",
    "Onda srl",
    "Piu Energia Spa",
    "Prometeo SRL",
    "Repower Italia",
    "RESS S.r.l.",
    "SET Distribuzione S.p.a.",
    "SGR Servizi S.p.A.",
    "SNIE SPA",
    "Trentino Servizi S.p.A.",
    "Umbria Energy SRL",
    "Utilita' S.p.A.",
    "VESTA TRE S.r.l.",
    "Visitel S.r.l.",
    "Voghera Energia Vendita S.p.A.",
    "ASP Azienda Servizi Polverigi",
    "Azienda Energetica Municipale",
    "Azienda San Severino Marche",
    "COOPERATIVA FORZA E LUCE DI AO",
    "Energindustria Vicenza",
    "Sistema Energia Italia",
    "5 M ENERGIA S.R.L.",
    "A.E.M. TIRANO S.P.A.",
    "AET ITALIA",
    "ARGOS ENERGIA SRL",
    "ASM BRESSANONE SPA",
    "C.E.G. SOCIETA'COOPERATIVA ELE",
    "CRE-Consorzio Romagna Energia",
    "OPTIMA ITALIA SPA",
    "ROMAENERGIE SRL",
    "SOC.COOP.ELETT.GIGNOD",
    "TEAENERGIA S.R.L.",
    "UNOGAS ENERGIA SPA",
    "VALDISOTTO ENERGIA S.R.L.",
    "VALLENERGIE S.P.A.",
    "VERITAS ENERGIA S.R.L.",
    "A.I.M. ENERGY S.R.L.",
    "A.I.M. Vicenza S.p.A.",
    "A2A ENERGIA SPA",
    "A2A SPA",
    "ACE DISTRIBUZIONE S.P.A.",
    "ACEAElectrabel Elettricità SpA",
    "AGSM DISTRIBUZIONE S.R.L.",
    "AGSM ENERGIA S.P.A.",
    "ASTEA ENERGIA SRL",
    "ASTEA S.P.A.",
    "IREN EMILIA S.P.A.",
    "IREN MERCATO S.P.A.",
    "Seltrade Spa",
    "GDF SUEZ ENERGIE SPA",
    "SEI Società Energia Italia",
    "LENERGIA SRL",
    "BLUE META SPA",
    "A.F. ENERGIA S.r.l",
    "ABENERGIE S.P.A.",
    "ESA Energie S.r.l.",
    "Uni.Co.Ge. Srl",
    "MIWA ENERGIA S.r.l.",
    "OLIMPIA ENERGIA E GAS SPA",
    "Comune di Sessa Cilento",
    "ENEGAN SPA",
    "ETRA ENERGIA",
    "Mia Energia S.p.a.",
    "Enega S.r.l.",
    "GEO SPA",
    "Metamer Srl",
    "ALFANO ENERGIA SPA",
    "AGENGRANDA SRL",
    "SIMP GAS",
    "INENERGY SRL",
    "SOC.COOP.IDROEL.FORNI DI SOPRA",
    "SOENERGY SRL",
    "Transenergia S.r.l.",
    "[Nuovo allaccio]",
    "Epiù Spa",
    "AXPO ITALIA SPA",
    "AEVV ENERGIE SRL",
    "AEG Soc. Coop.",
    "ENERVENTI SPA",
    "ENERXENIA",
    "GAS SALES SRL",
    "GESAM ENERGIA SPA",
    "REWIND",
    "SIMECOMM SRL",
    "TOLENTINO",
    "UGM",
    "METANIA SRL",
    "PIEMONTE ENERGY",
    "BARI ENERGIA",
    "GAS NATURAL VENDITA ITALIA",
    "NUOVA ENERGIA ITALIA SRL",
    "TECNO ENERGIA",
    "GRITTI GAS SRL",
    "ENER TRADING SPA",
    "LA BRUZOLESE SRL",
    "STECA ENERGIA",
    "OMEGA POWER SPA",
    "EAS",
    "Spirit Energia",
    "AMG GAS S.R.L.",
    "EL TRADING spa",
    "PASUBIO ENERGIA SRL",
    "AEMME LINEA ENERGIA SPA",
    "ASM AZIENDA SPECIALE P D'ARCO",
    "GOLDENERGY SRL",
    "SPIENERGY SRL",
    "ASCOTRADE (Gruppo Ascopiave)",
    "AEC COMUNE DI CASTELLO MOLINA",
    "ASPEM SPA",
    "BEETWIN SPA",
    "POWER ENERGIA SPA",
    "ALPIQ Energia Italia Spa",
    "SORESINA RETI E IMPIANTI SRL",
    "IDROELETTRICA VALCANALE SAS",
    "ASM TIONE",
    "COMUNE DI CAVALESE",
    "Libera Energia S.p.a.",
    "Che Bolletta S.p.a.",
    "INTERCONSULT SRL",
    "ENERGENS SRL",
    "CAMPANIA GAS",
    "ENERcom s.r.l.",
    "CMV Energia srl",
    "FREE ENERGIA S.P.A.",
    "Enertronica Spa",
    "Switch Power Srls",
    "Amgas Bari srl",
    "S.EL.I.S. LAMPEDUSA S.P.A.",
    "Italconsulting Energia Srl",
    "FIORENTINA OIL COMPANY SRL",
    "A.I.M. ENERGY S.R.L.",
    "AXOPOWER SRL",
    "CI.ES. IMPIANTI TECNOLOGICI",
    "COMUNITA' COOPERATIVA",
    "CI.ES.I.T. ENERGY",
    "Comune S.Stefano di Sessanio",
    "ROYAL GROUP SRL",
    "AZIENDA ENERGIA E GAS SOC.COOP",
    "Luce e gas italia srl",
    "COMPAGNIA ENERGETICA ITALIANA",
    "SALERNO ENERGIA SPA",
    "ATERNO GAS POWER",
    "ACSM SPA",
    "ESTPIU'",
    "TECHMAGAS-ITALIANGAS",
    "PLURIENERGIA",
    "METAEDIL",
    "BASILICATA ENERGIA",
    "HELIOS ENERGIA",
    "INVENT",
    "LION ENERGY",
    "SORGEA ENERGIA",
    "SKY GAS E POWER",
    "API GREEN ENERGY",
    "OMNIAVER SRL",
    "LIRENAS GAS & LUCE SPA",
    "ENERGIA GREEN POWER SRL",
    "BRYO SPA",
    "ENEGAS",
    "ENERGETIC SPA",
    "GEAS SRL",
    "NWG ENERGIA SRL",
    "SPIGAS",
    "VELGA",
    "CI.ES. IMPIANTI TECNOLOGICI",
    "AZIENDA MUNICIPALE DI CHIOMONT",
    "EMME2 SRL GAS E LUCE",
    "POMILIA ENERGIA SPA",
    "LUCE E GAS ITALIA SRL",
    "CEDIS STORO",
    "ITALIANGAS",
    "ENERGETIC SPA",
    "ENERVIVA",
    "ENERGIA ITALIA SRL",
    "ACEA PINEROLESE",
    "AIMET",
    "CH4",
    "ENERGY TIME RETAIL",
    "RETAIL",
    "ETRURIA LUCE E GAS",
    "GLOBOENERGIA",
    "Società Elettrica in Morbegno",
    "CHIARAGAS SERVIZI SRL",
    "CEIS STENICO",
    "COGAS PIU'",
    "COLSAM GAS",
    "COOP GAS",
    "AEC COMUNE DI RONCONE",
    "JULIA SERVIZI SPA",
    "GEO NORD EST",
    "BFD ENERGY SRL",
    "AGESP ENERGIA SRL",
    "ENEL GREEN POWER",
    "ENERGYPOLIS SRL",
    "SOC.COOP. ELETTR. CAMPO TURES",
    "ENEL SPA",
    "HERA SPA",
    "BeNRG srl",
    "GRAN SASSO ENERGIE",
    "SOCIETA' SERVIZI ENERGIA",
    "CAST ENERGIE",
    "NUOVENERGIE SPA",
    "APE SRL",
    "PROMETEO SPA",
    "UMBRIA ENERGY SPA",
    "ELETTRIKOM SRL",
    "BERGAMASCA ENERGIA",
    "ACEL SERVICE",
    "EGERIA",
    "FUTURENERGY RINNOVABILE",
    "NORD OVEST ENERGIE",
    "ROMA GAS POWER",
    "UNENDO",
    "LW ENERGY",
    "COIMEPA Servizi Srl",
    "PASSUELLO FRATELLI SRL",
    "ELETTRAGAS",
    "ENERMED",
    "MAXIMA ITALIA",
    "ELETTRAROMA SRL",
    "EMMECIDUE SPA",
    "X3ENERGY S.P.A.",
    "SOCIETA' ELETTRICA PONZESE",
    "EUROPE ENERGY GAS&POWER SPA",
    "GASPOWER",
    "E.JA ENERGIA",
    "ENERFIN SRL",
    "ERRECI",
    "POWERGAS CLIENTI SRL",
    "CHINA POWER",
    "ARIA ENERGIA SPA",
    "MARCHE ENERGIE SRL",
    "GREEN ENERGY SRL",
    "CIP LOMBARDIA SRL",
    "AUDAX ENERGIA SRL",
    "DGAS & POWER SPA",
    "A.M.C. ENERGIA s.r.l.",
    "COMUNE DI CASTEL DEL MONTE",
    "EVISO SRL",
    "WEG SCRL",
    "COGESER VENDITE SRL",
    "CONSORTIUM ENERGIA VERDE SPA",
    "ENERGY ENERGIA E GAS",
    "ERMES GAS&POWER SRL",
    "GRUPPO DOLOMITI ENERGIA",
    "INSIEMENERGIA SPA",
    "UNIPOWER ITALIA SRL",
    "SIME SPA",
    "SENTRA ENERGIA S.R.L.",
    "COMUNE DI PACENTRO",
    "AEM VALPRATO SOANA",
    "AEC VIPITENO",
    "ROTAGAS SRL",
    "SIMA ENERGIA SPA",
    "ENERGIE DEL MONVISO",
    "UBROKER",
    "TRENTA SPA (MT)",
    "Gopower S.r.l.",
    "Veneto Gas & Power",
    "LINEA PIU S.P.A.",
    "CURA GAS AND POWER S.p.A.",
    "Convergenze S.p.a.",
    "CVA TRADING (MT)",
    "2G ENERGIA SRL",
    "AP ENERGIA SPA",
    "CONFINDUSTRIA ENERGIA ADRIAT",
    "I-ENERGY ENERGIA E GAS",
    "METANOSUD SERVIZI",
    "SAN GIORGIO ENERGIA",
    "SICME ENERGY E GAS SRL",
    "UNION GAS METANO SPA",
    "UTILITY 360",
    "BLURANTON",
    "ASMEA S.R.L.",
    "ILLUMIA S.P.A.",
    "MORE LIFE ENERGY S.R.L.",
    "ENERGHE S.P.A.",
    "NORD ENERGIA",
    "IES POWER & GAS SRL",
    "SMARTUTILITY",
    "ENERGETIC SOURCE LUCE E GAS",
    "UTILITY SRL",
    "UBROKER SRL",
    "MADE IN ENERGY",
    "LUCE E GAS ITALIA",
    "SMART LUCE E GAS",
    "DUE ENERGIE - DUFERCO GROUP",
    "ENGIE",
    "SERVIZIO ELETTRICO NAZIONALE",
    "HERA COMM MARCHE",
    "Alperia Energy srl",
    "GASWAY",
    "CIP LOMBARDIA S.R.L.",
    "YES ENERGY",
    "ANTARES",
    "GEKO",
    "EVIVA SPA",
    "DOLOMITI ENERGIA",
    "TU.GG",
    "MBI GAS E LUCE SRL",
    "ANTENORE",
    "UNICA ENERGIA SRL",
    "Energy Italia Spa",
    "plt puregreen",
    "FUTURENERGY",
    "aterno gas e power",
    "SERVIZIO ELETTRICO ROMA",
    "OROBIE GAS & POWER S.R.L",
    "Energie Diffuse S.r.l.",
    "E2A (ENERGIA ADRIATICA ABRUZZE",
    "INENERGY SPA",
    "REVOLUCE",
    "LUCE&GAS COOP",
    "ASM - AZIENDA SERVIZI MUNICIPA",
    "2G Energia Srl",
    "ONE POWER & GAS",
    "NECTAWARE",
    "ITALIA.POWER",
    "axpo",
    "SIEC",
    "NOVA AEG SPA",
    "CONSOCI ENERGIE",
    "OPTIMA ITALIA",
    "TRI.BI.GAS",
    "GRUPPO AUTOGAS",
    "EDLO ENERGY",
    "EN-GAS SRL",
    "GEU ENERGIA",
    "Patavium Energia srl di Padova",
    "AZ.EL.HALLER SAS DI KRUSELBURG",
    "SMG S.R.L",
    "EVOLVERE ENERGIA",
    "SIENERGIA SRL - AVELLINO",
    "DOLOMITI ENERGIA",
    "ALLEANZA LUCE & GAS",
    "SEI ENERGIA",
    "SEMPLICE",
    "ESA SRL",
    "CON ENERGIE",
    "OVENERGY",
    "NATURAL ENERGY",
    "FONTEL SRL",
    "ACOS Spa",
    "EICOM srl",
    "ECO TRADE",
    "GRUPPO SINERGY",
    "LEVIGAS",
    "IBERDROLA",
    "PASUBIO ENERGIA SRL",
    "media consult energy & comm sr",
    "LIFEGATE ENERGY S.R.L.",
    "ENNE ENERGIA SRL",
    "ACCENDI LUCE E GAS DI COOP",
    "Energia Sociale S.p.A",
    "MIOGAS SRL",
    "PEF Power",
    "Ecotrade",
    "SEN",
    "CHIURLO SRL",
    "ABENERGIE s.p.a",
    "ENERGY ONLY S.P.A.",
    "Metano Nord SPA",
    "EnergiabaseTrieste",
    "INVOLTA",
    "ITALIA GAS E LUCE",
    "S4 Energia",
    "TATE",
    "GRITTI ENERGIA Srl",
    "ENERCOOP",
    "Phlogas & Power",
    "commerciale Gas e Luce",
    "Madogas Natural Energy",
    "ANTENORE ENERGIA SRL",
    "ENTRACO GAS & POWER",
    "B-on",
    "AUTOGASNORD",
    "INRETE DISTRIBUZIONE ENERGIA",
    "Withu",
    "Energia Nazionale Srl",
    "LORO F.LLI  S.P.S",
    "DOMESTIKA ENERGIA",
    "S.G. ENERGIA",
    "Consorzio Noce-STN Val sole",
    "ILLUMIA SPA",
    "Alegas",
    "Moods Energie",
    "WEKIWI SRL",
    "Optima Spa",
    "UNIKO S.R.L.",
    "Ajò Energia Srl",
    "ARGOS",
    "AB ENERGIE SRL",
    "SPIGAS",
    "MIO GAS E LUCE",
    "ENERGETIC GAS & POWER",
    "SELGAS",
    "PLT PURE GREEN",
    "ENIT-Energia Italia SpA",
    "MET energia Italia spa",
    "ENEGAN SRL",
    "CENTRAL ENERGY",
    "ALG S.P.A. COOP",
    "LIFEGATE S.p.A.",
    "FACILE ENERGY",
    "sinergy",
    "Jen Energia Srl",
    "AGN LUCE",
    "Alperia smart services",
    "NeN Energia",
    "Acel energie srl",
    "LEVIGAS S.P.A.",
    "BLU ENERGY",
    "SECAB SOC COOP- PALUZZA (UD)",
    "ENI GAS E LUCE S.P.A.",
    "AMGAS BLU S.R.L.",
    "SEREGNO ENERGIA",
    "TUA ENERGIA",
    "ENERG SPA",
    "PLT ENERGIA",
    "PROENERGY",
    "OLIMPIA SRL",
    "ENERGIA PULITA",
    "ERACOM",
    "ENERGIA GREEN POWER SRL",
    "NUOVE ENERGIE",
    "Big one energy srl",
    "LUPATOTINA",
    "EREDI CAMPIDONICO SPA",
    "Energia Comune",
    "ALPERIA SUM SPA",
    "e.plus",
    "IDEA ENERGIA",
    "CAMER",
].sort();



function SendEmailFields(props) {
    const {
        data,
        setFieldValue,
        openNotification,
        subscribeUser,
        setFieldValueState,
        type_product,
        type_contract,
        product_ee,
        product_gas,
        product,
        prodotto,
        operation_ee,
        operation_gas,
        operation,
        client_type,
        newsletter,
        marketing_terzi
    } = props;

    const [passaparola_nome, setPass] = React.useState(data.passaparola_nome || '')
    const [origine_passaparola, setOrigine] = React.useState(data.origine_passaparola || '')
    const [status, setStatus] = React.useState('not sent');
    const [emails, setSelectedEmails] = React.useState([]);
    const [emailTypes, setEmailTypes] = React.useState([]);
    const [passaparola, setPassaparola] = React.useState(data.passaparola);
    const [recensioni, setRecensioni] = React.useState(data.recensioni);
    const [name_mailing, setName] = React.useState(data.name_mailing || data.name || data.name_extra || '');
    const [last_name_mailing, setLastName] = React.useState(data.last_name_mailing || data.last_name || data.last_name_extra || '');
    const [email_mailing, setEmail] = React.useState(data.email_mailing || data.email || data.email_extra || '');
    const [verificationStatus, setVerification] = React.useState('not cheked');

    useEffect(() => {
        setFieldValue('name_mailing', name_mailing);
        setFieldValue('last_name_mailing', last_name_mailing);
        setFieldValue('email_mailing', email_mailing);

        service.getAllMailCheckboxes().then(resp => {
            console.log(resp, 'SendEmailFields')
            const { checkboxes } = resp.data;

            console.log(checkboxes, 'checkbox.template');
            
            setEmailTypes(checkboxes);
        })
    }, [])

    const setContactInfo = () => {
        const name_subs = data.name || data.name_extra;
        const last_name_subs = data.last_name || data.last_name_extra;
        const email_subs = data.email || data.email_extra;

        setName(name_subs);
        setLastName(last_name_subs);
        setEmail(email_subs);

        setFieldValue('name_subs', name_subs);
        setFieldValue('last_name_subs', last_name_subs);
        setFieldValue('email_subs', email_subs);
    }

    const sendEmailAction = () => {
        let name = '',
            last_name,
            email,
            productS,
            operationS

        const {
            lead_id,
            contact_owner,
            operazione_extra,
            contact_id
        } = data;

        if(!lead_id)
            return props.openNotification({ notificationText: 'You have to fill the Lead ID field', notificationType: 'success' });

        if(!contact_id)
            return props.openNotification({ notificationText: 'You have to fill the Contact ID field', notificationType: 'success' });

        if(!contact_owner || !contact_owner.ZohoID)
            return props.openNotification({ notificationText: 'You have to fill the Contact Owner field', notificationType: 'success' });


        if(type_contract === 'contratto energia' || type_contract === 'telco') {
            if(client_type === 'domestico') {
                name        = name_mailing;
                last_name   = last_name_mailing;
                email       = email_mailing;
            } else {
                last_name   = data.ragione_sociale;
                email       = email_mailing;
            }

            if(type_contract === 'contratto energia') {
                if(type_product !== 'gas') {
                    productS = product_ee.product_name;
                    operationS = operation_ee.name;
                } else {
                    productS = product_gas.product_name;
                    operationS = operation_gas.name;
                }

            } else {
                productS = product.product_name;
                operationS = operation;
            }


        } else {
            name        = name_mailing;
            last_name   = last_name_mailing;
            email       = email_mailing;
            productS     = prodotto;
            operationS   = operazione_extra;
        }

        if(!name && client_type === 'domestico')
            return props.openNotification({ notificationText: 'You have to fill the Nome field', notificationType: 'success' });

        if(!last_name)
            return props.openNotification({ notificationText: 'You have to fill the Cognome field', notificationType: 'success' });

        if(!email)
            return props.openNotification({ notificationText: 'You have to fill the email field', notificationType: 'success' });

        if(!productS)
            return props.openNotification({ notificationText: 'You have to fill the product field', notificationType: 'success' });

        if(type_contract === 'contratto energia' || type_contract === 'telco') {
            if(!operationS)
                return props.openNotification({ notificationText: 'You have to fill the operation field', notificationType: 'success' });
        }

        let code = '';

        if(type_contract === 'contratto energia') {
            const { 
                codice_fiscale,
                codice_fiscale_referente,
                pod,
                pdr
            } = data;
            let lg_code = '';

            if(product_ee && product_ee.product_name) {
                code += product_ee.product_name.substring(0, 2);
                lg_code += pod ? pod.slice(-4) : '0000';
            }

            if(product_gas && product_gas.product_name) {
                
                if(lg_code)
                    lg_code += "&";

                code += product_gas.product_name.substring(0, 2);
                lg_code += pdr ? pdr.slice(-4) : '0000';
            };

            if(lg_code && code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += lg_code;
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }
        }

        if(type_contract === 'vendite extra') {
            const { 
                codice_fiscale_extra
            } = data;

            if(prodotto) {
                code += prodotto.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale_extra ? codice_fiscale_extra.substring(0, 6) : 'X';
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }
        }

        if(type_contract === 'telco') {
            const { 
                codice_fiscale,
                codice_fiscale_referente,
            } = data;

            if(product && product.product_name) {
                code += product.product_name.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale ? codice_fiscale.substring(0, 6) : codice_fiscale_referente.substring(0, 6)
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }
        }

        if(type_contract === 'Assicurazione') {
            const { 
                codice_fiscale_insurance,
                product_insurance
            } = data;

            if(product_insurance && product_insurance) {
                code += product_insurance.substring(0, 2);
            }

            if(code) {
                code += codice_fiscale_insurance.substring(0, 6);
                code += months[new Date().toLocaleString('en-US', { month: 'long' })];
            }
        }

        const payload = {
            lead_id,
            contact_id,
            name,
            last_name,
            email,
            contact_owner: contact_owner.ZohoID,
            product: productS,
            operation: operationS,
            passaparola_nome,
            code,
            iteractions: emails,
            recensioni,
            passaparola
        };

        setStatus('sent');
        service.sendEmailZohoNew(payload).then(resp => {
            setStatus('not sent');
            return props.openNotification({ notificationText: 'Email sent!', notificationType: 'success' });
        }).catch(() => {
            setStatus('not sent');
            return props.openNotification({ notificationText: 'Cannot send the email', notificationType: 'success' });
        });
    };

    return (
        <div className='section' id='mailing_section'>
             <div className='head_text'>Mailing</div>
            {/* <div className='fields'>
                <TextField 
                    className='field' 
                    defaultValue={passaparola_nome}
                    helperText='passaparola_nome'
                    name='passaparola_nome'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('passaparola_nome', value);
                        setPass(value);
                    }} 
                    label="Passaparola - Nome Cliente A" 
                        />
            </div> */}

            <div className='fields'>
                { emailTypes && 
                        <div id='question_list' className="question_list">
                            
                            <FormControlLabel
                                control={<Checkbox checked={passaparola} name="passaparola" />}
                                onChange={(event, value) => {
                                    setFieldValue('passaparola', value);
                                    setPassaparola(value);
                                    
                                }}
                                label="Passaparola"
                                helperText='Passaparola'
                                name='passaparola'
                            >
                            </FormControlLabel>

                            {/* {passaparola && <FormControl style={{ width: '400px', marginTop: '20px' }} variant="outlined" className='field -select'>
                                <InputLabel id="demo-simple-select-outlined-label">Origine Passaparola</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={origine_passaparola}
                                    helperText='origine_passaparola'
                                    name='origine_passaparola'
                                    onChange={(event, val) => { setFieldValue('origine_passaparola', event.target.value); setOrigine(event.target.value)}}
                                    label="Origine Passaparola"
                                    >
                                    <MenuItem value='e-mail'>E-mail</MenuItem>
                                    <MenuItem value='telefono'>Telefono</MenuItem>
                                </Select>
                            </FormControl>}
                            {passaparola && <TextField 
                                    style={{ position: 'relative', top: '-65px', left: '450px', marginBottom: '-65px' }}
                                    className='field' 
                                    defaultValue={passaparola_nome}
                                    helperText='passaparola_nome'
                                    name='passaparola_nome'
                                    onChange={event => {
                                        const value = event.target.value;

                                        setFieldValue('passaparola_nome', value);
                                        setPass(value);
                                    }} 
                                    label="Passaparola - Nome Cliente A" 
                                        />} */}

                            <FormControlLabel
                                control={<Checkbox checked={recensioni} name="recensioni" />}
                                style={passaparola ? { marginTop: '20px' } : {}}
                                onChange={(event, value) => {
                                    setFieldValue('recensioni', value);
                                    setRecensioni(value);
                                    
                                }}
                                label="recensioni"
                                helperText='recensioni'
                                name='recensioni'
                            >
                            </FormControlLabel>
                            {
                                emailTypes.map(checkbox => {
                                    return <FormControlLabel
                                        control={
                                            <Checkbox checked={emails.some(email => email === checkbox.template)} name={checkbox.name} />}
                                        onChange={(event, value) => {
                                            const setEmails = emails.some(email => email === checkbox.template) ? emails.filter(email => email !== checkbox.template) : [...emails, checkbox.template];
                                            setSelectedEmails(setEmails)
                                        }
                                        }
                                        label={checkbox.name}
                                    />
                                })
                            }
                        </div>
                    }
            </div>
            { (passaparola || recensioni || emailTypes.some(checkbox => emails.some(email => email === checkbox.template))) && <div className='fields'>
                <TextField 
                    required
                    error={!name_mailing}
                    className='field' 
                    value={name_mailing}
                    helperText='name_mailing'
                    name='name_mailing'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('name_mailing', value);
                        setName(value)
                    }} 
                    label="Nome" 
                        />

                <TextField 
                    required
                    error={!last_name_mailing}
                    className='field' 
                    value={last_name_mailing}
                    helperText='last_name_mailing'
                    name='last_name_mailing'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('last_name_mailing', value);
                        setLastName(value)
                    }} 
                    label="Cognome"  
                        />
            </div> }
            { (passaparola || recensioni || emailTypes.some(checkbox => emails.some(email => email === checkbox.template))) && <div className='fields'>
                <TextField 
                    required 
                    error={!email_mailing}
                    className='field' 
                    value={email_mailing}
                    helperText='email_mailing'
                    name='email_mailing'
                    onChange={event => { setEmail(event.target.value); setFieldValue('email_mailing', event.target.value)} } 
                    label="Email" 
                        />
            </div> }
            <div className='actions'>
                    <Button 
                        className={verificationStatus === 'checking' ? 'action -disabled' : 'action'}
                        onClick={() => {
                            setVerification('checking');
                            setFieldValueState('subscribeStatus', true);

                            service.verifyEmail({ email: email_mailing }).then(resp => {
                                const { code, ok } = resp.data;

                                console.log(resp.data, 'resp.data');

                                if(!ok)
                                    return;

                                if(code == 5 || code == 4 || code == 7) {
                                    setVerification('verified');
                                    openNotification({ notificationText: "Email valid!", notificationType: 'success' });
                                } else {
                                    setVerification('error');
                                    openNotification({ notificationText: "Email invalid!", notificationType: 'success' });
                                }

                                setFieldValueState('subscribeStatus', false);
                            }).catch(err => {
                                setVerification('not checked');

                                setFieldValueState('subscribeStatus', false);

                                openNotification({ notificationText: "Can't check the email, problem on backend", notificationType: 'success' });
                            })
                        }}
                        >
                        Verifica
                    </Button>
                    {/* <Button 
                        disabled={ verificationStatus !== 'verified'}
                        className={ (verificationStatus !== 'verified') ? 'action -disabled' : 'action'}
                        onClick={subscribeUser}>
                        Invio
                    </Button> */}

                    <Button 
                        className='action'
                        onClick={setContactInfo}>
                        Copy
                    </Button>
                    <Button 
                        disabled={ status === 'sent'}
                        className={ (status !== 'not sent') ? 'action -disabled' : 'action'}
                        onClick={sendEmailAction}>
                        Cliente felice
                    </Button>
                </div>
        </div>

    )
}

function Passaparola(props) {
    const {
        data,
        setFieldValue,
        openNotification,
        subscribeUser,
        setFieldValueState,
        type_product,
        type_contract,
        product_ee,
        product_gas,
        product,
        prodotto,
        operation_ee,
        operation_gas,
        operation,
        client_type,
        newsletter,
        marketing_terzi
    } = props;

    const [passaparola_nome, setPass] = React.useState(data.passaparola_nome || '')
    const [origine_passaparola, setOrigine] = React.useState(data.origine_passaparola || '')
    const [legame_cliente_b, setLegame] = React.useState(data.legame_cliente_b || '')
    
    return (
        <div className='section'>
            <div className='head_text'>Passaparola</div>
            <div className='fields'>
                <div id='question_list' className="question_list">

                    <FormControl style={{ width: '400px', marginTop: '20px' }} variant="outlined" className='field -select'>
                        <InputLabel id="demo-simple-select-outlined-label">Origine Passaparola</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={origine_passaparola}
                            helperText='origine_passaparola'
                            name='origine_passaparola'
                            onChange={(event, val) => { setFieldValue('origine_passaparola', event.target.value); setOrigine(event.target.value)}}
                            label="Origine Passaparola"
                            >
                            <MenuItem value='e-mail'>E-mail</MenuItem>
                            <MenuItem value='telefono'>Telefono</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField 
                        style={{ position: 'relative', top: '-65px', left: '450px', marginBottom: '-65px' }}
                        className='field' 
                        defaultValue={passaparola_nome}
                        helperText='passaparola_nome'
                        name='passaparola_nome'
                        onChange={event => {
                            const value = event.target.value;

                            setFieldValue('passaparola_nome', value);
                            setPass(value);
                        }} 
                        label="Passaparola - Nome Cliente A" 
                    />
                </div>
            </div>

            <div className='fields'>
                <div id='question_list'>

                    <FormControl style={{ width: '400px', marginTop: '20px' }} variant="outlined" className='field -select'>
                        <InputLabel id="demo-simple-select-outlined-label">Legame cliente B</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={legame_cliente_b}
                            helperText='legame_cliente_b'
                            name='legame_cliente_b'
                            onChange={(event, val) => { setFieldValue('legame_cliente_b', event.target.value); setLegame(event.target.value)}}
                            label="Legame cliente B"
                            >
                            <MenuItem value='amico'>amico</MenuItem>
                            <MenuItem value='parente'>parente</MenuItem>
                            <MenuItem value='collega'>collega</MenuItem>
                            <MenuItem value='vicino'>vicino</MenuItem>
                            <MenuItem value='conoscente'>conoscente</MenuItem>
                            <MenuItem value='altro'>altro</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    )
}



function NewsletterFields (props) {
    const { 
        setFieldValue, 
        data, 
        setFieldValueState,
        openNotification,
        subscribeUser,
        openManageScript,
        role
    } = props;

    const [newsletter, setNewsletter] = React.useState(data.newsletter || false);
    const [name_subs, setName] = React.useState(data.name_subs || data.name || data.name_extra || '');
    const [last_name_subs, setLastName] = React.useState(data.last_name_subs || data.last_name || data.last_name_extra || '');
    const [email_subs, setEmail] = React.useState(data.email_subs || data.email || data.email_extra || '');
    const [verificationStatus, setVerification] = React.useState('not cheked');
    const [scripts, setScripts] = React.useState([]);
    const [loadNew, setLoadNew] = React.useState(true);

    useEffect(() => {
        setFieldValue('name_subs', name_subs);
        setFieldValue('last_name_subs', last_name_subs);
        setFieldValue('email_subs', email_subs);
    })

    useEffect(() => {
        service.getNewsletterScripts().then(resp => {
            const { newsletterScripts, ok } = resp.data;

            if(!ok)
                return

            setScripts(newsletterScripts);
        }).catch(console.log);
    }, [loadNew])

    const setContactInfo = () => {
        const name_subs = data.name || data.name_extra;
        const last_name_subs = data.last_name || data.last_name_extra;
        const email_subs = data.email || data.email_extra;

        setName(name_subs);
        setLastName(last_name_subs);
        setEmail(email_subs);

        setFieldValue('name_subs', name_subs);
        setFieldValue('last_name_subs', last_name_subs);
        setFieldValue('email_subs', email_subs);
    }

    console.log(data.email_subs, data.email, data.email_extra, data.email_subs || data.email || data.email_extra)

    return (
        <div className='section' id='news_letter_section'>
            <div className='head_text' id='punto_di_fornitura_part'>Newsletter</div>
            <div className='list_scripts'>
                { role === 'admin' && <div className='actions'>
                    <Button 
                        className='action'
                            onClick={() => {
                                openManageScript('', '', function () {
                                    setLoadNew(!loadNew);
                                });
                            }}
                        >
                        Add new
                    </Button>
                </div> }
                <div className='scripts'>
                    {scripts.map(script => {
                        return (
                            <div className='script'>
                                {role === 'admin' && <div className='actions'>
                                    <div className='action -edit' onClick={() => {
                                        openManageScript(script.id, script.text, function() {
                                            setLoadNew(!loadNew);
                                        });
                                    }}></div>
                                    <div className='action -remove' onClick={() => {
                                        service.deleteNewsletterScripts(script.id).then(resp => {
                                            setLoadNew(!loadNew);
                                        }).catch(console.log);
                                    }}></div>
                                </div>}
                                <div className='text'>
                                    {renderHTML(script.text)}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='fields'>
                <FormControlLabel
                    control={<Checkbox checked={newsletter} name="newsletter" />}
                    onChange={(event, value) =>{ setNewsletter(value); setFieldValueState('newsletter', value)}}
                    label="Newsletter IT"
                    helperText='newsletter'
                    name='newsletter'
                >
                </FormControlLabel>
            </div>

            {newsletter && <div className='fields'>
                <TextField 
                    error={!name_subs}
                    className='field' 
                    value={name_subs}
                    helperText='name_subs'
                    name='name_subs'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('name_subs', value);
                        setName(value)
                    }} 
                    label="Nome" 
                        />

                <TextField 
                    error={!last_name_subs}
                    className='field' 
                    value={last_name_subs}
                    helperText='last_name_subs'
                    name='last_name_subs'
                    onChange={event => {
                        const value = event.target.value;

                        setFieldValue('last_name_subs', value);
                        setLastName(value)
                    }} 
                    label="Cognome"  
                        />
            </div> }
            { newsletter && <div className='fields'>
                <TextField 
                    required 
                    error={!email_subs}
                    error={verificationStatus === 'error'}
                    className='field' 
                    value={email_subs}
                    helperText='email_subs'
                    name='email_subs'
                    onChange={event => { setEmail(event.target.value); setFieldValue('email_subs', event.target.value)} } 
                    label="Email" 
                        />
            </div> }

            <div className='actions'>
                    <Button 
                        className={verificationStatus === 'checking' ? 'action -disabled' : 'action'}
                        onClick={() => {
                            setVerification('checking');
                            setFieldValueState('subscribeStatus', true);

                            service.verifyEmail({ email: email_subs }).then(resp => {
                                const { code, ok } = resp.data;

                                console.log(resp.data, 'resp.data');

                                if(!ok)
                                    return;

                                if(code == 5 || code == 4 || code == 7) {
                                    setVerification('verified');
                                    openNotification({ notificationText: "Email valid!", notificationType: 'success' });
                                } else {
                                    setVerification('error');
                                    openNotification({ notificationText: "Email invalid!", notificationType: 'success' });
                                }

                                setFieldValueState('subscribeStatus', false);
                            }).catch(err => {
                                setVerification('not checked');

                                setFieldValueState('subscribeStatus', false);

                                openNotification({ notificationText: "Can't check the email, problem on backend", notificationType: 'success' });
                            })
                        }}
                        >
                        Verifica
                    </Button>
                    <Button 
                        disabled={ verificationStatus !== 'verified'}
                        className={ (verificationStatus !== 'verified') ? 'action -disabled' : 'action'}
                        onClick={subscribeUser}>
                        Invio
                    </Button>

                    <Button 
                        className='action'
                        onClick={setContactInfo}>
                        Copy
                    </Button>
            </div>
        </div>
    )
}


function ClearDataMain(props) {
    const {
        state,
        formData,
        setFieldValue,
        setFieldValueState,
        setState
    } = props;

    const notTouchFields = [
        'id',
        'contact_owner',
        'products',
        'operations',
        'usersZ',
        'lead_id',
        'code',
        'contact_id',
        'loading',
        'subscribeStatus',
        'loadingParse',
        'usedData',
        'zohoProducts',
        'zohoUsers',
        'showManagePopup',
        'manageData',
        'theSameAddress',
        'timezone'
    ];
    const setEmtpyObj = ['product_ee', 'product_gas', 'operation_ee', 'operation_gas'];

    const clear = () => {
        const ks = {};

        Object.keys(formData).map(key => {
            if(notTouchFields.some(k => k == key))
                return 

            setFieldValue(key, '');
        });

        Object.keys(state).map(key => {
            if(notTouchFields.some(k => k == key))
                return

            if(setEmtpyObj.some(k => k == key))
                return ks[key] = {};

            ks[key] = '';
        });

        setFieldValueState('client_type', '');

        setState({...ks});
        console.log({...ks});
    }

    return (
        <Button 
            className='action -removed_data'
            onClick={clear}
            >
            Cancella dati
        </Button>
    )
}

function CopyDataMain(props) {
    const {
        state: { type_contract, ...state },
        formData,
        setFieldValue,
        setFieldValueState,
        setState
    } = props;

    const notTouchFields = [
        'id',
        'contact_owner',
        'products',
        'operations',
        'usersZ',
        'lead_id',
        'code',
        'contact_id',
        'loading',
        'subscribeStatus',
        'loadingParse',
        'usedData',
        'zohoProducts',
        'zohoUsers',
        'showManagePopup',
        'manageData',
        'theSameAddress',
        'timezone'
    ];
    const setEmtpyObj = ['product_ee', 'product_gas', 'operation_ee', 'operation_gas'];

    const copy = () => {
        if(type_contract === 'Assicurazione') {
            transferEnergiaToInsurance(formData, setFieldValue, type_contract);
            setFieldValueState('type_contract', null);
            setTimeout(() => { setFieldValueState('type_contract', 'Assicurazione') }, 10)
        }

        if(type_contract === 'vendite extra') {
            transferInfoToVendite(formData, setFieldValue, type_contract);
            setFieldValueState('type_contract', null);
            setTimeout(() => { setFieldValueState('type_contract', 'vendite extra') }, 10)
        }

        if(type_contract === 'contratto energia') {
            transferVenditeToEnergia(formData, setFieldValue, type_contract);
            setFieldValueState('type_contract', null);
            setTimeout(() => { setFieldValueState('type_contract', 'contratto energia') }, 10)
        }

        if(type_contract === 'telco') {
            transferVenditeToEnergia(formData, setFieldValue, type_contract);
            setFieldValueState('type_contract', null);
            setTimeout(() => { setFieldValueState('type_contract', 'telco') }, 10)
        }
        
    }

    return (
        <Button 
            className='action -removed_data'
            onClick={copy}
            >
            Copy dati
        </Button>
    )
}

function ClearDataExtra(props) {
    const {
        state,
        formData,
        setFieldValue,
        setFieldValueState
    } = props;

    const clear = () => {
        Object.keys(formData).filter(key => /(_extra)/.test(key)).map(key => {
            setFieldValue(key, null);
        });

        setFieldValueState('sub_contract', '');
        setFieldValueState('prodotto', '');
        setFieldValue('prodotto', '');
    }

    return (
        <Button 
            className='action -removed_data'
            onClick={clear}
            >
            Cancella dati
        </Button>
    )
}
function ClearDataAltra(props) {
    const {
        state,
        formData,
        setFieldValue,
        setFieldValueState
    } = props;

    const clear = () => {
        Object.keys(formData).filter(key => /(_altra)|(_extra)/.test(key)).map(key => {
            setFieldValue(key, null);
        });

        setFieldValueState('sub_contract', '');
        setFieldValueState('sub_sub_contract', '');
        setFieldValueState('prodotto', '');
        setFieldValueState('client_type_altra', '');
        setFieldValueState('product_altra', '');
    }

    return (
        <Button 
            className='action -removed_data'
            onClick={clear}
            >
            Cancella dati
        </Button>
    )
}

const allowUsers = [72, 84, 83];
const allowUsersRep = true;

// allowUsersRep
// allowUsersRep